import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EmployeeProfile from './employeeProfile';
import CompanyProfile from './companyProfile';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { getAuth } from 'firebase/auth';

const auth = getAuth();

const Profile = () => {
    const { userId } = useParams();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray[0]); // Assuming only one user data exists
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    if (!userData) {
        console.error('User data not found');
        return;
    }

    // Determine whether the user is a company or an employee based on data
    const isEmployee = userData.customerId || userData.employeeNo ? true : false;

    return (
        <div>
            {isEmployee ? <EmployeeProfile userData={userData} /> : <CompanyProfile userData={userData} />}
        </div>
    );
};

export default Profile;
