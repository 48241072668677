import React, {useContext, useState, useEffect} from "react";
import { db } from "../../../firebase.js";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { UserContext } from '../../../InitialPage/App.js';
import LeaveTable from "./Table/LeaveTable";
import TimesheetTable from "./Table/TimesheetTable";
import "../../../index.css";
import HolidayTable from "./Table/HolidayTable";
import CalendarTable from "./Table/CalendarTable";
import PayrollVarianceTable from "./Table/PayrollVarianceTable";
import { toast } from "react-toastify";

const auth = getAuth();

function EmployeeDashboardHome(){
    const { userRole } = useContext(UserContext);

    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     const fetchUserData = async () => {
    //         if (!auth.currentUser) {
    //             toast.error("User not found. Please login again.");
    //             return;
    //         }

    //         const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
    //         const querySnapshot = await getDocs(q);

    //         if (querySnapshot.empty) {
    //             toast.error(
    //                 "User not found. Please register an account on astra first on registration page"
    //             );
    //             return;
    //         }

    //         const userDataArray = querySnapshot.docs.map(doc => ({
    //             id: doc.id,
    //             ...doc.data()
    //         }));
    //         setUserData(userDataArray);
    //     };

    //     fetchUserData();
    // },);
    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const [leaves, setLeaves] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [timesheets, setTimesheets] = useState([]);


    useEffect(() => {
        const fetchLeaves = async () => {
            const querySnapshot = await getDocs(collection(db, 'issue-leave'));
            const leaveList = [];

            querySnapshot.forEach((doc) => {
                const leaveData = doc.data();
                if (!leaveData.deleted && leaveData.customerId === userData[0]?.customerId) {
                    leaveList.push({ id: doc.id, ...leaveData });
                }
            });

            setLeaves(leaveList);
        };
        fetchLeaves();

        const fetchHolidays = async () => {
            const querySnapshot = await getDocs(collection(db, 'holidays'));
            const holidayList = [];

            querySnapshot.forEach((doc) => {
                const holidayData = doc.data();
                if (!holidayData.deleted) {
                    holidayList.push({ id: doc.id, ...holidayData });
                }
            });

            setHolidays(holidayList);
        };
        fetchHolidays();

        const fetchTimesheets = async () => {
            const querySnapshot = await getDocs(collection(db, 'timesheets'));
            const timesheetList = [];

            querySnapshot.forEach((doc) => {
                const timesheetData = doc.data();
                if (!timesheetData.deleted && timesheetData.customerId === userData[0]?.customerId) {
                    timesheetList.push({ id: doc.id, ...timesheetData });
                }
            });

            setTimesheets(timesheetList);
        };
        fetchTimesheets();

    }, [userData, loading]);

    const modules = userData.length > 0 ? userData[0].modules || [] : [];

    return(
        <div>
            <div className='page-wrapper'>
                <div className='content container-fluid'>
                    <div className='page-header'>
                    {userData && userData.map(user => (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h3 className='page-title'>Welcome {
                                    user.companyName ? (
                                        user.companyName
                                    ) : user.employeeName ? (
                                        user.employeeName
                                    ) : (
                                        `${user.firstName} ${user.lastName}`
                                    )}
                                    !
                                </h3>
                                {/* <h3 className='page-title'>Welcome {user.firstName} {user.lastName}!</h3> */}
                                <ul className='breadcrumb'>
                                    <li className='breadcrumb-item active'>Dashboard</li>
                                </ul>
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className='row'>
                    {(modules.includes("leaves"))&& (
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-4'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa-solid fa-user-minus'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{leaves.length}</h3>
                                        <span>Leaves</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(modules.includes("holidays"))&& (
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-4'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa fa-gifts'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{holidays.length}</h3>
                                        <span>Holidays</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(modules.includes("timesheets"))&& (
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-4'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa-regular fa-calendar-days'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{timesheets.length}</h3>
                                        <span>Submitted Timesheets</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    </div>

                    <div className="row">
                        {/* <PayrollVarianceTable/> */}
                        {(modules.includes("leaves"))&& (<LeaveTable/>)}
                        {(modules.includes("timesheets"))&& ( <TimesheetTable/>)}

                    </div>

                    <div className="row">
                        {/* <div className="col-md-12 col-lg-6 col-xl-6 d-flex">
                            <div className="card flex-fill">
                                <div className="card-body">
                                    <h4 className="card-title">Leave Balances Statistics</h4>
                                    <div className="statistics">
                                        <div className="row">
                                            <div className="col-md-6 col-6 text-center">
                                                <div className="stats-box mb-4">
                                                    <p>Annual Leave Balances</p>
                                                    <h3>38</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-6 text-center">
                                                <div className="stats-box mb-4">
                                                    <p>Other Leaves</p>
                                                    <h3>19</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="progress mb-4">
                                        <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                            style={{width: "40%"}}
                                            aria-valuenow={40}
                                            aria-valuemin={0}
                                            aria-valuemax={100}>
                                            40%
                                        </div>
                                        <div
                                            className="progress-bar bg-warning"
                                            role="progressbar"
                                            style={{width: "30%"}}
                                            aria-valuenow={30}
                                            aria-valuemin={0}
                                            aria-valuemax={100}>
                                            30%
                                        </div>
                                        <div
                                            className="progress-bar bg-danger"
                                            role="progressbar"
                                            style={{width: "30%"}}
                                            aria-valuenow={30}
                                            aria-valuemin={0}
                                            aria-valuemax={100}>
                                            30%
                                        </div>
                                    </div>
                                    <div>
                                        <p>
                                            <i className="far fa-dot-circle text-success me-2"/>
                                            Approved Leaves <span className="float-end">31</span>
                                        </p>
                                        <p>
                                            <i className="far fa-dot-circle text-warning me-2"/>
                                            Pending Leaves <span className="float-end">115</span>
                                        </p>
                                        <p>
                                            <i className="far fa-dot-circle text-danger me-2"/>
                                            Denied Leaves <span className="float-end">47</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                    {(modules.includes("holidays"))&& (
                    <div className="row">
                        <HolidayTable/>
                        <CalendarTable/>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EmployeeDashboardHome;
