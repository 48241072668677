import React, {useContext, useState, useEffect} from "react";
import { db } from "../../../firebase.js";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { UserContext } from '../../../InitialPage/App.js';
import EmployeeSalaryTable from "./Table/EmployeeSalaryTable";
import LeaveCategoriesTable from "./Table/LeaveCategoriesTable";
import LeaveTable from "./Table/LeaveTable";
import EmployeesTable from "./Table/EmployeesTable";
import LeaveSchedulingTable from "./Table/LeaveSchedulingTable";
import PayrollTableShortcuts from "./Table/PayrollTableShortcuts";
import PayrollVarianceTable from "./Table/PayrollVarianceTable";

import { toast } from "react-toastify";

const auth = getAuth();
function HRDashboardHome(){
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const [employees, setEmployees] = useState([]);
    const [leaves, setLeaves] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [timesheets, setTimesheets] = useState([]);

    useEffect(() => {
        const fetchEmployees = async () => {
            // Check if userData has a defined value before using it in the query
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                const colRef = collection(db, 'add-employee');
                let querySnapshot;
    
                // Use userData safely in the query
                querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
    
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();
                    if (employeeData.status === 'active' && !employeeData.deleted) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
    
                setEmployees(employeeList);
            }
        };

        fetchEmployees();

        const fetchLeaves = async () => {
            // const querySnapshot = await getDocs(collection(db, 'issue-leave'));
            // const leaveList = [];

            // querySnapshot.forEach((doc) => {
            //     const leaveData = doc.data();
            //     if (!leaveData.deleted) {
            //         leaveList.push({ id: doc.id, ...leaveData });
            //     }
            // });

            // setLeaves(leaveList);
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                const colRef = collection(db, 'issue-leave');
                let querySnapshot;
    
                // Use userData safely in the query
                querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
    
                const leaveList = [];
                querySnapshot.forEach((doc) => {
                    const leaveData = doc.data();
                    if (!leaveData.deleted) {
                        leaveList.push({ id: doc.id, ...leaveData });
                    }
                });
    
                setLeaves(leaveList);
            }
        };
        fetchLeaves();

        const fetchHolidays = async () => {
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                const colRef = collection(db, 'holidays');
                let querySnapshot;
    
                // Use userData safely in the query
                querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
    
                const holidayList = [];
                querySnapshot.forEach((doc) => {
                    const holidayData = doc.data();
                    if (!holidayData.deleted) {
                        holidayList.push({ id: doc.id, ...holidayData });
                    }
                });
    
                setHolidays(holidayList);
            }
        };
        fetchHolidays();

        const fetchTimesheets = async () => {
            if (userData && userData.length > 0 && userData[0]?.organisationId) {
                // const querySnapshot = await getDocs(collection(db, 'timesheets'));
                const colRef = collection(db, 'timesheets');
                    // const querySnapshot = await getDocs(colRef);
                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all
                    querySnapshot = await getDocs(colRef);
                }
                const timesheetList = [];

                querySnapshot.forEach((doc) => {
                    const timesheetData = doc.data();
                    if (!timesheetData.deleted) {
                        timesheetList.push({ id: doc.id, ...timesheetData });
                    }
                });

                setTimesheets(timesheetList);
            }
        };
        fetchTimesheets();

    }, [userData, loading]);

    const modules = userData.length > 0 ? userData[0].modules || [] : [];

    return(
        <div>
            <div className='page-wrapper'>
                <div className='content container-fluid'>
                    <div className='page-header'>
                        {userData && userData.map(user => (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h3 className='page-title'>Welcome {
                                    user.companyName ? (
                                        user.companyName
                                    ) : user.employeeName ? (
                                        user.employeeName
                                    ) : (
                                        `${user.firstName} ${user.lastName}`
                                    )}
                                    !
                                </h3>
                                {/* <h3 className='page-title'>Welcome {user.companyName ? user.companyName : `${user.firstName} ${user.lastName}`}!</h3> */}
                                {/* <h3 className='page-title'>Welcome {user.firstName} {user.lastName}!</h3> */}
                                <ul className='breadcrumb'>
                                    <li className='breadcrumb-item active'>Dashboard</li>
                                </ul>
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className='row'>
                    {(modules.includes("employees"))&& (
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa fa-user'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{employees.length}</h3>
                                        <span>Active Employees</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(modules.includes("leaves"))&& (
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa fa-user-minus'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{leaves.length}</h3>
                                        <span>Leave Requests</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(modules.includes("holidays"))&& (
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa-solid fa-gifts'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{holidays.length}</h3>
                                        <span>Holidays</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(modules.includes("timesheets"))&& (
                        <div className='col-md-6 col-sm-6 col-lg-6 col-xl-3'>
                            <div className='card dash-widget'>
                                <div className='card-body'>
                                    <span className='dash-widget-icon'>
                                        <i className='fa-solid fa-calendar-days'/>
                                    </span>
                                    <div className='dash-widget-info'>
                                        <h3>{timesheets.length}</h3>
                                        <span>Submitted Timesheets</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    </div>
                    {(modules.includes("payroll"))&& (
                    <div className="row">
                        <PayrollVarianceTable/>
                        <EmployeeSalaryTable/>
                    </div>
                    )}
                    {(modules.includes("leaves"))&& (
                    <div className="row">
                        <LeaveCategoriesTable/>
                        <LeaveTable/>
                    </div>
                    )}
                    <div className="row">
                        {(modules.includes("employees"))&& (<EmployeesTable/>)}
                        {(modules.includes("leaves"))&& (<LeaveSchedulingTable/>)}
                    </div>
                    {(modules.includes("payroll"))&& (
                        <div className="row">
                            <PayrollTableShortcuts/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default HRDashboardHome;
