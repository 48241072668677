import React, { useState, useEffect } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import logo2 from "../../assets/img/new-rhokit-logo.png";
import "react-phone-input-2/lib/style.css";
import "../../assets/css/register.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase.js";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { uploadOrganisationDocument } from "../../lib/astra.js";
import PhoneInput from "react-phone-input-2";

const UploadOrgDocuments = () => {
    const navigate = useNavigate();
    const storage = getStorage();
    const [certOfReg, setCertOfReg] = useState(null);
    const [base64EncodedReg, setBase64EncodedReg] = useState(null);
    const [certOfIncorp, setCertOfIncorp] = useState(null);
    const [base64EncodedIncorp, setBase64EncodedIncorp] = useState(null);
    
    const [businessLicense, setBusinessLicense] = useState(null);
    const [base64EncodedBusinessLicense, setBase64EncodedBusinessLicense] = useState(null);

    const [physicalAddress, setPhysicalAddress] = useState(null);
    const [base64EncodedPhysicalAddress, setBase64EncodedPhysicalAddress] = useState(null);

    const [practiceLicense, setPracticeLicense] = useState(null);
    const [base64EncodedPractice, setBase64EncodedPractice] = useState(null);
    
    const [kraCertificateFile, setKraCertificateFile] = useState(null);
    const [base64EncodedKraCertificate, setBase64EncodedKraCertificate] = useState(null);
    const [companyPin, setCompanyPin] = useState(null);
    const [base64EncodedCompanyPin, setBase64EncodedCompanyPin] = useState(null);
   
    const [bankStatement, setBankStatement] = useState(null);
    const [base64EncodedBankStatement, setBase64EncodedBankStatement] = useState(null);
   
    const [idFrontFile, setIdFrontFile] = useState(null);
    const [base64EncodedIdFront, setBase64EncodedIdFront] = useState(null);
    const [idBackFile, setIdBackFile] = useState(null);
    const [base64EncodedIdBack, setBase64EncodedIdBack] = useState(null);

    const [passportPhoto, setPassportPhoto] = useState(null);
    const [base64EncodedPassportPhoto, setBase64EncodedPassportPhoto] = useState(null);

    const [uploadStatus, setUploadStatus] = useState({
        certOfReg: false,
        certOfIncorp: false,
        // certOfRegBno: false,
        // certOfRegIno: false,
        // certOfRegBname: false,
        businessLicense: false,
        physicalAddress: false,
        // practiceLicense: false,
        kraCertificateFile: false,
        // companyPin: false,
        bankStatement: false,
        idFront: false,
        idBack: false,
        passportPhoto: false,
    });

    const [businessType, setBusinessType] = useState('');

    const [user, setUser] = useState({});
    const [value, setValue] = useState();
    let { userId } = useParams();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        natureOfBusiness: "",
        fullName: "",
        phone: "",
        nationalIDNumber: "",
        kraPinNumber: "",
        
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const natureOfBusiness = [
        "Legal Services",
        "Depository Institutions",
        "Non Depository Institutions",
        "Security Brokers",
        "Insurance",
        // "Other"
    ];

    const fetchUser = async () => {
        const userRef = doc(db, "users", userId);
        const userSnapshot = await getDoc(userRef);
        console.log("User Data:", userSnapshot.data()); 
        if (userSnapshot.exists()) {
          setUser(userSnapshot.data());
          setBusinessType(userSnapshot.data().businessType);
        } else {
          toast.error("User not found");
          navigate("/orgregister");
        }
    };
    
    useEffect(() => {
        let isMounted = true;
    
        fetchUser();
    
        return () => {
          isMounted = false;
        };
    }, []);

    // Function to navigate based on business type
    // const navigateBasedOnBusinessType = () => {
    //   if (businessType === 'SOLE_PROPRIETOR') {
    //       // Navigate to sole proprietor details
    //       navigate(`/orgregister/${userId}/sole-proprietor-details`);
    //   } else if (businessType === 'LIMITED_COMPANY') {
    //       // Navigate to primary contact
    //       navigate(`/orgregister/${userId}/primary-contact-details`);
    //   } else {
    //       // Handle other business types if needed
    //       toast.error("Unsupported business type");
    //   }
    // }

    const uploadAndGetURL = async (documentType, organisationId, base64EncodedFile, fileType) => {
      try {
          // Upload the document
          await uploadOrganisationDocument(documentType, organisationId, base64EncodedFile, fileType);
          
          // Get the download URL
          const storageRef = ref(storage, `${documentType}/${user.organisationId}`);
          await uploadBytes(storageRef, base64EncodedFile);
          const downloadURL = await getDownloadURL(storageRef);
  
          // Success toast notification
          toast.success(`${documentType} uploaded successfully`);
          
          return downloadURL;
      } catch (error) {
          // Error toast notification
          toast.error(`Error uploading ${documentType}: ${error.message}`);
          throw error;
      }
  };
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // check if all documents have been uploaded
        // if (selfieFile === null || idFrontFile === null || idBackFile === null) {
        //   toast.error("Please upload all required documents");
        //   return;
        // }
    
        let regCertURL = null;
        let incorpCertURL = null;
        let kraCertificateURL = null;
        let physicalAddressURL = null;
        let bankStatementURL = null;
    
        try {
          setLoading(true);

          // Upload documents and get download URLs
          const regCertURL = certOfReg ? await uploadAndGetURL("CERT_OF_REGISTRATION", user.organisationId, base64EncodedReg, certOfReg.type) : null;
          const incorpCertURL = certOfIncorp ? await uploadAndGetURL("CERT_OF_INCORP", user.organisationId, base64EncodedIncorp, certOfIncorp.type) : null;
          const businessLicenseURL = businessLicense ? await uploadAndGetURL("BUSINESS_LICENSE", user.organisationId, base64EncodedBusinessLicense, businessLicense.type) : null;
          const physicalAddressURL = physicalAddress ? await uploadAndGetURL("PROOF_OF_ADDRESS", user.organisationId, base64EncodedPhysicalAddress, physicalAddress.type) : null;
          const kraCertificateURL = kraCertificateFile ? await uploadAndGetURL("TAX", user.organisationId, base64EncodedKraCertificate, kraCertificateFile.type) : null;
          const bankStatementURL = bankStatement ? await uploadAndGetURL("BANK_STATEMENT", user.organisationId, base64EncodedBankStatement, bankStatement.type) : null;
        
          // Update Firestore document with URLs
          const userRef = doc(db, "users", userId);
          await updateDoc(userRef, {
              certOfReg: regCertURL,
              certOfIncorp: incorpCertURL,
              businessLicense: businessLicenseURL,
              physicalAddress: physicalAddressURL,
              kraCertificate: kraCertificateURL,
              bankStatement: bankStatementURL,
          });

    
          setLoading(false);
          toast.success("Documents uploaded successfully");
          navigate(`/orgregister/${userId}/position-holder`);
          navigateBasedOnBusinessType();
        } catch (error) {
          setLoading(false);
          toast.error(
            error.message +
              ". Please try again by selecting another file to proceed with the upload"
          );
        }
    };

    return (
        <div className="register">
            <div className="account-page">
                <div className="main-wrapper">
                    <div className="account-content">
                        <div className="container">
                            <div className="account-logo">
                                <img src={logo2} alt="Logo" />
                            </div>

                            <div className="account-box">
                                <div className="account-wrapper">
                                    <h3 className="account-title">Register</h3>
                                    <p className="account-subtitle">Upload Documents</p>
                                    <p>
                                        Please upload the following documents to complete your KYB
                                        and for you to be to move to the next step, all documents
                                        should be uploaded successfully
                                    </p>
                                    <div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                              {businessType === 'SOLE_PROPRIETOR' && (
                                                <div className="col-md-12">
                                                  <div className="input-block">
                                                      <label className="col-form-label">
                                                          Certificate of Registration (Business
                                                          Permit) {" "}
                                                          <span className="text-danger">*</span>
                                                      </label>
                                                       <input
                                                          className="form-control"
                                                          type="file"
                                                          // accept=".pdf"
                                                          disabled={uploadStatus.certOfReg}
                                                          onChange={(event) => {
                                                          const file =
                                                            event.currentTarget.files &&
                                                            event.currentTarget.files[0];
                                                            if (file) {
                                                              if (file.size / 1024 / 1024 > 1) {
                                                                toast.error("File size too big (1MB max)");
                                                              } else if (file.type !== "application/pdf") {
                                                                toast.error(
                                                                  "File type not supported. Only PDF allowed"
                                                                );
                                                              } else {
                                                                setCertOfReg(file);
                                                                const reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                  setBase64EncodedReg(
                                                                    e.target?.result.split(",")[1]
                                                                  );
                                                                  console.log(e.target?.result);
                                                                  };
                                                                reader.readAsDataURL(file);
                                                              }
                                                            }
                                                          }}
                                                          required
                                                        />
                                                      </div>
                                                      {uploadStatus.certOfReg && (
                                                        <p className="text-success text-xs">
                                                          Certificate of registration uploaded successfully
                                                        </p>
                                                      )}
                                                      </div>
                                                    )}
                                                    {businessType === 'LIMITED_COMPANY' && (
                                                        <div className="col-md-12">
                                                          <div className="input-block">
                                                            <label className="col-form-label">
                                                              Certificate of Incorporation {" "}
                                                              <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                              className="form-control"
                                                              type="file"
                                                              // accept=".pdf"
                                                              disabled={uploadStatus.certOfIncorp}
                                                              onChange={(event) => {
                                                              const file =
                                                                event.currentTarget.files &&
                                                                event.currentTarget.files[0];
                                                                if (file) {
                                                                  if (file.size / 1024 / 1024 > 1) {
                                                                    toast.error("File size too big (1MB max)");
                                                                  } else if (file.type !== "application/pdf") {
                                                                    toast.error(
                                                                      "File type not supported. Only PDF allowed"
                                                                    );
                                                                  } else {
                                                                    setCertOfIncorp(file);
                                                                    const reader = new FileReader();
                                                                    reader.onload = (e) => {
                                                                      setBase64EncodedIncorp(
                                                                        e.target?.result.split(",")[1]
                                                                      );
                                                                      console.log(e.target?.result);
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                  }
                                                                }
                                                              }}
                                                              required
                                                            />
                                                          </div>
                                                          {uploadStatus.certOfIncorp && (
                                                            <p className="text-success text-xs">
                                                              Certificate of Incorporation uploaded successfully
                                                            </p>
                                                          )}
                                                        </div>
                                                      )}
                                                        <div className="col-md-12">
                                                            <div className="input-block">
                                                                <label className="col-form-label">
                                                                    Business/Practice License {" "}
                                                                    {/* <span className="text-danger">*</span> */}
                                                                </label>
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    // accept=".pdf"
                                                                    disabled={uploadStatus.businessLicense}
                                                                    onChange={(event) => {
                                                                        const file =
                                                                        event.currentTarget.files &&
                                                                        event.currentTarget.files[0];
                                                                        if (file) {
                                                                        if (file.size / 1024 / 1024 > 1) {
                                                                            toast.error("File size too big (1MB max)");
                                                                        } else if (file.type !== "application/pdf") {
                                                                            toast.error(
                                                                            "File type not supported. Only PDF allowed"
                                                                            );
                                                                        } else {
                                                                            setBusinessLicense(file);
                                                                            const reader = new FileReader();
                                                                            reader.onload = (e) => {
                                                                            setBase64EncodedBusinessLicense(
                                                                                e.target?.result.split(",")[1]
                                                                            );
                                                                            console.log(e.target?.result);
                                                                            };
                                                                            reader.readAsDataURL(file);
                                                                        }
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            {/* <p className="text-primary text-xs">
                                                              Failure to upload this will limit your transactions and wallet balance
                                                            </p> */}
                                                            {uploadStatus.businessLicense && (
                                                                <p className="text-success text-xs">
                                                                   Business license uploaded successfully
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="input-block">
                                                                <label className="col-form-label">
                                                                    Physical Address/ Utility Bill
                                                                    {/* <span className="text-danger">*</span> */}
                                                                </label>
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    // accept=".pdf"
                                                                    onChange={(event) => {
                                                                        const file =
                                                                          event.currentTarget.files &&
                                                                          event.currentTarget.files[0];
                                                                        if (file) {
                                                                          if (file.size / 1024 / 1024 > 10) {
                                                                            toast.error("File size too big (10MB max)");
                                                                          } else if (file.type !== "application/pdf") {
                                                                            toast.error(
                                                                              "File type not supported. Only PDF allowed"
                                                                            );
                                                                          } else {
                                                                            setPhysicalAddress(file);
                                                                            const reader = new FileReader();
                                                                            reader.onload = (e) => {
                                                                              setBase64EncodedPhysicalAddress(
                                                                                e.target?.result.split(",")[1]
                                                                              );
                                                                              console.log(e.target?.result);
                                                                            };
                                                                            reader.readAsDataURL(file);
                                                                          }
                                                                        }
                                                                      }}
                                                                    disabled={uploadStatus.physicalAddress}
                                                                />
                                                                <p className="text-primary text-xs">
                                                                Document for proof of address E.g Utility bill <br />
                                                                Failure to upload this will limit your transactions and wallet balance
                                                                </p>
                                                                {uploadStatus.physicalAddress && (
                                                                <p className="text-success text-xs">
                                                                    Proof of address uploaded successfully
                                                                </p>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="input-block">
                                                                <label className="col-form-label">
                                                                    KRA PIN Certificate {" "}
                                                                    <span className="text-danger">*</span>
                                                                </label>
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    // accept=".pdf"
                                                                    onChange={(event) => {
                                                                        const file =
                                                                          event.currentTarget.files &&
                                                                          event.currentTarget.files[0];
                                                                        if (file) {
                                                                          if (file.size / 1024 / 1024 > 10) {
                                                                            toast.error("File size too big (10MB max)");
                                                                          } else if (file.type !== "application/pdf") {
                                                                            toast.error(
                                                                              "File type not supported. Only PDF allowed"
                                                                            );
                                                                          } else {
                                                                            setKraCertificateFile(file);
                                                                            const reader = new FileReader();
                                                                            reader.onload = (e) => {
                                                                              setBase64EncodedKraCertificate(
                                                                                e.target?.result.split(",")[1]
                                                                              );
                                                                              console.log(e.target?.result);
                                                                            };
                                                                            reader.readAsDataURL(file);
                                                                          }
                                                                        }
                                                                      }}
                                                                      disabled={uploadStatus.kraCertificate}
                                                                    />
                                                                    {uploadStatus.kraCertificate && (
                                                                      <p className="text-success text-xs">
                                                                        KRA certificate uploaded successfully
                                                                      </p>
                                                                    )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="input-block">
                                                                <label className="col-form-label">
                                                                   Bank Statement
                                                                </label>
                                                                <input
                                                                    className="form-control"
                                                                    type="file"
                                                                    // accept=".pdf"
                                                                    onChange={(event) => {
                                                                        const file =
                                                                          event.currentTarget.files &&
                                                                          event.currentTarget.files[0];
                                                                        if (file) {
                                                                          if (file.size / 1024 / 1024 > 10) {
                                                                            toast.error("File size too big (10MB max)");
                                                                          } else if (file.type !== "application/pdf") {
                                                                            toast.error(
                                                                              "File type not supported. Only PDF allowed"
                                                                            );
                                                                          } else {
                                                                            setBankStatement(file);
                                                                            const reader = new FileReader();
                                                                            reader.onload = (e) => {
                                                                              setBase64EncodedBankStatement(
                                                                                e.target?.result.split(",")[1]
                                                                              );
                                                                              console.log(e.target?.result);
                                                                            };
                                                                            reader.readAsDataURL(file);
                                                                          }
                                                                        }
                                                                      }}
                                                                      disabled={uploadStatus.bankStatement}
                                                                    />
                                                                    <p className="text-primary text-xs">
                                                                    At least 1 month from day of onboarding {" "}<br />
                                                                    Failure to upload this will limit your transactions and wallet balance
                                                                    </p>
                                                                    {uploadStatus.bankStatement && (
                                                                    <p className="text-success text-xs">
                                                                        Bank statement uploaded successfully
                                                                    </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                

                                                
                                                
                                                <div className="input-block text-center">
                                                    <button
                                                        className="btn btn-primary"
                                                        type="submit"
                                                        disabled={loading}
                                                    >
                                                        {loading ? "uploading..." : "Next Step"}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="account-footer">
                                            <p>
                                                Already have an account? <Link to="/">Login</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
              position="top-right"
              autoClose={10000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
              transition={Slide}
            />
        </div>
    )
}

export default UploadOrgDocuments
