import React, { useState, useEffect, useContext } from "react";
import { db } from '../../../../firebase.js';
import { collection, getDocs, updateDoc, doc, query, where } from 'firebase/firestore';
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import Select from "react-select";
import {Link} from "react-router-dom";
import PayrollitemForm from "./PayrollitemForm.js";
import { UserContext } from "../../../../InitialPage/App.js";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const PayrollItems = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [nonCashBenefitFormData, setNonCashBenefitFormData] = useState({
        nonCashBenefitName: '',
        nonCashBenefitAmount: 0,
        employeeName: [],
    });

    const [housingBenefitFormData, setHousingBenefitFormData] = useState({
        typeOfHousing: '',
        rentOfHouseMarketValue: 0,
        rentRecoveredFromEmployee: 0,
        employeeName: [],
    });

    const [cashBenefitFormData, setCashBenefitFormData] = useState({
        cashBenefitName: '',
        cashBenefitAmount: 0,
        permissibleLimit: 0,
        employeeName: [],
    });

    const [allowanceFormData, setAllowanceFormData] = useState({
        allowanceName: '',
        allowanceAmount: 0,
        employeeName: [],
    });

    const [otherDeductionFormData, setOtherDeductionFormData] = useState({
        deductionName: '',
        deductionAmount: 0,
        employeeName: [],
    });

    const [focused, setFocused] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [allowanceData, setAllowanceData] = useState([]);
    const [editAllowanceData, setEditAllowanceData] = useState([]);
    const [filteredAllowanceData, setAllowanceFilteredData] = useState(allowanceData);
    const [otherDeductionData, setOtherDeductionData] = useState([]);
    const [editOtherDeductionData, setEditOtherDeductionData] = useState([]);
    const [filteredOtherDeductionData, setOtherDeductionFilteredData] = useState(otherDeductionData);
    const [nonCashBenefitData, setNonCashBenefitData] = useState([]);
    const [editNonCashBenefitData, setEditNonCashBenefitData] = useState([]);
    const [filteredNonCashBenefitData, setNonCashBenefitFilteredData] = useState(nonCashBenefitData);
    const [housingBenefitData, setHousingBenefitData] = useState([]);
    const [editHousingBenefitData, setEditHousingBenefitData] = useState([]);
    const [filteredHousingBenefitData, setHousingBenefitFilteredData] = useState(housingBenefitData);
    const [cashBenefitData, setCashBenefitData] = useState([]);
    const [editCashBenefitData, setEditCashBenefitData] = useState([]);
    const [filteredCashBenefitData, setCashBenefitFilteredData] = useState(cashBenefitData);

    const [menu, setMenu] = useState(false);
    const [employees, setEmployees] = useState([]);
    const customStyles = {
        control: base => ({
            ...base,
            height: 43,
            minHeight: 43
        })
    };

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleAllowanceInputChange = (e) => {
        const { name, value } = e.target;
        setAllowanceFormData({
            ...allowanceFormData,
            [name]: value,
        });
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const filterAllowanceData = () => {
        if (searchInput === '') {
            return allowanceData.filter(allowanceItem => !allowanceItem.deleted);
        } else {
            return filteredAllowanceData.filter(allowanceItem => !allowanceItem.deleted);
        }
    };

    const handleOtherDeductionInputChange = (e) => {
        const { name, value } = e.target;
        setOtherDeductionFormData({
            ...otherDeductionFormData,
            [name]: value,
        });
    };

    const filterOtherDeductionData = () => {
        if (searchInput === '') {
            return otherDeductionData.filter(otherDeductionItem => !otherDeductionItem.deleted);
        } else {
            return filteredOtherDeductionData.filter(otherDeductionItem => !otherDeductionItem.deleted);
        }
    };


    const handleNonCashBenefitInputChange = (e) => {
        const { name, value } = e.target;
        setNonCashBenefitFormData({
            ...nonCashBenefitFormData,
            [name]: value,
        });
    };

    const filterNonCashBenefitData = () => {
        if (searchInput === '') {
            return nonCashBenefitData.filter(nonCashItem => !nonCashItem.deleted);
        } else {
            return filteredNonCashBenefitData.filter(nonCashItem => !nonCashItem.deleted);
        }
    };

    const handleHousingBenefitInputChange = (e) => {
        const { name, value } = e.target;
        setHousingBenefitFormData({
            ...housingBenefitFormData,
            [name]: value,
        });
    };

    const filterHousingBenefitData = () => {
        if (searchInput === '') {
            return housingBenefitData.filter(housingItem => !housingItem.deleted);
        } else {
            return filteredHousingBenefitData.filter(housingItem => !housingItem.deleted);
        }
    };

    const handleCashBenefitInputChange = (e) => {
        const { name, value } = e.target;
        setCashBenefitFormData({
            ...cashBenefitFormData,
            [name]: value,
        });
    };

    const filterCashBenefitData = () => {
        if (searchInput === '') {
            return cashBenefitData.filter(cashItem => !cashItem.deleted);
        } else {
            return filteredCashBenefitData.filter(cashItem => !cashItem.deleted);
        }
    };


    const handleSubmit1 = async (e) => {
        e.preventDefault();

        try {
            // Update the data in the database
            await updateDoc(doc(db, 'payroll-allowance', editAllowanceData), allowanceFormData);

            // Clear the form data and reset the  state
            setAllowanceFormData({
                allowanceName: '',
            });
            setAllowanceFormData(null);
        } catch (error) {
            console.error('Error updating allowance:', error);
        }
    };

    const handleSubmit2 = async (e) => {
        e.preventDefault();

        try {
            // Update the data in the database
            await updateDoc(doc(db, 'payroll-noncash-benefit', editNonCashBenefitData), nonCashBenefitFormData);

            // Clear the form data and reset the  state
            setNonCashBenefitFormData({
                nonCashBenefitName: '',
            });
            setNonCashBenefitFormData(null);
        } catch (error) {
            console.error('Error updating non-cash benefit:', error);
        }
    };

    const handleSubmit3 = async (e) => {
        e.preventDefault();

        try {
            // Update the data in the database
            await updateDoc(doc(db, 'payroll-housing-benefit', editHousingBenefitData), housingBenefitFormData);

            // Clear the form data and reset the  state
            setHousingBenefitFormData({
                typeOfHousing: '',
            });
            setHousingBenefitFormData(null);
        } catch (error) {
            console.error('Error updating housing benefit:', error);
        }
    };

    const handleSubmit4 = async (e) => {
        e.preventDefault();

        try {
            // Update the data in the database
            await updateDoc(doc(db, 'payroll-cash-benefit', editCashBenefitData), cashBenefitFormData);

            // Clear the form data and reset the  state
            setCashBenefitFormData({
                cashBenefitName: '',
            });
            setCashBenefitFormData(null);
        } catch (error) {
            console.error('Error updating cash benefit:', error);
        }
    };

    const handleSubmit5 = async (e) => {
        e.preventDefault();

        try {
            // Update the data in the database
            await updateDoc(doc(db, 'payroll-other-deduction', editOtherDeductionData), otherDeductionFormData);

            // Clear the form data and reset the  state
            setOtherDeductionFormData({
                deductionName: '',
                deductionAmount: '',
                employeeName: [],
            });
            setOtherDeductionFormData(null);
        } catch (error) {
            console.error('Error updating other deduction:', error);
        }
    };

    const handleDelete1 = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'payroll-allowance', editAllowanceData), { deleted: true });

            // Update the local data without refetching from Firestore
            setAllowanceData(allowanceData => allowanceData.map(allowanceItem =>
                allowanceItem.id === editAllowanceData ? { ...allowanceItem, deleted: true } : allowanceItem
            ));

        } catch (error) {
            console.error('Error deleting overtime item:', error);
        }
    };

    const handleDelete2 = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'payroll-noncash-benefit', editNonCashBenefitData), { deleted: true });

            // Update the local data without refetching from Firestore
            setNonCashBenefitData(nonCashBenefitData => nonCashBenefitData.map(nonCashItem =>
                nonCashItem.id === editNonCashBenefitData ? { ...nonCashItem, deleted: true } : nonCashItem
            ));

        } catch (error) {
            console.error('Error deleting non-cash benefit item:', error);
        }
    };

    const handleDelete3 = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'payroll-housing-benefit', editHousingBenefitData), { deleted: true });

            // Update the local data without refetching from Firestore
            setHousingBenefitData(housingBenefitData => housingBenefitData.map(housingItem =>
                housingItem.id === editHousingBenefitData ? { ...housingItem, deleted: true } : housingItem
            ));

        } catch (error) {
            console.error('Error deleting housing benefit item:', error);
        }
    };

    const handleDelete4 = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'payroll-cash-benefit', editCashBenefitData), { deleted: true });

            // Update the local data without refetching from Firestore
            setCashBenefitData(cashBenefitData => cashBenefitData.map(cashItem =>
                cashItem.id === editCashBenefitData ? { ...cashItem, deleted: true } : cashItem
            ));

        } catch (error) {
            console.error('Error deleting cash benefit item:', error);
        }
    };

    const handleDelete5 = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'payroll-other-deduction', editOtherDeductionData), { deleted: true });

            // Update the local data without refetching from Firestore
            setOtherDeductionData(otherDeductionData => otherDeductionData.map(otherDeductionItem =>
                otherDeductionItem.id === editOtherDeductionData ? { ...otherDeductionItem, deleted: true } : otherDeductionItem
            ));

        } catch (error) {
            console.error('Error deleting other deduction item:', error);
        }
    };


    useEffect(() => {
        const fetchAllowanceData = async () => {
            try {
                // setLoading(true);

                // const colRef = collection(db, 'payroll-allowance');
                // const querySnapshot = await getDocs(colRef);
                const colRef = collection(db, 'payroll-allowance');

                let querySnapshot;

                if (userData[0]?.role && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray1 = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setAllowanceData(dataArray1);
                setEntriesCount(dataArray1.length);

                setLoading(false);

                if (editAllowanceData) {
                    const selectedAllowanceItem = dataArray1.find(allowanceFormData => allowanceFormData.id === editAllowanceData);
                    setAllowanceFormData(selectedAllowanceItem);
                }
                // console.log('Fetched allowance data:', dataArray1);
            } catch (error) {
                console.error('Error fetching allowance data:', error);
                setLoading(false);
            }
        };

        fetchAllowanceData();
    }, [userData, loading, editAllowanceData]);


    useEffect(() => {
        const fetchNonCashBenefitData = async () => {
            try {
                // setLoading(true);

                // const colRef = collection(db, 'payroll-noncash-benefit');
                // const querySnapshot = await getDocs(colRef);
                const colRef = collection(db, 'payroll-noncash-benefit');

                let querySnapshot;

                if (userData[0]?.role && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray2 = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setNonCashBenefitData(dataArray2);
                setEntriesCount(dataArray2.length);

                setLoading(false);

                if (editNonCashBenefitData) {
                    const selectedNonCashItem = dataArray2.find(nonCashItem => nonCashItem.id === editNonCashBenefitData);
                    setNonCashBenefitFormData(selectedNonCashItem);
                }
                // console.log('Fetched non-cash benefits data:', dataArray2);
            } catch (error) {
                console.error('Error fetching non-cash benefits data:', error);
                setLoading(false);
            }
        };

        fetchNonCashBenefitData();
    }, [userData, loading, editNonCashBenefitData]);

    useEffect(() => {
        const fetchHousingBenefitData = async () => {
            try {
                // setLoading(true);

                // const colRef = collection(db, 'payroll-housing-benefit');
                // const querySnapshot = await getDocs(colRef);
                const colRef = collection(db, 'payroll-housing-benefit');

                let querySnapshot;

                if (userData[0]?.role && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray3 = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setHousingBenefitData(dataArray3);
                setEntriesCount(dataArray3.length);

                setLoading(false);

                if (editHousingBenefitData) {
                    const selectedHousingItem = dataArray3.find(housingItem => housingItem.id === editHousingBenefitData);
                    setHousingBenefitFormData(selectedHousingItem);
                }
                // console.log('Fetched housing benefits data:', dataArray3);
            } catch (error) {
                console.error('Error fetching housing benefits data:', error);
                setLoading(false);
            }
        };

        fetchHousingBenefitData();
    }, [userData, loading, editHousingBenefitData]);

    useEffect(() => {
        const fetchCashBenefitData = async () => {
            try {
                // setLoading(true);

                // const colRef = collection(db, 'payroll-cash-benefit');
                // const querySnapshot = await getDocs(colRef);

                const colRef = collection(db, 'payroll-cash-benefit');

                let querySnapshot;

                if (userData[0]?.role && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray4 = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCashBenefitData(dataArray4);
                setEntriesCount(dataArray4.length);

                setLoading(false);

                if (editCashBenefitData) {
                    const selectedCashItem = dataArray4.find(cashItem => cashItem.id === editCashBenefitData);
                    setCashBenefitFormData(selectedCashItem);
                }
                // console.log('Fetched cash benefits data:', dataArray4);
            } catch (error) {
                console.error('Error fetching cash benefits data:', error);
                setLoading(false);
            }
        };

        fetchCashBenefitData();
    }, [userData, loading, editCashBenefitData]);

    useEffect(() => {
        const fetchOtherDeductionData = async () => {
            try {
                // setLoading(true);

                // const colRef = collection(db, 'payroll-other-deduction');
                // const querySnapshot = await getDocs(colRef);
                const colRef = collection(db, 'payroll-other-deduction');

                let querySnapshot;

                if (userData[0]?.role && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray5 = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setOtherDeductionData(dataArray5);
                setEntriesCount(dataArray5.length);

                setLoading(false);

                if (editOtherDeductionData) {
                    const selectedOtherDeductionItem = dataArray5.find(otherDeductionItem => otherDeductionItem.id === editOtherDeductionData);
                    setOtherDeductionFormData(selectedOtherDeductionItem);
                }
                // console.log('Fetched other deduction data:', dataArray5);
            } catch (error) {
                console.error('Error fetching other deduction data:', error);
                setLoading(false);
            }
        };

        fetchOtherDeductionData();
    }, [userData, loading, editOtherDeductionData]);


    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Payroll Items</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="#">Payroll</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Payroll Items</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="page-menu">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <ul className="nav nav-tabs nav-tabs-bottom">
                                                <li className="nav-item">
                                                    <Link
                                                        className="nav-link active"
                                                        data-bs-toggle="tab"
                                                        to="#tab_benefits">
                                                        Benefits
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        className="nav-link"
                                                        data-bs-toggle="tab"
                                                        to="#tab_allowances">
                                                        Allowances
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        className="nav-link"
                                                        data-bs-toggle="tab"
                                                        to="#tab_other_deductions">
                                                        Other Deductions
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                <div className="tab-content">
                                    <div className="tab-pane show active" id="tab_benefits">
                                        <div className="page-menu">
                                            <div className="row">
                                                <div className="col">
                                                    <ul className="nav nav-tabs nav-tabs-bottom">
                                                        <li className="nav-item">
                                                            <Link
                                                                className="nav-link active"
                                                                data-bs-toggle="tab"
                                                                to="#tab_non_cash_benefits">
                                                                Non Cash Benefits
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                className="nav-link"
                                                                data-bs-toggle="tab"
                                                                to="#tab_housing_benefits">
                                                                Housing Benefits
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                className="nav-link"
                                                                data-bs-toggle="tab"
                                                                to="#tab_cash_benefits">
                                                                Cash Benefits
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-content">
                                            <div className="tab-pane show active" id="tab_non_cash_benefits">
                                                <div className="text-end mb-4 clearfix">
                                                    <button
                                                        className="btn btn-primary add-btn"
                                                        type="button"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#add_non_cash_benefit">
                                                        <i className="fa fa-plus"/> Add Non Cash Benefit
                                                    </button>
                                                </div>

                                                <div className="payroll-table card">
                                                    <div className="table-responsive">
                                                        <table
                                                            className="table table-hover table-radius custom-table datatable">
                                                            <thead>
                                                            <tr>
                                                                <th>Non-Cash Benefits</th>
                                                                <th>Amount</th>
                                                                <th>Employees</th>
                                                                <th className="text-end">Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <>
                                                                {filterNonCashBenefitData().map(nonCashItem => (
                                                                    <React.Fragment key={nonCashItem.id}>
                                                                        <tr className="clickable" data-toggle="collapse"
                                                                            id={`row${nonCashItem.id}`}
                                                                            data-target={`.row${nonCashItem.id}`}>
                                                                            <td>
                                                                                {nonCashItem.nonCashBenefitName}
                                                                            </td>
                                                                            <td>
                                                                                {nonCashItem.nonCashBenefitAmount}
                                                                            </td>
                                                                            <td>
                                                                                <Link className="btn btn-sm btn-primary"
                                                                                      to="#">
                                                                                    View Employees
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <div
                                                                                    className="dropdown dropdown-action text-end">
                                                                                    <a
                                                                                        href="#"
                                                                                        className="action-icon dropdown-toggle"
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false">
                                                                                        <i className="material-icons">more_vert</i>
                                                                                    </a>
                                                                                    <div
                                                                                        className="dropdown-menu dropdown-menu-right">
                                                                                        <a
                                                                                            className="dropdown-item"
                                                                                            href="#"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#edit_non_cash_benefit"
                                                                                            onClick={() => setEditNonCashBenefitData(nonCashItem.id)}
                                                                                        >
                                                                                            <i className="fa fa-pencil m-r-5"/> Edit
                                                                                        </a>
                                                                                        <a
                                                                                            className="dropdown-item"
                                                                                            href="#"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#delete_non_cash_benefit"
                                                                                            onClick={() => setEditNonCashBenefitData(nonCashItem.id)}
                                                                                        >
                                                                                            <i className="fa fa-trash m-r-5"/> Delete
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr className={`collapse row${nonCashItem.id}`}>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>
                                                                                {nonCashItem.employeeName && (
                                                                                    <div>
                                                                                        <ul>
                                                                                            {nonCashItem.employeeName.map((name, index) => (
                                                                                                <li key={index}>{name}</li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))}
                                                            </>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="tab_housing_benefits">
                                                <div className="text-end mb-4 clearfix">
                                                    <button
                                                        className="btn btn-primary add-btn"
                                                        type="button"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#add_housing_benefit">
                                                        <i className="fa fa-plus"/> Add Housing Benefit
                                                    </button>
                                                </div>

                                                <div className="payroll-table card">
                                                <div className="table-responsive">
                                                        <table
                                                            className="table table-hover table-radius custom-table datatable">
                                                            <thead>
                                                            <tr>
                                                                <th>Type of Housing</th>
                                                                <th>Rent of House / Market Value</th>
                                                                <th>Rent Computed from Employee</th>
                                                                <th>Employees</th>
                                                                <th className="text-end">Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <>
                                                                {filterHousingBenefitData().map(housingItem => (
                                                                    <React.Fragment key={housingItem.id}>
                                                                        <tr className="clickable" data-toggle="collapse"
                                                                            id={`row${housingItem.id}`} data-target={`.row${housingItem.id}`}>
                                                                            <td>
                                                                                {housingItem.typeOfHousing}
                                                                            </td>
                                                                            <td>
                                                                                {housingItem.rentOfHouseMarketValue}
                                                                            </td>
                                                                            <td>
                                                                                {housingItem.rentRecoveredFromEmployee}
                                                                            </td>
                                                                            <td>
                                                                                <Link className="btn btn-sm btn-primary"
                                                                                      to="#">
                                                                                    View Employees
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <div
                                                                                    className="dropdown dropdown-action text-end">
                                                                                    <a
                                                                                        href="#"
                                                                                        className="action-icon dropdown-toggle"
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false">
                                                                                        <i className="material-icons">more_vert</i>
                                                                                    </a>
                                                                                    <div
                                                                                        className="dropdown-menu dropdown-menu-right">
                                                                                        <a
                                                                                            className="dropdown-item"
                                                                                            href="#"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#edit_housing_benefit"
                                                                                            onClick={() => setEditHousingBenefitData(housingItem.id)}
                                                                                        >
                                                                                            <i className="fa fa-pencil m-r-5"/> Edit
                                                                                        </a>
                                                                                        <a
                                                                                            className="dropdown-item"
                                                                                            href="#"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#delete_housing_benefit"
                                                                                            onClick={() => setEditHousingBenefitData(housingItem.id)}
                                                                                        >
                                                                                            <i className="fa fa-trash m-r-5"/> Delete
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr className={`collapse row${housingItem.id}`}>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>
                                                                                {housingItem.employeeName && (
                                                                                    <div>
                                                                                        <ul>
                                                                                            {housingItem.employeeName.map((name, index) => (
                                                                                                <li key={index}>{name}</li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))}
                                                            </>
                                                            </tbody>
                                                        </table>
                                                </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="tab_cash_benefits">
                                                <div className="text-end mb-4 clearfix">
                                                    <button
                                                        className="btn btn-primary add-btn"
                                                        type="button"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#add_cash_benefit">
                                                        <i className="fa fa-plus"/> Add Cash Benefit
                                                    </button>
                                                </div>

                                                <div className="payroll-table card">
                                                    <div className="table-responsive">
                                                        <table
                                                            className="table table-hover table-radius custom-table datatable">
                                                            <thead>
                                                            <tr>
                                                                <th>Cash Benefit</th>
                                                                <th>Permissable Limit (Pension)</th>
                                                                <th>Amount</th>
                                                                <th>Employees</th>
                                                                <th className="text-end">Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <>
                                                                {filterCashBenefitData().map(cashItem => (
                                                                    <React.Fragment>
                                                                        <tr key={cashItem.id} className="clickable" data-toggle="collapse"
                                                                            id={`row${cashItem.id}`} data-target={`.row${cashItem.id}`}>
                                                                            <td>
                                                                                {cashItem.cashBenefitName}
                                                                            </td>
                                                                            <td>
                                                                                {cashItem.permissibleLimit}
                                                                            </td>
                                                                            <td>
                                                                                {cashItem.cashBenefitAmount}
                                                                            </td>
                                                                            <td>
                                                                                <Link className="btn btn-sm btn-primary"
                                                                                      to="#">
                                                                                    View Employees
                                                                                </Link>
                                                                            </td>
                                                                            <td>
                                                                                <div
                                                                                    className="dropdown dropdown-action text-end">
                                                                                    <a
                                                                                        href="#"
                                                                                        className="action-icon dropdown-toggle"
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-expanded="false">
                                                                                        <i className="material-icons">more_vert</i>
                                                                                    </a>
                                                                                    <div
                                                                                        className="dropdown-menu dropdown-menu-right">
                                                                                        <a
                                                                                            className="dropdown-item"
                                                                                            href="#"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#edit_cash_benefit"
                                                                                            onClick={() => setEditCashBenefitData(cashItem.id)}
                                                                                        >
                                                                                            <i className="fa fa-pencil m-r-5"/> Edit
                                                                                        </a>
                                                                                        <a
                                                                                            className="dropdown-item"
                                                                                            href="#"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#delete_cash_benefit"
                                                                                            onClick={() => setEditCashBenefitData(cashItem.id)}
                                                                                        >
                                                                                            <i className="fa fa-trash m-r-5"/> Delete
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr className={`collapse row${cashItem.id}`}>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>
                                                                                {cashItem.employeeName && (
                                                                                    <div>
                                                                                        <ul>
                                                                                            {cashItem.employeeName.map((name, index) => (
                                                                                                <li key={index}>{name}</li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))}
                                                            </>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane" id="tab_allowances">
                                        <div className="text-end mb-4 clearfix">
                                            <button
                                                className="btn btn-primary add-btn"
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_allowance">
                                                <i className="fa fa-plus"/> Add Allowance
                                            </button>
                                        </div>

                                        <div className="payroll-table card">
                                            <div className="table-responsive">
                                                <table className="table table-hover table-radius">
                                                    <thead>
                                                    <tr>
                                                        <th>Type of Allowance</th>
                                                        <th>Amount</th>
                                                        <th>Employees</th>
                                                        <th className="text-end">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <>
                                                        {filterAllowanceData().map(allowanceItem => (
                                                            <React.Fragment key={allowanceItem.id}>
                                                                <tr className="clickable" data-toggle="collapse"
                                                                    id={`row${allowanceItem.id}`}
                                                                    data-target={`.row${allowanceItem.id}`}>
                                                                    <td>{allowanceItem.allowanceName}</td>
                                                                    <td>{allowanceItem.allowanceAmount}</td>
                                                                    <td>
                                                                        <Link className="btn btn-sm btn-primary"
                                                                              to="#">
                                                                            View Employees
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <div className="dropdown profile-action">
                                                                            <Link
                                                                                to="#"
                                                                                className="action-icon dropdown-toggle"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false">
                                                                                <i className="material-icons">more_vert</i>
                                                                            </Link>
                                                                            <div
                                                                                className="dropdown-menu dropdown-menu-right">
                                                                            <Link
                                                                                    className="dropdown-item"
                                                                                    to="#"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#edit_allowance"
                                                                                    onClick={() => setEditAllowanceData(allowanceItem.id)}
                                                                                >
                                                                                    <i className="fa fa-pencil m-r-5"/> Edit
                                                                                </Link>

                                                                                <Link
                                                                                    className="dropdown-item"
                                                                                    to="#"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#delete_allowance"
                                                                                    onClick={() => setEditAllowanceData(allowanceItem.id)}
                                                                                >
                                                                                    <i className="fa-regular fa-trash-can m-r-5"/> Delete
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr className={`collapse row${allowanceItem.id}`}>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>
                                                                        {allowanceItem.employeeName && (
                                                                            <div>
                                                                                <ul>
                                                                                    {allowanceItem.employeeName.map((name, index) => (
                                                                                        <li key={index}>{name}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane" id="tab_other_deductions">
                                        <div className="text-end mb-4 clearfix">
                                            <button
                                                className="btn btn-primary add-btn"
                                                type="button"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_other_deduction">
                                                <i className="fa fa-plus"/> Add Other Deductions
                                            </button>
                                        </div>

                                        <div className="payroll-table card">
                                            <div className="table-responsive">
                                                <table className="table table-hover table-radius">
                                                    <thead>
                                                    <tr>
                                                        <th>Deduction Name</th>
                                                        <th>Amount</th>
                                                        <th>Employees</th>
                                                        <th className="text-end">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <>
                                                        {filterOtherDeductionData().map((item, index) => (
                                                            <React.Fragment key={item.id}>
                                                                <tr className="clickable" data-toggle="collapse"
                                                                    id={`row${item.id}`} data-target={`.row${item.id}`}>
                                                                    <td>{item.deductionName}</td>
                                                                    <td>{item.deductionAmount}</td>
                                                                    <td>
                                                                        <Link className="btn btn-sm btn-primary" to="#">
                                                                            View Employees
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <div
                                                                            className="dropdown dropdown-action text-end">
                                                                            <a
                                                                                href="#"
                                                                                className="action-icon dropdown-toggle"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false">
                                                                                <i className="material-icons">more_vert</i>
                                                                            </a>
                                                                            <div
                                                                                className="dropdown-menu dropdown-menu-right">
                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    href="#"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#edit_other_deduction"
                                                                                    onClick={() => setEditOtherDeductionData(item.id)}>
                                                                                    <i className="fa fa-pencil m-r-5"/> Edit
                                                                                </a>
                                                                                <a
                                                                                    className="dropdown-item"
                                                                                    href="#"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#delete_other_deduction"
                                                                                    onClick={() => setEditOtherDeductionData(item.id)}
                                                                                >
                                                                                    <i className="fa fa-trash m-r-5"/> Delete
                                                                                </a>

                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr className={`collapse row${item.id}`}>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>
                                                                        {item.employeeName && (
                                                                            <div>
                                                                                <ul>
                                                                                    {item.employeeName.map((name, index) => (
                                                                                        <li key={index}>{name}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ))}
                                                    </>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <PayrollitemForm/>

                            {/* Edit Allowance Modal */}
                            <div
                                id="edit_allowance"
                                className="modal custom-modal fade"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Allowance</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit1} noValidate>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label>
                                                                Allowance Name <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="allowanceName"
                                                                value={allowanceFormData?.allowanceName || ''}
                                                                onChange={handleAllowanceInputChange}
                                                                required
                                                            >

                                                                <option value>Select Allowance</option>
                                                                <option value='housing'>Housing</option>
                                                                <option value='transport'>Transport</option>
                                                                <option value='leavePay'>Leave Pay</option>
                                                                <option value='overtimeAllowance'>Overtime Allowance
                                                                </option>
                                                                <option value='directorsFee'>Director's Fee</option>
                                                                <option value='lumpSumPayment'>Lump Sum Payment</option>
                                                                <option value='otherAllowance'>Other Allowance</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label>
                                                                Allowance Amount <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="allowanceAmount"
                                                                value={allowanceFormData?.allowanceAmount || ''}
                                                                onChange={handleAllowanceInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label>Select Employees<span
                                                                className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="employeeName"
                                                                styles={customStyles}
                                                                options={employees.map(employee => ({
                                                                    value: employee.employeeName,
                                                                    label: employee.employeeName
                                                                }))}
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                    setOtherDeductionFormData({
                                                                        ...otherDeductionFormData,
                                                                        employeeName: selectedValues
                                                                    });
                                                                }}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal" type='submit'>
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Allowance Modal */}

                            {/* Delete Allowance Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_allowance"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Allowance</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="" className="btn btn-primary continue-btn"
                                                              data-bs-dismiss="modal" onClick={handleDelete1}>
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Allowance Modal */}


                            {/* Edit Non Cash Benefit Modal */}
                            <div
                                id="edit_non_cash_benefit"
                                className="modal custom-modal fade"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Non Cash Benefit</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit2} noValidate>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label>
                                                                Non Cash Benefit Name <span
                                                                className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="nonCashBenefitName"
                                                                value={nonCashBenefitFormData?.nonCashBenefitName || ''}
                                                                onChange={handleNonCashBenefitInputChange}
                                                                required
                                                            >
                                                                <option value>Select Non-Cash Benefit</option>
                                                                <option value='car-benefit'>Car Benefit</option>
                                                                <option value='other'>Other</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label>
                                                                Amount <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="nonCashBenefitAmount"
                                                                value={nonCashBenefitFormData?.nonCashBenefitAmount || ''}
                                                                onChange={handleNonCashBenefitInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label>Select Employees<span
                                                                className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="employeeName"
                                                                options={employees.map(employee => ({
                                                                    value: employee.employeeName,
                                                                    label: employee.employeeName
                                                                }))}
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                    setOtherDeductionFormData({
                                                                        ...otherDeductionFormData,
                                                                        employeeName: selectedValues
                                                                    });
                                                                }}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal">
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Non Cash Benefit Modal */}

                            {/* Delete Non Cash Benefit Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_non_cash_benefit"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Non Cash Benefit</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="" className="btn btn-primary continue-btn"
                                                              data-bs-dismiss="modal" onClick={handleDelete2}>
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Non Cash Benefit Modal */}


                            {/* Edit Housing Benefit Modal */}
                            <div
                                id="edit_housing_benefit"
                                className="modal custom-modal fade"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Housing Benefit</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit3} noValidate>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label>
                                                                Type of Housing <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="typeOfHousing"
                                                                value={housingBenefitFormData?.typeOfHousing || ''}
                                                                onChange={handleHousingBenefitInputChange}
                                                                required
                                                            >
                                                                <option value>Select Type of Housing</option>
                                                                <option value='employeesOwnedHouse'>Employees Owned Hous</option>
                                                                <option value='employeesRentedHouse'>Employees Rented House</option>
                                                                <option value='agriculture'>Agriculture</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Rent of House / Market Value <span
                                                                className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="rentOfHouseMarketValue"
                                                                value={housingBenefitFormData?.rentOfHouseMarketValue || ''}
                                                                onChange={handleHousingBenefitInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Rent Recovered from Employee <span
                                                                className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="rentRecoveredFromEmployee"
                                                                value={housingBenefitFormData?.rentRecoveredFromEmployee || ''}
                                                                onChange={handleHousingBenefitInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label>Select Employees<span
                                                                className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="employeeName"
                                                                styles={customStyles}
                                                                options={employees.map(employee => ({
                                                                    value: employee.employeeName,
                                                                    label: employee.employeeName
                                                                }))}
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                    setOtherDeductionFormData({
                                                                        ...otherDeductionFormData,
                                                                        employeeName: selectedValues
                                                                    });
                                                                }}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal">
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Housing Benefit Modal */}

                            {/* Delete Housing Benefit Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_housing_benefit"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Housing Benefit</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="" className="btn btn-primary continue-btn"
                                                              data-bs-dismiss="modal" onClick={handleDelete3}>
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Housing Benefit Modal */}


                            {/* Edit Cash Benefit Modal */}
                            <div
                                id="edit_cash_benefit"
                                className="modal custom-modal fade"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Cash Benefit</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit4} noValidate>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Cash Benefit Name <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="cashBenefitName"
                                                                value={cashBenefitFormData?.cashBenefitName || ''}
                                                                onChange={handleCashBenefitInputChange}
                                                                required
                                                            >
                                                                <option value>Select Cash Benefit</option>
                                                                <option value='pensionBenefits'>Pension Benefits
                                                                </option>
                                                                <option value='mortgageInterest'>Mortgage Interest (Max
                                                                    2500)
                                                                </option>
                                                                <option value='depositOnHomeOwnerShip'>Deposit on Home
                                                                    Ownership
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Cash Benefit Amount <span
                                                                className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="cashBenefitAmount"
                                                                value={cashBenefitFormData?.cashBenefitAmount || ''}
                                                                onChange={handleCashBenefitInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="input-block">
                                                            <label>Select Employees<span
                                                                className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="employeeName"
                                                                styles={customStyles}
                                                                options={employees.map(employee => ({
                                                                    value: employee.employeeName,
                                                                    label: employee.employeeName
                                                                }))}
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                    setOtherDeductionFormData({
                                                                        ...otherDeductionFormData,
                                                                        employeeName: selectedValues
                                                                    });
                                                                }}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal">
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Cash Benefit Modal */}

                            {/* Delete Cash Benefit Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_cash_benefit"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Cash Benefit</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="" className="btn btn-primary continue-btn"
                                                              data-bs-dismiss="modal" onClick={handleDelete4}>
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Cash Benefit Modal */}

                            {/* Edit Other Deduction Modal */}
                            <div
                                id="edit_other_deduction"
                                className="modal custom-modal fade"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Other Deduction</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit5} noValidate>
                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label>
                                                            Deduction Name <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="deductionName"
                                                            value={otherDeductionFormData?.deductionName || ''}
                                                            onChange={handleOtherDeductionInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label>
                                                            Amount <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            name="deductionAmount"
                                                            value={otherDeductionFormData?.deductionAmount || ''}
                                                            onChange={handleOtherDeductionInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-12">
                                                    <div className="input-block">
                                                        <label>Select Employees<span
                                                            className="text-danger">*</span></label>
                                                        <Select
                                                            isMulti
                                                            name="employeeName"
                                                            options={employees.map(employee => ({
                                                                value: employee.employeeName,
                                                                label: employee.employeeName
                                                            }))}
                                                            onChange={(selectedOptions) => {
                                                                const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                setOtherDeductionFormData({
                                                                    ...otherDeductionFormData,
                                                                    employeeName: selectedValues
                                                                });
                                                            }}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal"
                                                            type='submit'>
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Other Deduction Modal */}

                            {/* Delete Other Deduction Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_other_deduction"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Other Deduction</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="" className="btn btn-primary continue-btn"
                                                              data-bs-dismiss="modal" onClick={handleDelete5}>
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Other Deduction Modal */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayrollItems;
