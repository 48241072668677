import React, { useState, useEffect, useContext } from "react";
import { db } from '../../../../firebase.js';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { UserContext } from "../../../../InitialPage/App.js";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const Downloads = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const handleEntriesChange = (e) => {
        const value = e.target.value;
        setEntriesPerPage(value === 'all' ? entriesCount : parseInt(value));
    };

    const [formData, setFormData] = useState({});

    const [resultsData, setResultsData] = useState({
        netSalary:0,
        nhifContribution:0,
        nssfContribution:0,
        payePayable:0,
        housingLevy: 0,
        allowableDeductions: 0,
        totalDeductions: 0,
        taxablePay: 0,
        netPay: 0,
    });

    const [focused, setFocused] = useState(false);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [employees, setEmployees] = useState([]);
    const [editSalaryId, setEditSalaryId] = useState(null);

    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);
    

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            (item.employeeName && item.employeeName.toLowerCase().includes(input.toLowerCase())) ||
            (item.netPay && item.netPay.toLowerCase().includes(input.toLowerCase())) ||
            (item.grossSalary && item.grossSalary.toLowerCase().includes(input.toLowerCase()))
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted && item.status === 'approved');
        } else {
            return filteredData.filter(item => !item.deleted && item.status === 'approved');
        }
    };

    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    };
    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const navigate = useNavigate();
    const handleGeneratePayslip = (employee) => {
        console.log("Generating payslip for employee:", employee);
        navigate(`/finance/payroll/payslip/${employee.id}`);
    };

    const handleGenerateP9Form = (employee) => {
        console.log("Generating P9 Form for employee:", employee);
        navigate(`/finance/payroll/generated-p9form/${employee.id}`);
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];

            querySnapshot.forEach((doc) => {
                const employeeData = doc.data();

                // Check if the employee is active and not deleted
                if (employeeData.status === 'active' && 
                    !employeeData.deleted &&
                    employeeData.organisationId === userData[0]?.organisationId
                ) {
                    employeeList.push({ id: doc.id, ...employeeData });
                }
            });
            setEmployees(employeeList);
        };
        fetchEmployees();
    }, []);

    const fetchCompanyName = async (organisationId) => {
        try {
            const q = query(collection(db, 'add-company'), where('organisationId', '==', organisationId));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data().companyName;
            }
        } catch (error) {
            console.error("Error fetching company name:", error);
        }
        return "Unknown Company";
    };
    

    useEffect(() => {
        const fetchData = async () => {
          try {
            setLoading(true);

            const colRefSalary = collection(db, 'add-salary');
            // const querySnapshotSalary = await getDocs(colRefSalary);
            let querySnapshotSalary;

            if (userData && userData[0]?.role !== 'sys-admin') {
                // If the user is not a sys-admin, filter companies based on organisation ID
                querySnapshotSalary = await getDocs(query(colRefSalary, where('organisationId', '==', userData[0]?.organisationId)));
            } else {
                // If user is sys-admin, fetch all companies
                querySnapshotSalary = await getDocs(colRefSalary);
            }
                
            const dataArraySalary = querySnapshotSalary.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(item => !item.deleted);

                const dataWithAdditionalInfo = await Promise.all(dataArraySalary.map(async item => {
                    const employeeQuerySnapshot = await getDocs(query(collection(db, 'add-employee'), where('employeeName', '==', item.employeeName)));
                    const employeeData = employeeQuerySnapshot.docs[0]?.data(); // Added check here
                    const companyName = await fetchCompanyName(item.organisationId); // Fetch company name
                    // if (employeeData) {
                        return {
                            id: item.id,
                            ...item,
                            employeeName: item.employeeName,
                            grossSalary: item.grossSalary,
                            netSalary: item.netSalary,
                            companyName, // Add the company name to the item
                        };
                    // } else {
                        // return null; // Return null if no matching document is found
                    // }
                }));

            const filteredData = dataWithAdditionalInfo.filter(item => item !== null); // Filter out null entries

            setData(filteredData);
            setLoading(false);
            setEntriesCount(filteredData.length);

          } catch (error) {
            console.error('Error fetching data:', error);
              setLoading(false);
          }
        };

        fetchData();
      }, [userData, searchInput, entriesPerPage]);

    
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Downloads</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Downloads</li>
                                            </ul>
                                        </div>
                                        {userData[0]?.role !== 'sys-admin' && (  
                                        <>
                                        {/* <div className="col-auto float-end ms-auto">
                                                <Link
                                                    to="/finance/payroll/payslip/all"
                                                    className="btn add-btn">
                                                    <i className="fa fa-download" /> Generate All Payslips
                                                </Link>
                                            </div><div className="col-auto float-end ms-auto">
                                                    <Link
                                                        to="/finance/payroll/generated-p9form/all"
                                                        className="btn add-btn">
                                                        <i className="fa fa-download" /> Generate All P9 Forms
                                                    </Link>
                                                </div> */}
                                                <div className="col-auto float-end ms-auto">
                                                    <Link
                                                        to="/finance/payroll/muster-roll"
                                                        className="btn add-btn"
                                                    >
                                                        <i className="fa fa-download" /> Generate Muster Roll
                                                    </Link>
                                                </div><div className="col-auto float-end ms-auto">
                                                    <Link
                                                        to="/finance/payroll/annual-muster-roll"
                                                        className="btn add-btn">
                                                        <i className="fa fa-download" /> Annual Muster Roll
                                                    </Link>
                                                </div></>
                                        )}
                                    </div>
                                </div>

                                <div className="row filter-row">
                                    <div className="col-sm-6 col-md-12">
                                        <div
                                            className={
                                                focused
                                                    ? "input-block form-focus focused"
                                                    : "input-block form-focus"
                                            }>
                                            <input
                                                type="text"
                                                className="form-control floating"
                                                onFocus={() => setFocused(true)}
                                                onBlur={() => setFocused(false)}
                                                value={searchInput}
                                                onChange={handleSearchInputChange}
                                            />
                                            <label className="focus-label ">Search...</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row mt-4">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm"
                                                                    value={entriesPerPage}
                                                                    onChange={handleEntriesChange}>
                                                                <option value="all">All</option>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th>Employee</th>
                                                        {userData[0]?.role === 'sys-admin' && (  
                                                            <th>Organisation</th>
                                                        )}
                                                        <th>Month</th>
                                                        <th>Gross Salary</th>
                                                        <th>Net Pay</th>
                                                        <th>Payslip</th>
                                                        <th>P9 Form</th>
                                                        {/* <th className="text-end">Action</th> */}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {filterData().slice(0, entriesPerPage).map(item => (
                                                        <tr key={item.id}>
                                                            <td>{item.employeeName}</td>
                                                            {userData[0]?.role === 'sys-admin' && (  
                                                                <td>{item.companyName}</td>
                                                            )}
                                                            <td>{item.month}</td>
                                                            <td>{item.totalGrossPay && new Intl.NumberFormat().format(item.totalGrossPay) || "N/A"}</td>
                                                            <td>{item.netPay && new Intl.NumberFormat().format(item.netPay)}</td>
                                                            <td>
                                                                <Link to={`/finance/payroll/payslip/${item.id}`}
                                                                      onClick={() => handleGeneratePayslip(item)}
                                                                      className="btn btn-sm btn-primary">
                                                                    Generate Slip
                                                                </Link>
                                                            </td>
                                                            {(item.employmentType !== 'Consultancy') ? (
                                                            <td>
                                                                <Link
                                                                    to={`/finance/payroll/generated-p9form/${item.id}`}
                                                                    onClick={() => handleGenerateP9Form(item)}
                                                                    className="btn btn-sm btn-primary">
                                                                    Generate Form
                                                                </Link>
                                                            </td>
                                                            ): 
                                                            <td>N/Aq</td>
                                                            }
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="table-footer">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info">
                                                    {searchInput
                                                        ? `Showing 1 to ${Math.min(filteredEntriesCount, entriesPerPage)} of ${filteredEntriesCount} entries`
                                                        : `Showing 1 to ${Math.min(entriesCount, entriesPerPage)} of ${entriesCount} entries`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Downloads;
