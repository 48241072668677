import React from "react";
import logo2 from "../../assets/img/new-rhokit-logo.png";
import {Link} from "react-router-dom";

const ChooseReceiveWallet = () => {
    return (
        <>
            <div className="register">
                <div className="account-page">
                    <div className="main-wrapper">
                        <div className="account-content">
                            <div className="container">
                                <div className="account-logo">
                                    <img src={logo2} alt="Logo"/>
                                </div>

                                <div className="account-box">
                                    <div className="account-wrapper">
                                        <h3 className="account-title">Register</h3>
                                        <p className="account-subtitle">Access to our dashboard</p>

                                        <p className="text-center">
                                            Please select whether you would like to receive a DTB wallet upon registration to continue
                                        </p>
                                        <p className="text-center text-primary text-xs">
                                            Your company information and relevant documents are required for wallet creation
                                        </p> 

                                        <div className="row text-center">
                                            <div className="flex">
                                                <Link to="/orgregister" className="btn btn-primary m-r-10">
                                                    Yes
                                                </Link>

                                                <Link to="/register-organization" className="btn btn-primary">
                                                   No
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="account-footer text-center mb-5">
                                        <p>
                                            Already have an account? <Link to="/">Login</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChooseReceiveWallet;
