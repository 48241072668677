import {useEffect} from 'react';
import { db } from '../../../../firebase.js';
import { collection, addDoc, getDoc, getDocs, doc, where, query, orderBy, limit } from 'firebase/firestore';

async function fetchTaxRates() {
  try {
      // Query to get the document with the highest version
      const q = query(
          collection(db, 'tax-rates'), 
          orderBy("version", "desc"),
          limit(1)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
          const latestTaxRate = querySnapshot.docs[0].data();
          console.log("Latest Tax Rate:", latestTaxRate);
          return latestTaxRate;
      } else {
          console.log("No tax rates found.");
          return null;
      }
  } catch (error) {
      console.error("Error fetching tax rates:", error);
  }
}

const latestTaxRate = await fetchTaxRates();

// Constants
export const CONSTANTS = {
  // MAX_PENSION_CONTRIBUTION: 20000,
  personalRelief: latestTaxRate.personalRelief,
  MAX_INSURANCE_RELIEF: latestTaxRate.maxInsuranceRelief,
  MAX_NSSF_CONTRIBUTION: latestTaxRate.maxNssfContribution,
  permissibleLimit: latestTaxRate.permissibleLimit,
};

async function calculateTotalCashPay(employeeName, basicSalary, allowances) {
  let totalCashPay = parseFloat(basicSalary);

  // Calculate total allowance for the selected employee
  allowances.forEach(allowance => {
      if (allowance.employeeName.includes(employeeName)) {
          totalCashPay += parseFloat(allowance.allowanceAmount);
      }
  });

  return totalCashPay;
}

async function calculateWithholdingTax(basicSalary) {
  let amountToBeDeducted = 0;
  let netPayWithholding = 0;

  amountToBeDeducted = basicSalary * latestTaxRate.withHoldingTax;
  netPayWithholding = basicSalary - amountToBeDeducted;

  return {netPayWithholding, amountToBeDeducted};
}


async function calculateTotalNonCashPay(employeeName, nonCashBenefits) {
    // const nonCashBenefitsSnapshot = await getDocs(collection(db, 'payroll-noncash-benefit'), where('employeeName', '==', employeeName));
    // const nonCashBenefitsSnapshot = await db.collection('payroll-noncash-benefit').where('employeeName', '==', employeeName).get();
    let totalNonCashPay = 0;

    nonCashBenefits.forEach(nonCashBenefit => {
      if (nonCashBenefit.employeeName && nonCashBenefit.employeeName.includes(employeeName)) {
          const name = nonCashBenefit.nonCashBenefitName;
          const amount = nonCashBenefit.nonCashBenefitAmount;

          if (name === 'Car Benefit' || (name === 'Other' && amount > 3000)) {
              totalNonCashPay += parseFloat(amount);
          }
      }
  });

    return totalNonCashPay;
};

async function calculateComputedRent(employeeName, totalCashPay, totalNonCashPay, housingBenefits) {
  function lookupTypeOfHousingCode(typeOfHousing) {
    switch (typeOfHousing) {
      case "Employees Owned House":
        return 0;
      case "Employees Rented House":
        return 1;
      case "Agriculture":
        return 2;
      default:
        return 0; // Return 0 for unknown or undefined housing types
    }
  }

  let rentOfHouseComputed = 0;

  housingBenefits.forEach(housingBenefit => {
    if(housingBenefit.employeeName && housingBenefit.employeeName.includes(employeeName)){
      let typeOfHousing = housingBenefit.typeOfHousing;

      const housingCode = lookupTypeOfHousingCode(typeOfHousing);
      let rentOfHouseMarketValue = housingBenefit.rentOfHouseMarketValue;
      switch (housingCode) {
        case 0: // Employees Owned House
        //   return 0;
            // rentOfHouseComputed = 0;
            rentOfHouseComputed = (latestTaxRate.rentOfHouseOwnedRented * (totalCashPay + totalNonCashPay) > rentOfHouseMarketValue ?
                                      rentOfHouseMarketValue :
                                      latestTaxRate.rentOfHouseOwnedRented  * (totalCashPay + totalNonCashPay));
            break;
        case 1: // Employees Rented House
            // rentOfHouseComputed = Math.min(0.15 * rentOfHouseMarketValue, 3000);
            rentOfHouseComputed = latestTaxRate.rentOfHouseOwnedRented  * (totalCashPay + totalNonCashPay) > rentOfHouseMarketValue ?
                                      rentOfHouseMarketValue :
                                      latestTaxRate.rentOfHouseOwnedRented  * (totalCashPay + totalNonCashPay);
            break;
        //   return Math.min(0.15 * rentOfHouseMarketValue, 3000);
        case 2: // Agriculture
            rentOfHouseComputed = latestTaxRate.rentOfHouseAgriculture  * (totalCashPay + totalNonCashPay);
            break;
        //   return Math.min(0.15 * (totalCashPay + totalNonCashPay), rentOfHouseMarketValue);
        default:
            rentOfHouseComputed = 0;
        //   return 0; // Default case
      }
    }
  })
    return rentOfHouseComputed;
};

async function calculateNetValueOfHousing(rentOfHouseComputed, rentRecoveredFromEmployee) {
    // Calculate net value of housing using the provided formula
    let netValueOfHousing = Math.max(0, parseFloat(rentOfHouseComputed) - parseFloat(rentRecoveredFromEmployee));

    // Round the result to 2 decimal places
    netValueOfHousing = Math.round(parseFloat(netValueOfHousing) * 100) / 100;

    // Check if the result is less than 0, and if so, set it to 0
    netValueOfHousing = netValueOfHousing < 0 ? 0 : netValueOfHousing;

    return netValueOfHousing; // Return the net value of housing
};

async function calculateTotalGrossPay(totalCashPay, totalNonCashPay, netValueOfHousing){
  let totalGrossPay = parseFloat(totalCashPay) + parseFloat(totalNonCashPay) + parseFloat(netValueOfHousing);

  return totalGrossPay;
};

async function calculatePensionCashPay(totalCashPay) {
    // Calculate pension contribution (30% of totalCashPay)
    let pensionCashPay = 0;

    // 0.3
    pensionCashPay = latestTaxRate.pensionCashPay * parseFloat(totalCashPay);

    return pensionCashPay; // Return the calculated pension contribution rounded to 2 decimal places

}

async function calculateAmountOfBenefit(employeeNames, pensionCashPay, cashBenefits) {
  try {
      let totalAmountOfBenefit = 0;

      // Fetch the permissible limit
      const permissibleLimit = CONSTANTS.permissibleLimit;

      // Iterate over each employeeName in the array
      for (const employeeName of employeeNames) {
          // Filter cash benefits for the current employee
          const employeeCashBenefits = cashBenefits.filter(cashBenefit => cashBenefit.employeeName === employeeName);

          let actualPensionContribution = 0;
          let mortgageInterest = 0;
          let depositOnHomeOwnershipPlan = 0;

          // Iterate over the cash benefits of the current employee
          employeeCashBenefits.forEach(cashBenefit => {
              if (cashBenefit.cashBenefitName === 'Pension Benefits') {
                  actualPensionContribution += cashBenefit.cashBenefitAmount;
              } else if (cashBenefit.cashBenefitName === 'Mortgage Interest') {
                  mortgageInterest += cashBenefit.cashBenefitAmount;
              } else if (cashBenefit.cashBenefitName === 'Deposit On Home Ownership Plan') {
                  depositOnHomeOwnershipPlan += cashBenefit.cashBenefitAmount;
              }
          });

          // Apply the formula to calculate the amount of benefit for the current employee
          let amountOfBenefit = 0;
          if (pensionCashPay < actualPensionContribution) {
              amountOfBenefit = Math.min(pensionCashPay < permissibleLimit ? permissibleLimit : pensionCashPay, actualPensionContribution < permissibleLimit ? pensionCashPay : permissibleLimit);
          } else {
              amountOfBenefit = Math.min(pensionCashPay, permissibleLimit) + Math.max(mortgageInterest, depositOnHomeOwnershipPlan);
          }

          // Accumulate the total amount of benefit for all employees
          totalAmountOfBenefit += amountOfBenefit;
      }

      return parseFloat(totalAmountOfBenefit.toFixed(2));  // Return the total calculated amount of benefit rounded to 2 decimal places

  } catch (error) {
      console.error("Error calculating total amount of benefit:", error);
      return 0; // Return 0 in case of an error
  }
}

// Helper function to calculate NSSF contribution
async function calculateNSSF(totalGrossPay) {
  let nssfContribution = 0;

  // 0.06
  if (totalGrossPay <= 6000) {
      nssfContribution = totalGrossPay * latestTaxRate.nssfContribution;
  } else if (totalGrossPay <= 18000) {
      nssfContribution = 360 + (totalGrossPay - 6000) *  latestTaxRate.nssfContribution;
  } else {
      nssfContribution = CONSTANTS.MAX_NSSF_CONTRIBUTION;
  }
  return nssfContribution;
}

async function calculateNHIF(totalGrossPay) {
  let nhifContribution = 0;

  if (totalGrossPay <= 0) {
      return nhifContribution;
  }

  let brackets = [6000, 8000, 12000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000, 60000, 70000, 80000, 90000, 100000];
  let contributions = [150, 300, 400, 500, 600, 750, 850, 900, 950, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700];

  for(let i = 0; i < brackets.length; i++){
      if(totalGrossPay < brackets[i]){
        nhifContribution = contributions[i]; // Update nhifContribution based on totalGrossPay
        return nhifContribution;
      }
  }

  nhifContribution = contributions[contributions.length-1];

  return nhifContribution; // Return nhifContribution
}

async function calculateHousing(totalGrossPay) {
  let housingLevy = 0;
  //0.015

  housingLevy = totalGrossPay * latestTaxRate.housingLevy;

  return housingLevy;
}


async function calculateTaxablePay(totalGrossPay, totalDeductions, totalAmountOfBenefit) {
  try {
    // Calculate the taxable pay by subtracting the total amount of benefit from the total gross pay
    let taxablePay = Math.max(0, totalGrossPay - totalAmountOfBenefit - totalDeductions);

    return taxablePay; // Return the taxable pay rounded to 2 decimal places
  } catch (error) {
    console.error("Error calculating taxable pay:", error);
    return ""; // Return an empty string in case of an error
  }
}

// Helper function to calculate taxable amount
function calculateTaxPayable(taxablePay) {
  let taxPayable = 0;
  //if chargeable is less than 24000, tax is 10% of chargeable
  if (taxablePay <= 24000) {
      taxPayable = taxablePay * latestTaxRate.taxPayable1;
  }
  //if chargeable is between 24001 and 32333, tax is 10% of 24000 + 25% of the amount above 24000
  else if (taxablePay > 24000 && taxablePay <= 32333) {
    taxPayable = (24000 * latestTaxRate.taxPayable1) + ((taxablePay - 24000) * latestTaxRate.taxPayable2);
  }
  //if chargeable is between 32334 and 500000, tax is 10% of 24000 + 25% of 8333 + 30% of the amount above 32333
  else if (taxablePay > 32333 && taxablePay <= 500000){
    taxPayable = (24000 * latestTaxRate.taxPayable1) + (8333 * latestTaxRate.taxPayable2) + ((taxablePay - 32333) * latestTaxRate.taxPayable3);
  }
  // if chargeable is between 500000 and 800000, tax is 10% of 24000 + 25% of 8333 + 30% of 467667 + 32.5% of the amount above 500000
  else if (taxablePay > 500000 && taxablePay <= 800000){
    taxPayable = (24000 * latestTaxRate.taxPayable1) + (8333 * latestTaxRate.taxPayable2) + (467667 * latestTaxRate.taxPayable3) +((taxablePay - 500000) * latestTaxRate.taxPayable4);
  }
   // if chargeable is above 500000, tax is 10% of 24000 + 25% of 8333 + 30% of 467667 + 32.5% of 300000 + 35% of the amount above 800000
  else {
    taxPayable = (24000 * latestTaxRate.taxPayable1) + (8333 * latestTaxRate.taxPayable2) + (467667 * latestTaxRate.taxPayable3) + (300000 * latestTaxRate.taxPayable4 ) +((taxablePay - 800000) * latestTaxRate.taxPayable5);
  }
  return taxPayable;
}

async function calculateTotalDeductions(employeeName, nssfContribution, deductions, housingLevy){

  // const deductionsSnapshot = await getDocs(collection(db, 'payroll-other-deduction'), where('employeeName', '==', employeeName));
  let totalDeductions = 0;

  deductions.forEach(deduction => {
    if (deduction.employeeName && deduction.employeeName.includes(employeeName)){
      totalDeductions += parseFloat(deduction.deductionAmount);

    }
  })

  totalDeductions = parseFloat(totalDeductions) + parseFloat(nssfContribution) + parseFloat(housingLevy); 

  return totalDeductions;
}

async function calculateInsuranceRelief(employeeName, nhifContribution, insuranceContribution) {
  try {
      // Retrieve insurance contribution from Firestore
      const insuranceContributionSnapshot = await getDocs(collection(db, 'add-salary'), where('employeeName', '==', employeeName));

      let insuranceContribution = 0;

      if (!insuranceContributionSnapshot.empty) {
          insuranceContributionSnapshot.forEach(doc => {
              insuranceContribution = doc.data().insuranceContribution;
          });
      }

      // Calculate amount of insurance relief (capped at a maximum of 5000)
      const totalContribution = nhifContribution + insuranceContribution;

      // 0.15
      const amountOfInsuranceRelief = Math.min(totalContribution * latestTaxRate.insuranceRelief, CONSTANTS.MAX_INSURANCE_RELIEF);

      return amountOfInsuranceRelief;
  } catch (error) {
      console.error("Error calculating insurance relief:", error);
      return ""; // Return an empty string in case of an error
  }
}

async function calculatePayeTax(taxPayable, amountOfInsuranceRelief){
  let personalRelief = CONSTANTS.personalRelief;

  let payePayable = taxPayable - personalRelief - amountOfInsuranceRelief;

  return payePayable;

}

async function calculateNetPay(totalGrossPay, payePayable, totalDeductions){
  let netPay = totalGrossPay - payePayable - totalDeductions;

  return netPay;
}

export { calculateTotalCashPay, calculateTotalNonCashPay, calculateComputedRent, calculateNetValueOfHousing, calculateTotalGrossPay, calculatePensionCashPay, calculateAmountOfBenefit, calculateTaxablePay, calculateNSSF, calculateNHIF, calculateTaxPayable, calculateTotalDeductions, calculateInsuranceRelief, calculatePayeTax, calculateNetPay, calculateHousing, calculateWithholdingTax };
