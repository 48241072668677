import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "../../assets/css/register.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useNavigate } from "react-router-dom";
import logo2 from "../../assets/img/new-rhokit-logo.png";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { app, db } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function SetOrgPassword() {
  const auth = getAuth(app);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [repeatPasswordError, setRepeatPasswordError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  let { userId } = useParams();

  const fetchUser = async () => {
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      setUser(userSnapshot.data());
    } else {
      toast.error("User not found");
      navigate("/orgregister");
    }
  };

  useEffect(() => {
    let isMounted = true;

    fetchUser();

    return () => {
      isMounted = false;
    };
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
    setRepeatPasswordError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!e.target.checkValidity()) {
      toast.error("Please fill out all fields");
      return;
    }

    if (password.length < 8) {
      toast.error("Password must be at least 8 characters");
      return;
    }

    setLoading(true);
    // Create user in Firebase Authentication
    await createUserWithEmailAndPassword(auth, user.email, password)
      .then(() => {
        toast.success("Password set successfully");
        setLoading(false);
        navigate(`/orgregister/${userId}/verify-kyb`);
      })
      .catch((error) => {
        setLoading(false);
        if (error.code === "auth/email-already-in-use") {
          toast.error(
            "Email already in use. Riderecting to kyb verification..."
          );
          setTimeout(() => {
            navigate(`/orgregister/${userId}/verify-kyb`);
          }, 3000);
        } else {
          toast.error(error.message);
        }
      });
  };
  return (
    <div className="register">
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-logo">
                <img src={logo2} alt="Logo" />
              </div>

              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">Register</h3>
                  <p className="account-subtitle">Access to our dashboard</p>

                  <div>
                    <form onSubmit={handleSubmit} noValidate>
                      <h4>Set Password</h4>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="input-block">
                            <div className="row">
                              <div className="col">
                                <label>
                                  Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                            </div>

                            <div style={{ position: "relative" }}>
                              <input
                                type={showPassword ? "text" : "password"}
                                className={`form-control ${
                                  passwordError && "is-invalid"
                                }`}
                                name="password"
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder="Enter Password"
                              />
                              <FontAwesomeIcon
                                icon={showPassword ? faEye : faEyeSlash}
                                style={{
                                  position: "absolute",
                                  right: "5%",
                                  top: "30%",
                                  cursor: "pointer",
                                }}
                                onClick={togglePasswordVisibility}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input-block">
                            <div className="row">
                              <div className="col">
                                <label>
                                  Confirm Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                            </div>

                            <div style={{ position: "relative" }}>
                              <input
                                type={showRepeatPassword ? "text" : "password"}
                                className={`form-control ${
                                  repeatPasswordError && "is-invalid"
                                }`}
                                name="repeatPassword"
                                value={repeatPassword}
                                onChange={handleRepeatPasswordChange}
                                placeholder="Confirm Password"
                              />
                              <FontAwesomeIcon
                                icon={showRepeatPassword ? faEye : faEyeSlash}
                                style={{
                                  position: "absolute",
                                  right: "5%",
                                  top: "30%",
                                  cursor: "pointer",
                                }}
                                onClick={toggleRepeatPasswordVisibility}
                              />
                            </div>
                            <div className="text-danger">
                              {repeatPasswordError}
                            </div>
                          </div>
                        </div>
                        <div className="input-block text-center">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "saving..." : "Save Password"}
                          </button>
                        </div>
                      </div>
                    </form>

                    <div className="account-footer">
                      <p>
                        Already have an account? <Link to="/">Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </div>
  );
}
