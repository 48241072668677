import React, {useState, useEffect} from "react";
import { db } from '../../../firebase.js';
import { collection, addDoc, getDocs, where, query } from 'firebase/firestore';
import DatePicker from "react-datepicker";
import { DefaultEditor } from "react-simple-wysiwyg";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const ProjectForm = () => {
    const [formData, setFormData] = useState({
        projectName: '',
        startDate: '',
        projectDeadline: '',
        completionDate: '',
        projectTotalHours:'',
        teamName:'',
        projectLeader:'',
        priority: '',
        projectDescription: '',
        organisationId: 0,
    });
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [html, setHtml] = React.useState("my <b>HTML</b>");
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [selectedDate3, setSelectedDate3] = useState(null);

    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    };
    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };
    const handleDateChange3 = (date) => {
        setSelectedDate3(date);
    };

    function onChange(e) {
        setHtml(e.target.value);
    }

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Define a regular expression pattern to match invalid inputs
        const invalidPattern = /[^\w\s]/; // Matches any character that is not a word character or whitespace

        // Check if the field is the projectName field
        if (name === 'projectName') {
            // If it is the teamName field, check for invalid characters
            // if (invalidPattern.test(value)) {
            //     // If it contains invalid characters, don't update the state and show an error message
            //     showToast('Invalid input: Special characters and symbols are not allowed', 'error');
            //     return;
            // }
        }

        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    };

    const [employees, setEmployees] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            if (userData && userData[0]?.role !== 'sys-admin') {
                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' && 
                        !employeeData.deleted &&
                        employeeData.organisationId === userData[0]?.organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Non-Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            } else {
                if (!selectedCompany) return;

                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' &&
                        !employeeData.deleted &&
                        employeeData.organisationId === selectedCompany.organisationId // Use selected company's organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            }
        };
        fetchEmployees();
    }, [userData, selectedCompany]);


    const [teams, setTeams] = useState([]);

    useEffect(() => {
        const fetchTeams = async () => {
            if (userData && userData[0]?.role !== 'sys-admin') {
                const querySnapshot = await getDocs(collection(db, 'teams'));
                const teamList = [];
                querySnapshot.forEach((doc) => {
                    const teamData = doc.data();

                    // Check if the employee is active and not deleted
                    if (teamData.deleted &&
                        teamData.organisationId === userData[0]?.organisationId
                    ) {
                        teamList.push({ id: doc.id, ...teamData });
                    }
                });
                // console.log('Fetched Employees for Non-Admin:', employeeList); // Debug log
                setTeams(teamList);
            } else {
                if (!selectedCompany) return;

                const querySnapshot = await getDocs(collection(db, 'teams'));
                const teamList = [];
                querySnapshot.forEach((doc) => {
                    const teamData = doc.data();

                    // Check if the employee is active and not deleted
                    if (!teamData.deleted &&
                        teamData.organisationId === selectedCompany.organisationId // Use selected company's organisationId
                    ) {
                        teamList.push({ id: doc.id, ...teamData });
                    }
                });
                // console.log('Fetched Employees for Admin:', employeeList); // Debug log
                setTeams(teamList);
            }
        };
        fetchTeams();

        // const fetchTeams = async () => {
        //     const querySnapshot = await getDocs(collection(db, 'teams'));
        //     const teamList = [];
        //     querySnapshot.forEach((doc) => {
        //         const teamData = doc.data();
        //         if (!teamData.deleted && teamData.organisationId === userData[0]?.organisationId) {
        //             teamList.push({ id: doc.id, ...teamData });
        //         }
        //     });
        //     setTeams(teamList);
        // };
        // fetchTeams();
    }, [userData, selectedCompany]);

    const requiredFields =
        [
            'projectName',
            'priority',
        ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(formData).every(value => {
            if (value instanceof Date) {
                // Check if date value is not null or undefined
                return value !== null && value !== undefined;
            } else {
                // Check if other types of values are falsy
                return !value;
            }
        });

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // If the form is not empty, check each required field for empty value
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (requiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        let startDateString = null;
        let completionDateString = null;
        let deadlineDateString = null;

        if (selectedDate1) {
            const year = selectedDate1.getFullYear();
            const month = String(selectedDate1.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate1.getDate()).padStart(2, '0');
            startDateString = `${year}-${month}-${day}`;
        }

        if (selectedDate2) {
            const year = selectedDate2.getFullYear();
            const month = String(selectedDate2.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate2.getDate()).padStart(2, '0');
            completionDateString = `${year}-${month}-${day}`;
        }

        if (selectedDate3) {
            const year = selectedDate3.getFullYear();
            const month = String(selectedDate3.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate3.getDate()).padStart(2, '0');
            deadlineDateString = `${year}-${month}-${day}`;
        }

        // Calculate total hours worked by employees
        const totalEmployeeHours = employeeHours.reduce((total, employeeHour) => {
            return total + (parseFloat(employeeHour.employeeWorkHours) || 0);
        }, 0);

        // Check if total employee hours exceed total project hours
        if (totalEmployeeHours > parseFloat(formData.projectTotalHours)) {
            showToast('Total employee hours cannot exceed total project hours', 'error');
            return;
        }

        try {
            // const docRef = await addDoc(collection(db, 'projects'), {
            //     ...formData,
            //     startDate: startDateString,
            //     completionDate: completionDateString,
            //     projectDeadline: deadlineDateString,
            //     employeeHours: employeeHours.map(({ employeeName, employeeWorkHours }) => ({
            //         employeeName,
            //         employeeWorkHours
            //     })),
            //     organisationId: userData[0].organisationId,
            // });
            let docRef;
            if (userData && userData[0]?.role === 'hod') {
                docRef = await addDoc(collection(db, 'projects'), {
                    ...formData,
                    startDate: startDateString,
                    completionDate: completionDateString,
                    projectDeadline: deadlineDateString,
                    employeeHours: employeeHours.map(({ employeeName, employeeWorkHours }) => ({
                        employeeName,
                        employeeWorkHours
                    })),
                    organisationId: userData[0].organisationId,
                    departmentHead: userData[0].employeeName,
                });
            } else if (userData && userData[0]?.role !== 'sys-admin'){
                docRef = await addDoc(collection(db, 'projects'), {
                    ...formData,
                    startDate: startDateString,
                    completionDate: completionDateString,
                    projectDeadline: deadlineDateString,
                    employeeHours: employeeHours.map(({ employeeName, employeeWorkHours }) => ({
                        employeeName,
                        employeeWorkHours
                    })),
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'projects'), {
                    ...formData,
                    startDate: startDateString,
                    completionDate: completionDateString,
                    projectDeadline: deadlineDateString,
                    employeeHours: employeeHours.map(({ employeeName, employeeWorkHours }) => ({
                        employeeName,
                        employeeWorkHours
                    })),
                    organisationId: selectedCompany.organisationId,
                });
            }
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Project Added Successfully', 'success');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const [employeeHours, setEmployeeHours] = useState([{ employeeName: '', employeeWorkHours: '' }]);

    const addEmployeeHour = () => {
        setEmployeeHours([...employeeHours, { employeeName: '', employeeWorkHours: '' }]);
    };

    const removeEmployeeHour = (indexToRemove) => {
        setEmployeeHours(employeeHours.filter((_, index) => index !== indexToRemove));
    };

    const handleEmployeeNameChange = (e, index) => {
        const newEmployeeHours = [...employeeHours];
        newEmployeeHours[index].employeeName = e.target.value;
        setEmployeeHours(newEmployeeHours);
    };

    const handleHoursWorkedChange = (e, index) => {
        const newEmployeeHours = [...employeeHours];
        newEmployeeHours[index].employeeWorkHours = e.target.value;
        setEmployeeHours(newEmployeeHours);
    };

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-company'));
            const companyList = [];
            querySnapshot.forEach((doc) => {
                const companyData = doc.data();
                if (!companyData.deleted && companyData.status === 'active') {
                    companyList.push({ id: doc.id, ...companyData });
                }
            });
            setCompanies(companyList);
        };
        fetchCompanies();
    }, []);

    return (
        <>
            {/* Create Project Modal */}
            <div
                id="create_project"
                className="modal custom-modal fade"
                role="dialog"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Create Project</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="row">
                                <div className="col-md-12">
                                    {userData[0]?.role === 'sys-admin' && (  
                                        <div className="input-block">
                                            <label className="col-form-label">Select Organisation</label>
                                            <select
                                                className="form-select form-control"
                                                name="companyName"
                                                value={formData.companyName}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select an active organisation</option>
                                                {companies.map(company => (
                                                    <option>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="input-block">
                                            <label>Project Name <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="projectName"
                                                autoComplete="off"
                                                value={formData.projectName}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Start Date </label>
                                            <div className="cal-icon">
                                                <DatePicker
                                                    selected={selectedDate1}
                                                    onChange={handleDateChange1}
                                                    className="form-control datetimepicker"
                                                    type="date"
                                                    name="startDate"
                                                    autoComplete="off"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Deadline </label>
                                            <div className="cal-icon">
                                                <DatePicker
                                                    selected={selectedDate3}
                                                    onChange={handleDateChange3}
                                                    className="form-control datetimepicker"
                                                    type="date"
                                                    name="projectDeadline"
                                                    autoComplete="off"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Completion Date</label>
                                            <div className="cal-icon">
                                                <DatePicker
                                                    selected={selectedDate2}
                                                    onChange={handleDateChange2}
                                                    className="form-control datetimepicker"
                                                    type="date"
                                                    name="completionDate"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Priority <span className="text-danger">*</span></label>
                                            <select
                                                className="form-select form-control"
                                                name="priority"
                                                value={formData.priority}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                                required
                                            >
                                                <option value="">Select a priority</option>
                                                <option>High</option>
                                                <option>Medium</option>
                                                <option>Low</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="input-block">
                                            <label>Total Project Hours</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="projectTotalHours"
                                                autoComplete="off"
                                                value={formData.projectTotalHours}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Add Project Leader</label>
                                            {/* <select
                                                className="form-select form-control"
                                                name="projectLeader"
                                                value={formData.projectLeader}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                            >
                                                <option value="">Select an employee</option>
                                                {employees.map(employee => (
                                                    <option>
                                                        {employee.employeeName}
                                                    </option>
                                                ))}
                                            </select> */}
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="projectLeader"
                                                autoComplete="off"
                                                value = {formData.projectLeader}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label>Add Team</label>
                                            {/* <select
                                                className="form-select form-control"
                                                name="teamName"
                                                value={formData.teamName}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                            >
                                                <option value="">Select a team</option>
                                                {teams.map(team => (
                                                    <option>
                                                        {team.teamName}
                                                    </option>
                                                ))}
                                            </select> */}
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="teamName"
                                                autoComplete="off"
                                                value = {formData.teamName}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="input-block">
                                            <label>Add Employees and Hours to be worked</label>
                                            {employeeHours.map((employeeHour, index) => (
                                                <div key={index} className="row mb-3">
                                                    <div className={index > 0 ? "col-sm-5" : "col-sm-6"}>
                                                        {/* <select
                                                            className="form-select form-control"
                                                            name="employeeName"
                                                            value={employeeHour.employeeName}
                                                            onChange={(e) => handleEmployeeNameChange(e, index)}
                                                            autoComplete="off"
                                                            required
                                                        >
                                                            <option value="">Select an employee</option>
                                                            {employees.map(employee => (
                                                                <option>
                                                                    {employee.employeeName}
                                                                </option>
                                                            ))}
                                                        </select> */}
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="employeeName"
                                                            autoComplete="off"
                                                            value={employeeHour.employeeName}
                                                            onChange={(e) => handleEmployeeNameChange(e, index)}
                                                            required
                                                        />
                                                    </div>
                                                    <div className={index > 0 ? "col-sm-5" : "col-sm-6"}>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            placeholder="Hours Worked"
                                                            value={employeeHour.employeeWorkHours}
                                                            autoComplete="off"
                                                            onChange={(e) => handleHoursWorkedChange(e, index)}
                                                        />
                                                    </div>
                                                    {index > 0 && (
                                                        <div className="col-sm-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                style={{padding: "8px 22px"}}
                                                                onClick={() => removeEmployeeHour(index)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            <button type="button" className="btn btn-primary btn-add-row" onClick={addEmployeeHour}><i className="fa fa-plus"></i></button>
                                        </div>
                                    </div>
                                </div>


                                <div className="input-block">
                                    <label>Description</label>
                                    <DefaultEditor
                                        type="text"
                                        className="form-control"
                                        value={formData.projectDescription}
                                        onChange={handleInputChange}
                                        name="projectDescription"
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal"
                                            type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Create Project Modal */}

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default ProjectForm;
