import React, { useState, useEffect } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase.js";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { uploadOrganisationDocument } from "../../lib/astra.js";
import "react-phone-input-2/lib/style.css";
import "../../assets/css/register.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import logo2 from "../../assets/img/new-rhokit-logo.png";
import PhoneInput from "react-phone-input-2";
import {Link} from "react-router-dom";

const SoleProprietorDetails = () => {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState();
    const [formData, setFormData] = useState({
        fullName: "",
        nationalIDNumber: "",
        kraPinNumber: "",
    });

    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <div className="register">
            <div className="account-page">
                <div className="main-wrapper">
                    <div className="account-content">
                        <div className="container">
                            <div className="account-logo">
                                <img src={logo2} alt="Logo"/>
                            </div>

                            <div className="account-box">
                                <div className="account-wrapper">
                                    <h3 className="account-title">Register</h3>
                                    <p className="account-subtitle">Access to our dashboard</p>

                                    <div>
                                        <form noValidate>
                                            <h4>Sole Proprietor Details</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-block">
                                                        <label>
                                                            Full Name{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="fullName"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            value={formData.fullName}
                                                            onChange={handleInputChange2}
                                                            required
                                                            placeholder="Enter first name"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="input-block">
                                                        <label>
                                                            Valid Phone Number{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <PhoneInput
                                                            country={"ke"}
                                                            value={value}
                                                            onChange={setValue}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Valid National ID Number
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            name="nationalIDNumber"
                                                            autoComplete="off"
                                                            value={formData.nationalIDNumber}
                                                            onChange={handleInputChange2}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Valid KRA PIN Number
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="kraPinNumber"
                                                            autoComplete="off"
                                                            value={formData.kraPinNumber}
                                                            onChange={handleInputChange2}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Upload Front Side of your National ID{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            accept=".png,.jpeg"
                                                            required
                                                        />
                                                        <p className="text-primary text-xs">
                                                            Front of national identity
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Upload Back Side of your National ID{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            accept=".png,.jpeg"
                                                            required
                                                        />
                                                        <p className="text-primary text-xs">
                                                            Back of national identity
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Upload Your Passport Photo{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            accept=".png,.jpeg"
                                                            required
                                                        />
                                                        <p className="text-primary text-xs">
                                                            Passport photo
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Upload Valid KRA PIN Certificate
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            accept=".pdf"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Upload Proof of Address
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            accept=".pdf"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-block text-center">
                                                    <button
                                                        className="btn btn-primary"
                                                        type="submit"
                                                        disabled={loading}
                                                    >
                                                        {loading ? "Updating address..." : "Next Step"}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>

                                        <div className="account-footer">
                                            <p>
                                                Already have an account? <Link to="/">Login</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SoleProprietorDetails;
