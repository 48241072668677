import React, {useEffect, useState} from 'react';
import { db } from '../../firebase.js';
import {collection, addDoc, Timestamp, getDocs, query, where} from 'firebase/firestore';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Avatar_02} from "../../Entryfile/imagepath.js";
import PhoneInput from "react-phone-input-2";
import {countries} from "countries-list";
import Select from "react-select";
import { getAuth } from "firebase/auth";
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

const auth = getAuth();

const EmployeeForm = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        employeeNo: '',
        employeeName: '',
        nssfNo: '',
        nhifNo: '',
        nationalIdentityNumber: '',
        phone: '',
        dateOfBirth: '',
        gender: '',
        address: {
            city: '',
            country: '',
        },
        email: '',
        kraPinNo: '',
        maritalStatus: '',
        qualification: '',
        role: '',
        organisationId: 0,
    });

    const [selectedDate, setSelectedDate] = useState(null);
    const [emailError, setEmailError] = useState('');
    const [value, setValue] = useState();
    const [countryNames, setCountryNames] = useState([]);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const kraPinRegex = /^[A-Za-z][0-9]{9}[A-Za-z]$/;
    const [kraPinError, setKraPinError] = useState('');
    const idNoRegex = /^[0-9]{8}$/;
    const [idNoError, setIdNoError] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('KE'); 

    useEffect(() => {
        // Extract country names from countries object
        const countryNamesArray = Object.values(countries).map(country => country.name);
        // Set the countryNames state with the list of country names
        setCountryNames(countryNamesArray);
    }, []); // Empty dependency array ensures this effect runs only once after initial render

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Define a regular expression pattern to match invalid inputs
        const invalidPattern = /[^\w\s]/; // Matches any character that is not a word character or whitespace
    
        // Check if the field is the email field
        if (name === 'email') {
            // If it is the email field, update the state directly without further validation
            setFormData({
                ...formData,
                [name]: value,
            });
        } else if (name === 'companyName') {
            // If it's the companyName field, update the state with the selected companyName and its corresponding organisationId
            const selectedCompany = companies.find(company => company.companyName === value);
            if (selectedCompany) {
                setFormData({
                    ...formData,
                    [name]: value,
                    organisationId: selectedCompany.organisationId, // Update organisationId based on the selected company
                });
            }
        } else {
            // If it's not the email or companyName field, check for invalid characters
            // if (invalidPattern.test(value)) {
            //     // If it contains invalid characters, don't update the state and show an error message
            //     showToast('Invalid input: Special characters and symbols are not allowed', 'error');
            //     return;
            // }
    
            // If the value doesn't contain invalid characters, update the state
            setFormData({
                ...formData,
                [name]: name === 'dateOfBirth' ? value : value,
            });
        }
    };

    const handlePhoneChange = (value, country) => {
        setFormData({
            ...formData,
            phone: value,
        });
        setSelectedCountry(country.countryCode.toUpperCase()); // Update selected country
    };

    const isValidPhone = (phone) => {
        return isValidPhoneNumber(phone, selectedCountry); // Use selected country for validation
    };
    
    const handleInputChange1 = (e) => {
        const { name, value } = e.target;
        if (name === 'city' || name === 'country') {
            setFormData({
                ...formData,
                address: {
                    ...formData.address,
                    [name]: value
                }
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;

    //     // Define a regular expression pattern to match invalid inputs
    //     const invalidPattern = /[^\w\s]/; // Matches any character that is not a word character or whitespace

    //     // Check if the field is the email field
    //     if (name === 'email') {
    //         // If it is the email field, update the state directly without further validation
    //         setFormData({
    //             ...formData,
    //             [name]: value,
    //         });
    //     } else {
    //         // If it's not the email field, check for invalid characters
    //         if (invalidPattern.test(value)) {
    //             // If it contains invalid characters, don't update the state and show an error message
    //             showToast('Invalid input: Special characters and symbols are not allowed', 'error');
    //             return;
    //         }

    //         // If the value doesn't contain invalid characters, update the state
    //         setFormData({
    //             ...formData,
    //             [name]: name === 'dateOfBirth' ? value : value,
    //         });
    //     }
    // };

    const resetForm = () => {
        setFormData({
            employeeNo: '',
            employeeName: '',
            nssfNo: '',
            nhifNo: '',
            nationalIdentityNumber: '',
            phone: '',
            dateOfBirth: '',
            gender: '',
            address: {
                city: '',
                country: '',
            },
            email: '',
            kraPinNo: '',
            maritalStatus: '',
            qualification: '',
            role: '',
        });
        setSelectedDate(null);
    };

    const requiredFields =
        [
            // 'employeeNo',
            'employeeName',
            'nationalIdentityNumber',
            'phone',
            // 'dateOfBirth',
            'email',
            'kraPinNo',
            // 'qualification',
        ];

        useEffect(() => {
            const checkUserAuthentication = async () => {
                if (!auth.currentUser) {
                    console.error("User not found. Please login again.");
                    return;
                }

                const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    console.error("User data not found.");
                    return;
                }

                const userDataArray = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setLoading(false);
                setUserData(userDataArray);
                // console.log("User Data", userData);
            };

            // Check user authentication first
            const unsubscribe = auth.onAuthStateChanged(user => {
                if (user) {
                    // User is signed in
                    checkUserAuthentication();
                } else {
                    // No user is signed in
                    setLoading(false);
                    console.error("User not found. Please login again.");
                }
            });

            // Clean up subscription
            return () => unsubscribe();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(formData).every(value => !value);

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // Validate KRA PIN
        if (!kraPinRegex.test(formData.kraPinNo)) {
            setKraPinError('KRA PIN must be 11 alphanumeric characters.');
            showToast('Invalid KRA PIN!', 'error');
            return;
        } else {
            setKraPinError('');
        }

        // Validate email
        if (!emailRegex.test(formData.email)) {
            setEmailError('Email must be valid!');
            showToast('Invalid email!', 'error');
            return;
        } else {
            setEmailError('');
        }

        // Validate id
        if (!idNoRegex.test(formData.nationalIdentityNumber)) {
            setIdNoError('ID number must be valid!');
            showToast('Invalid ID number!', 'error');
            return;
        } else {
            setIdNoError('');
        }


        // If the form is not empty and KRA PIN is valid, proceed with other validations
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (requiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // Validate phone number
        // if (formData.phone.length > 15) {
        //     showToast('Phone number cannot exceed 15 characters!', 'error');
        //     return;
        // }

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        // Format date of birth
        let dateOfBirthString = null;
        if (selectedDate) {
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate.getDate()).padStart(2, '0');
            dateOfBirthString = `${year}${month}${day}`;
        }

        // console.log("org id", userData[0].organisationId)

        try {
            const companySnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', formData.email)));
            if (!companySnapshot.empty) {
                showToast('User with this email already exists!', 'error');
                return;
            }

            const companyDisplaySnapshot = await getDocs(query(collection(db, 'users'), where('nationalIdentityNumber', '==', formData.nationalIdentityNumber)));
            if (!companyDisplaySnapshot.empty) {
                showToast('User with this ID number already exists!', 'error');
                return;
            }

            const companyPINSnapshot = await getDocs(query(collection(db, 'users'), where('kraPinNo', '==', formData.kraPinNo)));
            if (!companyPINSnapshot.empty) {
                showToast('User with this PIN already exists!', 'error');
                return;
            }
            
            const customerId = Math.floor(Math.random() * 1000000);

            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'add-employee'), {
                    ...formData,
                    dateOfBirth: dateOfBirthString,
                    organisationId: userData[0].organisationId,
                    customerId
                });

                await addDoc(collection(db, 'users'), {
                    ...formData,
                    dateOfBirth: dateOfBirthString,
                    organisationId: userData[0].organisationId,
                    customerId
                })
                .then(async () => {
                    const requestBody = JSON.stringify({
                        email: formData.email,
                        name: formData.employeeName,
                        uid: docRef.id
                    });
                    
                    console.log('Request Body:', requestBody);

                    await fetch(`https://send-password-link-api-6w2fko6g2a-ey.a.run.app`,
                    {
                        method: "POST",
                        body: requestBody,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(() => {
                        console.log('Works!')
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                });

            } else {
                docRef = await addDoc(collection(db, 'add-employee'), {
                    ...formData,
                    dateOfBirth: dateOfBirthString,
                });

                await addDoc(collection(db, 'users'), {
                    ...formData,
                    dateOfBirth: dateOfBirthString,
                    customerId
                })
                .then(async () => {
                    const requestBody = JSON.stringify({
                        email: formData.email,
                        name: formData.employeeName,
                        uid: docRef.id
                    });

                    await fetch(`https://send-password-link-api-6w2fko6g2a-ey.a.run.app`,
                    {
                        method: "POST",
                        body: requestBody,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(() => {
                        console.log('Works!')
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                });
            }
            console.log("Document written with ID: ", docRef.id);
            showToast('Employee Added Successfully', 'success');
            resetForm();
        } catch (error) {
            console.error('Error adding document: ', error);
            showToast('An error occurred while creating the employee.', 'error');
        }
    };

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-company'));
            const companyList = [];
            querySnapshot.forEach((doc) => {
                const companyData = doc.data();
                if (!companyData.deleted && companyData.status === 'active') {
                    companyList.push({ id: doc.id, ...companyData });
                }
            });
            setCompanies(companyList);
        };
        fetchCompanies();
    }, []);

    return (
        <>
            <div id="add_employee"
                 className="modal custom-modal fade"
                 role="dialog"
                 data-bs-backdrop="static"
                 data-bs-keyboard="false"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Employee</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>


                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="row">
                                    {/* <div className="col-md-12">
                                        <div className="profile-img-wrap edit-img">
                                            <img
                                                className="inline-block"
                                                src={Avatar_02}
                                                alt="company"
                                            />
                                            <div className="fileupload btn">
                                                <span className="btn-text">upload your photo</span>
                                                <input className="upload" type="file" accept=".png"/>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12">
                                    {userData[0]?.role === 'sys-admin' && (  
                                        <div className="input-block">
                                            <label className="col-form-label">Select Organisation</label>
                                            <select
                                                className="form-select form-control"
                                                name="companyName"
                                                value={formData.companyName}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select an active organisation</option>
                                                {companies.map(company => (
                                                    <option>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Employee No
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="employeeNo"
                                                autoComplete="off"
                                                value={formData.employeeNo}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Employee Name <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="employeeName"
                                                autoComplete="off"
                                                value={formData.employeeName}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>


                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Email <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                name="email"
                                                autoComplete="off"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">Phone No <span
                                                className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="phone"
                                                autoComplete="off"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">Phone No <span className="text-danger">*</span></label>
                                            <PhoneInput
                                                country={'ke'}
                                                value={formData.phone}
                                                onChange={handlePhoneChange}
                                                inputClass="form-control"
                                                containerClass="phone-input-container"
                                                required
                                            />
                                            {formData.phone && !isValidPhone(formData.phone) && (
                                                <span className="text-danger">Invalid phone number</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">Gender</label>
                                            <select
                                                className="form-control form-select"
                                                name="gender"
                                                autoComplete="off"
                                                value={formData.gender}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">Date of Birth (mm/dd/yyyy)</label>
                                            <div className="cal-icon">
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={handleDateChange}
                                                    className="form-control datetimepicker"
                                                    type="date"
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                City
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="city"
                                                autoComplete="off"
                                                value={formData.address.city}
                                                onChange={handleInputChange1}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                        <label className="col-form-label">
                                            Country
                                        </label>
                                        <select
                                            className="form-select form-control"
                                            name="country"
                                            value={formData.address.country}
                                            onChange={handleInputChange1}
                                        >
                                            <option value="">Select Country</option>
                                            {countryNames.sort().map((country) => (
                                            <option key={country} value={country}>
                                                {country}
                                            </option>
                                            ))}
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                National ID <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="nationalIdentityNumber"
                                                autoComplete="off"
                                                value={formData.nationalIdentityNumber}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">KRA PIN No <span
                                                className="text-danger">*</span> </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="kraPinNo"
                                                autoComplete="off"
                                                value={formData.kraPinNo}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <p className="text-primary text-xs">
                                            Please ensure the KRA PIN format used is alphanumeric, consisting of 1 letter, followed by 9 numbers, and ending with 1 letter.
                                        </p>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">NSSF No</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="nssfNo"
                                                autoComplete="off"
                                                value={formData.nssfNo}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                NHIF No
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="nhifNo"
                                                autoComplete="off"
                                                value={formData.nhifNo}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block">
                                            <label className="col-form-label">Marital Status</label>
                                            <select
                                                className="form-select form-control"
                                                name="maritalStatus"
                                                value={formData.maritalStatus}
                                                onChange={handleInputChange}
                                            >
                                                <option value>Select status</option>
                                                <option value="S">Single</option>
                                                <option value="M">Married</option>
                                                <option value="D">Divorced</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Qualification
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="qualification"
                                                autoComplete="off"
                                                value={formData.qualification}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-block">
                                        <label className="col-form-label">
                                            Role
                                        </label>
                                        <select
                                            className="form-select form-control"
                                            autoComplete="off"
                                            name="role"
                                            value={formData.role}
                                            onChange={handleInputChange}
                                        >
                                            <option value>Select Role</option>
                                            {userData[0]?.role === 'sys-admin' && (  
                                                <option value="admin">Company Admin</option>
                                            )}
                                            <option value="hod">Department Head</option>
                                            <option value="employee">Employee</option>
                                            <option value="finance">Finance</option>
                                            <option value="hr">HR</option>
                                        </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Dependants
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="dependants"
                                                autoComplete="off"
                                                value={formData.dependants}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Next of Kin
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="nextOfKin"
                                                autoComplete="off"
                                                value={formData.nextOfKin}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-6">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Designation Name <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="designationName"
                                                autoComplete="off"
                                                value={formData.designationName}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-6">
                                        <div className="input-block">
                                            <label className="col-form-label">Select Department</label>
                                            <select
                                                className="form-select form-control"
                                                name="departmentName"
                                                value={formData.departmentName}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select a department</option>
                                                {departments.map(department => (
                                                    <option>
                                                        {department.departmentName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-12">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Membership
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="membership"
                                                autoComplete="off"
                                                value={formData.membership}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" type='submit'
                                            data-bs-dismiss="modal">Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    );
};

export default EmployeeForm;
