import React, {useContext, useState, useEffect} from "react";
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import { db } from '../../../../firebase.js';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { Link, useParams } from 'react-router-dom';
import {UserContext} from "../../../../InitialPage/App.js";
import {Applogo} from "../../../../Entryfile/imagepath.js";
import html2pdf from 'html2pdf.js';
import { format } from 'date-fns';
import numberToWords from 'number-to-words';
import {toast, ToastContainer, Slide} from "react-toastify";
import { getAuth } from "firebase/auth";

const auth = getAuth();

const GeneratedP9Form =  () => {
    const { userRole } = useContext(UserContext);

    const [userData, setUserData] = useState([]);
    const { ids } = useParams();
    const { year } = useParams();

    const selectedEmployeeIds = ids.split(',');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [employeeDataList, setEmployeeDataList] = useState([]);

    const [employeeData, setEmployeeData] = useState(null);
    const [qualification, setQualification] = useState(null);
    const [kraPinNo, setkraPinNo] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [netSalaryInWords, setNetSalaryInWords] = useState('');
    const [menu, setMenu] = useState(false);
    const [formattedMonth, setFormattedMonth] = useState(null);
    const [reports, setReports] = useState({});
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const employeeDataArray = [];

                if (userData[0]?.role && userData[0]?.role !== 'sys-admin'){
                    if (!userData || userData.length === 0 || !userData[0].organisationId) {
                        console.error("User data is not available or incomplete.", userData);
                        // setError("User data is not available or incomplete.");
                        setLoading(false);
                        return;
                    }

                    const organisationId = userData[0].organisationId;
                    console.log("Using organisationId:", organisationId);
                }
    
                if (selectedEmployeeIds.includes('all')) {
                    // Fetch all employee data from add-employee collection
                    const employeeQuerySnapshot = await getDocs(query(collection(db, 'add-employee'), where('organisationId', '==', organisationId)));
    
                    for (const doc of employeeQuerySnapshot.docs) {
                        const employeeData = doc.data();
                        employeeData.id = doc.id;
    
                        // Fetch salary data from add-salary collection based on employeeName
                        const salaryQuery = query(collection(db, 'add-salary'), where('employeeName', '==', employeeData.employeeName));
                        const salaryQuerySnapshot = await getDocs(salaryQuery);
    
                        if (!salaryQuerySnapshot.empty) {
                            const salaryData = salaryQuerySnapshot.docs[0].data();
                            employeeData.month = salaryData.month;
                            employeeData.basicSalary = salaryData.basicSalary;
                            employeeData.pensionCashPay = salaryData.pensionCashPay;
                            employeeData.permissibleLimit = salaryData.permissibleLimit;
                            employeeData.personalRelief = salaryData.personalRelief;
                            employeeData.insuranceRelief = salaryData.insuranceRelief;
                            employeeData.currency = salaryData.currency;
                            employeeData.totalGrossPay = salaryData.totalGrossPay;
                            employeeData.netPay = salaryData.netPay;
                            employeeData.payePayable = salaryData.payePayable;
                            employeeData.nssfContribution = salaryData.nssfContribution;
                            employeeData.nhifContribution = salaryData.nhifContribution;
                            employeeData.taxablePay = salaryData.taxablePay;
                            employeeData.taxPayable = salaryData.taxPayable;
                            employeeData.totalCashPay = salaryData.totalCashPay;
                            employeeData.totalNonCashPay = salaryData.totalNonCashPay;
                            employeeData.totalAmountOfBenefit = salaryData.totalAmountOfBenefit;
                            employeeDataArray.push(employeeData);
                        } else {
                            // console.warn(`Salary data not found for employeeName: ${employeeData.employeeName}`);
                        }
                    }
                } else {
                    // Fetch data for selected employee IDs from add-employee collection
                    const promises = selectedEmployeeIds.map(async id => {
                        try {
                            // Fetch employee data from add-employee collection
                            const employeeDocRef = doc(db, 'add-employee', id);
                            const employeeDocSnapshot = await getDoc(employeeDocRef);
    
                            if (employeeDocSnapshot.exists()) {
                                const employeeData = employeeDocSnapshot.data();
                                employeeData.id = id;
    
                                // Fetch salary data from add-salary collection based on employeeName
                                const salaryQuery = query(collection(db, 'add-salary'), where('employeeName', '==', employeeData.employeeName));
                                const salaryQuerySnapshot = await getDocs(salaryQuery);
    
                                if (!salaryQuerySnapshot.empty) {
                                    const salaryData = salaryQuerySnapshot.docs[0].data();
                                    employeeData.month = salaryData.month;
                                    employeeData.basicSalary = salaryData.basicSalary;
                                    employeeData.pensionCashPay = salaryData.pensionCashPay;
                                    employeeData.permissibleLimit = salaryData.permissibleLimit;
                                    employeeData.personalRelief = salaryData.personalRelief;
                                    employeeData.insuranceRelief = salaryData.insuranceRelief;
                                    employeeData.currency = salaryData.currency;
                                    employeeData.totalGrossPay = salaryData.totalGrossPay;
                                    employeeData.netPay = salaryData.netPay;
                                    employeeData.payePayable = salaryData.payePayable;
                                    employeeData.nssfContribution = salaryData.nssfContribution;
                                    employeeData.nhifContribution = salaryData.nhifContribution;
                                    employeeData.taxablePay = salaryData.taxablePay;
                                    employeeData.taxPayable = salaryData.taxPayable;
                                    employeeData.totalCashPay = salaryData.totalCashPay;
                                    employeeData.totalNonCashPay = salaryData.totalNonCashPay;
                                    employeeData.totalAmountOfBenefit = salaryData.totalAmountOfBenefit;
                                } else {
                                    console.error(`Salary data not found for employeeName: ${employeeData.employeeName}`);
                                }
                                // Fetch company data based on organisationId
                                const companyQuery = query(
                                    collection(db, 'add-company'),
                                    where('organisationId', '==', employeeData.organisationId)
                                );
                                const companyDocs = await getDocs(companyQuery);
                                const companyDataArray = companyDocs.docs.map(doc => doc.data());
    
                                if (companyDataArray.length > 0) {
                                    employeeData.companyData = companyDataArray;
                                }
    
                                return employeeData;
                            } else {
                                // Fetch salary data from add-salary collection based on ID
                                const salaryDocRef = doc(db, 'add-salary', id);
                                const salaryDocSnapshot = await getDoc(salaryDocRef);
    
                                if (salaryDocSnapshot.exists()) {
                                    const salaryData = salaryDocSnapshot.data();
                                    salaryData.id = id;
    
                                    // Fetch employee data from add-employee collection based on employeeName
                                    const employeeQuery = query(collection(db, 'add-employee'), where('employeeName', '==', salaryData.employeeName));
                                    const employeeQuerySnapshot = await getDocs(employeeQuery);
    
                                    if (!employeeQuerySnapshot.empty) {
                                        const employeeData = employeeQuerySnapshot.docs[0].data();
                                        salaryData.kraPinNo = employeeData.kraPinNo;
                                        salaryData.qualification = employeeData.qualification;
                                        salaryData.email = employeeData.email;
                                    } else {
                                        console.error(`Employee data not found for employeeName: ${salaryData.employeeName}`);
                                    }
    
                                    // Fetch company data based on organisationId
                                    const companyQuery = query(
                                        collection(db, 'add-company'),
                                        where('organisationId', '==', salaryData.organisationId)
                                    );
                                    const companyDocs = await getDocs(companyQuery);
                                    const companyDataArray = companyDocs.docs.map(doc => doc.data());
    
                                    if (companyDataArray.length > 0) {
                                        salaryData.companyData = companyDataArray;
                                    }
    
                                    return salaryData;
                                } else {
                                    throw new Error(`Employee data not found for ID: ${id}`);
                                }
                            }
                        } catch (error) {
                            console.error('Error fetching data:', error);
                            throw error;
                        }
                    });
    
                    const fetchedEmployeeDataArray = await Promise.all(promises);
                    employeeDataArray.push(...fetchedEmployeeDataArray);
                }
                setEmployeeDataList(employeeDataArray);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
    
        fetchData();
    }, [userData, loading, year, selectedEmployeeIds]);    


    const handlePDFExport = () => {
        const element = document.getElementById('p9-container');

        html2pdf()
            .from(element)
            .set({
                pagebreak: { mode: 'avoid-all' }, // Prevent page breaks within content
                filename: 'p9-form.pdf',
                margin: [0, 0],
                jsPDF: { format: 'letter', orientation: 'portrait' }, // Set PDF page size and orientation
                html2canvas: { scale: 3 } // Adjust scaling if needed
            })
            .save();
    };

    useEffect(() => {
        // Convert net salary to words when employeeDataList or any of its elements change
        if (employeeDataList.length > 0 && employeeDataList[0]?.netPay) {
            const netSalaryInWords = numberToWords.toWords(employeeDataList[0].netPay);
            setNetSalaryInWords(netSalaryInWords);
        }
    }, [employeeDataList]);

    // Function to handle Print button click
    const handlePrint = () => {
        const printContents = document.getElementById('p9-container').innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();

        document.body.innerHTML = originalContents;
    };

    const generateCSV = () => {
        const csvRows = [];

        // Header row
        const header = [
            'Employee Name',
            'Month',
            'Basic Salary (Kshs)',
            'Benefits Non Cash (Kshs)',
            'Total Gross Pay (Kshs)',
            'Defined Contribution Retirement Scheme (Kshs)',
            'Chargeable Pay (Kshs)',
            'Tax Charged (Kshs)',
            'Personal Relief (Kshs)',
            'Insurance Relief (Kshs)',
            'PAYE Tax (J-K) (Kshs)'
        ];
        csvRows.push(header.join(','));

        // Data rows
        employeeDataList.forEach(employeeData => {
            const row = [
                employeeData.employeeName,
                format(new Date(employeeData.month), 'MMMM'),
                employeeData.basicSalary || '',
                employeeData.totalNonCashPay || '',
                employeeData.totalGrossPay || '',
                employeeData.pensionCashPay || '',
                employeeData.taxablePay || '',
                employeeData.taxPayable || '',
                employeeData.personalRelief || '',
                employeeData.insuranceRelief || '',
                employeeData.payePayable || ''
            ];
            csvRows.push(row.join(','));
        });

        // Join rows with newline character
        const csvString = csvRows.join('\n');

        // Create a Blob object and initiate download
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'p9-form.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const formattedYear = format(selectedDate, 'yyyy');

    const handleEmailSend = () => { //(recipientEmail)
        const p9Container = document.getElementById('p9-container');
        
        if (!p9Container) {
            console.error('P9 container not found.');
            showToast('Error: P9 container not found.', 'error');
            return;
        }
    
        html2pdf()
            .from(p9Container)
            .toPdf()
            .get('pdf')
            .then(pdf => {
                const pdfData = pdf.output('blob');
    
                const reader = new FileReader();
                reader.readAsDataURL(pdfData);
                reader.onloadend = () => {
                    const pdfBase64 = reader.result;
    
                    window.Email.send({
                        Host: "smtp.elasticemail.com",
                        Username: "sealpayroll@gmail.com",
                        Password: "CE11E20264A7483D76F5BC7F1D9CDC36E00A",
                        To: 'jgitaridev@gmail.com', // recipientEmail
                        From: "sealpayroll@gmail.com",
                        Subject: "P9 form for " + format(new Date(), 'MMMM yyyy'),
                        Body: "Please find attached the P9 form for this month.",
                        Attachments: [
                            {
                                name: "p9-form.pdf",
                                data: pdfBase64
                            }
                        ]
                    }).then(
                        message => {
                            console.log(message);
                            showToast('P9 form sent successfully via email', 'success');
                        }
                    ).catch(
                        error => {
                            console.error('Error sending email:', error);
                            showToast('Error sending p9 form via email', 'error');
                        }
                    );
                };
            })
            .catch(error => {
                console.error('Error converting HTML to PDF:', error);
                showToast('Error converting HTML to PDF', 'error');
            });
    };
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">P9 Form</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item active">P9 Form</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <div className="btn-group btn-group-sm">
                                                {/* <button className="btn btn-white" onClick={handleEmailSend}><i className="fa fa-mail-bulk fa-lg"/> Email</button> */}
                                                <button className="btn btn-white" onClick={generateCSV}><i className="fa fa-file-csv fa-lg"/> CSV</button>
                                                {/* <button className="btn btn-white">CSV</button> */}
                                                <button className="btn btn-white" onClick={handlePDFExport}><i className="fa fa-file-pdf fa-lg"/> PDF</button>
                                                <button className="btn btn-white" onClick={handlePrint}><i
                                                    className="fa fa-print fa-lg"/> Print
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {error && <div>Error: {error}</div>}
                                {!error && (
                                <div className="row" id="p9-container">
                                    {employeeDataList.map((employeeData, index) => (
                                    <div key={index} className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="payslip-title">Tax Deduction Card for the Year {format(new Date(employeeData.month), 'yyyy')}</h4>
                                                <div className="row">
                                                    <div className="col-sm-6 m-b-20">
                                                        <img src={Applogo} className="inv-logo" alt=""/>
                                                        {employeeData.companyData && employeeData.companyData.map(company => (
                                                            <React.Fragment key={company.id}>
                                                            <p>Employer's Name: <strong>{company.companyName}</strong></p>
                                                            </React.Fragment>
                                                        ))}
                                                        <p>Employee's Name: <strong>{employeeData.employeeName}</strong></p>
                                                    </div>
                                                    <div className="col-sm-6 m-b-20">
                                                        <div className="invoice-details">
                                                            <h3 className="text-uppercase">P9 Form</h3>

                                                            <div className="p9-text">
                                                                {employeeData.companyData && employeeData.companyData.map(company => (
                                                                    <React.Fragment key={company.id}>
                                                                        <p>Employer's PIN: <strong>{company.pinNo || company.taxNumber}</strong></p>
                                                                    </React.Fragment>
                                                                ))}
                                                                <p> <strong>{employeeData.kraPinNo}</strong></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div>
                                                            <table className="table table-bordered" id="table-bordered">
                                                                <thead>
                                                                <tr className="text-center">
                                                                    <th>Month</th>
                                                                    <th>
                                                                        Basic <br/>
                                                                        Salary <br/>(Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Benefits <br/>
                                                                        Non <br/>
                                                                        Cash <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Total <br/>
                                                                        Gross <br/>
                                                                        Pay <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th colSpan={2}>
                                                                        Defined Contribution <br/>
                                                                        Retirement Scheme (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Chargeable <br/>
                                                                        Pay (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Tax <br/>
                                                                        Charged <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Personal <br/>
                                                                        Relief <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        Insurance <br/>
                                                                        Relief <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                    <th>
                                                                        PAYE <br/>
                                                                        Tax(J-K) <br/>
                                                                        (Kshs)
                                                                    </th>
                                                                </tr>
                                                                <tr className="text-center">
                                                                    <th></th>
                                                                    <th>A</th>
                                                                    <th>B</th>
                                                                    <th>C</th>
                                                                    <th colSpan={2}>D</th>
                                                                    <th>E</th>
                                                                    <th>F</th>
                                                                    <th>G</th>
                                                                    <th>H</th>
                                                                    <th>I</th>
                                                                </tr>
                                                                <tr className="text-center">
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th>E1 <br/>30% of Cash Pay</th>
                                                                    <th>E2 <br/> Permissible Limit</th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th></th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td className="table-month">{format(new Date(employeeData.month), 'MMMM')}</td>
                                                                    <td>{employeeData.basicSalary && new Intl.NumberFormat().format(employeeData.basicSalary)}</td>
                                                                    <td>{employeeData.totalNonCashPay && new Intl.NumberFormat().format(employeeData.totalNonCashPay)}</td>
                                                                    <td>{employeeData.totalGrossPay && new Intl.NumberFormat().format(employeeData.totalGrossPay)}</td>
                                                                    <td>{employeeData.pensionCashPay && new Intl.NumberFormat().format(employeeData.pensionCashPay)}</td>
                                                                    <td>{employeeData.permissibleLimit && new Intl.NumberFormat().format(employeeData.permissibleLimit)}</td>
                                                                    <td>{employeeData.taxablePay && new Intl.NumberFormat().format(employeeData.taxablePay)}</td>
                                                                    <td>{employeeData.taxPayable && new Intl.NumberFormat().format(employeeData.taxPayable)}</td>
                                                                    <td>{employeeData.personalRelief && new Intl.NumberFormat().format(employeeData.personalRelief)}</td>
                                                                    <td>{employeeData.insuranceRelief && new Intl.NumberFormat().format(employeeData.insuranceRelief)}</td>
                                                                    <td>{employeeData.payePayable && new Intl.NumberFormat().format(employeeData.payePayable)}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="table-bordered-footer">
                                                            <p>Total Chargeable Pay (COL H): <strong>{employeeData.currency} {employeeData.taxablePay && new Intl.NumberFormat().format(employeeData.taxablePay)}</strong></p>
                                                            <p>Total Tax (COL L): <strong>{employeeData.currency} {employeeData.taxPayable && new Intl.NumberFormat().format(employeeData.taxPayable)}</strong></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     ))}
                                </div>
                                 )}
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default GeneratedP9Form;
