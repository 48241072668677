 import React, { useState, useEffect } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "../../assets/css/register.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useNavigate } from "react-router-dom";
import logo2 from "../../assets/img/new-rhokit-logo.png";
import { db } from "../../firebase.js";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { uploadCustomerDocument } from "../../lib/astra.js";

export default function UploadDocuments() {
  const navigate = useNavigate();
  const storage = getStorage();
  const [selfieFile, setSelfieFile] = useState(null);
  const [base64EncodedSelfie, setBase64EncodedSelfie] = useState(null);
  const [idFrontFile, setIdFrontFile] = useState(null);
  const [base64EncodedIdFront, setBase64EncodedIdFront] = useState(null);
  const [idBackFile, setIdBackFile] = useState(null);
  const [base64EncodedIdBack, setBase64EncodedIdBack] = useState(null);
  const [kraCertificateFile, setKraCertificateFile] = useState(null);
  const [base64EncodedKraCertificate, setBase64EncodedKraCertificate] =
    useState(null);
  const [physicalAddress, setPhysicalAddress] = useState(null);
  const [base64EncodedPhysicalAddress, setBase64EncodedPhysicalAddress] =
    useState(null);
  const [uploadStatus, setUploadStatus] = useState({
    selfie: false,
    idFront: false,
    idBack: false,
    kraCertificate: false,
    physicalAddress: false,
  });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  let { userId } = useParams();

  const fetchUser = async () => {
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      setUser(userSnapshot.data());
    } else {
      toast.error("User not found");
      navigate("/register");
    }
  };

  useEffect(() => {
    let isMounted = true;

    fetchUser();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // check if all documents have been uploaded
    if (selfieFile === null || idFrontFile === null || idBackFile === null) {
      toast.error("Please upload all required documents");
      return;
    }

    let kraCertificateURL = null;
    let physicalAddressURL = null;

    try {
      setLoading(true);
      await uploadCustomerDocument(
        "FACIAL_PHOTO",
        user.customerId,
        base64EncodedSelfie,
        selfieFile.type
      );
      toast.success("Selfie uploaded successfully");
      setUploadStatus({ ...uploadStatus, selfie: true });

      // Upload FACIAL_PHOTO file to Firebase Storage
      const storageRef = ref(storage, `selfie/${user.customerId}`);
      await uploadBytes(storageRef, selfieFile);
      const selfieURL = await getDownloadURL(storageRef);

      await uploadCustomerDocument(
        "NATIONAL_IDENTITY",
        user.customerId,
        base64EncodedIdFront,
        idFrontFile.type
      );
      toast.success("Front of national identity uploaded successfully");
      setUploadStatus({ ...uploadStatus, idFront: true });

      // Upload NATIONAL_IDENTITY file to Firebase Storage
      const storageRef2 = ref(storage, `idFront/${user.customerId}`);
      await uploadBytes(storageRef2, idFrontFile);
      const idFrontURL = await getDownloadURL(storageRef2);

      await uploadCustomerDocument(
        "BACK_OF_NATIONAL_IDENTITY",
        user.customerId,
        base64EncodedIdBack,
        idBackFile.type
      );
      toast.success("Back of national identity uploaded successfully");
      setUploadStatus({ ...uploadStatus, idBack: true });

      // Upload BACK_OF_NATIONAL_IDENTITY file to Firebase Storage
      const storageRef3 = ref(storage, `idBack/${user.customerId}`);
      await uploadBytes(storageRef3, idBackFile);
      const idBackURL = await getDownloadURL(storageRef3);

      if (kraCertificateFile) {
        await uploadCustomerDocument(
          "TAX",
          user.customerId,
          base64EncodedKraCertificate,
          kraCertificateFile.type
        );
        toast.success("KRA certificate uploaded successfully");
        setUploadStatus({ ...uploadStatus, kraCertificate: true });

        // Upload TAX file to Firebase Storage
        const storageRef4 = ref(storage, `kraCertificate/${user.customerId}`);
        await uploadBytes(storageRef4, kraCertificateFile);
        kraCertificateURL = await getDownloadURL(storageRef4);
      }

      if (physicalAddress) {
        await uploadCustomerDocument(
          "PROOF_OF_ADDRESS",
          user.customerId,
          base64EncodedPhysicalAddress,
          physicalAddress.type
        );
        toast.success("Proof of address uploaded successfully");
        setUploadStatus({ ...uploadStatus, physicalAddress: true });

        // Upload PROOF_OF_ADDRESS file to Firebase Storage
        const storageRef5 = ref(storage, `physicalAddress/${user.customerId}`);
        await uploadBytes(storageRef5, physicalAddress);
        physicalAddressURL = await getDownloadURL(storageRef5);
      }

      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, {
        selfieURL,
        idFrontURL,
        idBackURL,
        kraCertificateURL,
        physicalAddressURL,
      });

      setLoading(false);
      toast.success("Documents uploaded successfully");
      navigate(`/register/${userId}/set-password`);
    } catch (error) {
      setLoading(false);
      toast.error(
        error.message +
          ". Please try again by selecting another file to proceed with the upload"
      );
    }
  };
  return (
    <div className="register">
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-logo">
                <img src={logo2} alt="Logo" />
              </div>

              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">Register</h3>
                  <p className="account-subtitle">Upload Documents</p>
                  <p>
                    Please upload the following documents to complete your KYC
                    and for you to be to move to the next step, all documents
                    should be uploaded successfully
                  </p>
                  <div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="input-block">
                            <label className="col-form-label">
                              Upload A selfie{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              accept=".jpeg,.png"
                              disabled={uploadStatus.selfie}
                              onChange={(event) => {
                                const file =
                                  event.currentTarget.files &&
                                  event.currentTarget.files[0];
                                if (file) {
                                  if (file.size / 1024 / 1024 > 1) {
                                    toast.error("File size too big (1MB max)");
                                  } else if (
                                    file.type !== "image/jpeg" 
                                    &&
                                    file.type !== "image/png"
                                  ) {
                                    toast.error(
                                      "File type not supported."
                                    );
                                  } else {
                                    setSelfieFile(file);
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                      setBase64EncodedSelfie(
                                        e.target?.result.split(",")[1]
                                      );
                                      console.log(e.target?.result);
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }
                              }}
                              required
                            />
                            <p className="text-primary text-xs">
                              A selfie with thumbs up or a finger. Eg{" "}
                              <a
                                href="https://uat-app.astraafrica.co:2012/assets/images/selfieImage-86c390845bd9914787eb1c49d5a46833.jpg"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Here
                              </a>
                            </p>
                            {uploadStatus.selfie && (
                              <p className="text-success text-xs">
                                Selfie uploaded successfully
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input-block">
                            <label className="col-form-label">
                              Upload Front Side of your National ID{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              accept=".jpeg,.png"
                              disabled={uploadStatus.idFront}
                              onChange={(event) => {
                                const file =
                                  event.currentTarget.files &&
                                  event.currentTarget.files[0];
                                if (file) {
                                  if (file.size / 1024 / 1024 > 1) {
                                    toast.error("File size too big (1MB max)");
                                  } else if (
                                    file.type !== "image/jpeg" 
                                    &&
                                    file.type !== "image/png"
                                  ) {
                                    toast.error(
                                      "File type not supported."
                                    );
                                  } else {
                                    setIdFrontFile(file);
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                      setBase64EncodedIdFront(
                                        e.target?.result.split(",")[1]
                                      );
                                      console.log(e.target?.result);
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }
                              }}
                              required
                            />
                            <p className="text-primary text-xs">
                              Front of national identity
                            </p>
                            {uploadStatus.idFront && (
                              <p className="text-success text-xs">
                                Front of national identity uploaded successfully
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input-block">
                            <label className="col-form-label">
                              Upload Back Side of your National ID{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              accept=".jpeg,.png"
                              disabled={uploadStatus.idBack}
                              onChange={(event) => {
                                const file =
                                  event.currentTarget.files &&
                                  event.currentTarget.files[0];
                                if (file) {
                                  if (file.size / 1024 / 1024 > 1) {
                                    toast.error("File size too big (1MB max)");
                                  } else if (
                                    file.type !== "image/jpeg" 
                                    &&
                                    file.type !== "image/png"
                                  ) {
                                    toast.error(
                                      "File type not supported."
                                    );
                                  } else {
                                    setIdBackFile(file);
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                      setBase64EncodedIdBack(
                                        e.target?.result.split(",")[1]
                                      );
                                      console.log(e.target?.result);
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }
                              }}
                              required
                            />
                            <p className="text-primary text-xs">
                              Back of national identity
                            </p>
                            {uploadStatus.idBack && (
                              <p className="text-success text-xs">
                                Back of national identity uploaded successfully
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input-block">
                            <label className="col-form-label">
                              Valid KRA PIN Certificate
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              accept=".pdf"
                              onChange={(event) => {
                                const file =
                                  event.currentTarget.files &&
                                  event.currentTarget.files[0];
                                if (file) {
                                  if (file.size / 1024 / 1024 > 10) {
                                    toast.error("File size too big (10MB max)");
                                  } else if (file.type !== "application/pdf") {
                                    toast.error(
                                      "File type not supported. Only PDF allowed"
                                    );
                                  } else {
                                    setKraCertificateFile(file);
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                      setBase64EncodedKraCertificate(
                                        e.target?.result.split(",")[1]
                                      );
                                      console.log(e.target?.result);
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }
                              }}
                              disabled={uploadStatus.kraCertificate}
                            />
                            <p className="text-primary text-xs">
                              Failure to upload this will limit your transactions and wallet balance
                            </p>
                            {uploadStatus.kraCertificate && (
                              <p className="text-success text-xs">
                                KRA certificate uploaded successfully
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="input-block">
                            <label className="col-form-label">
                              Physical Address/ Utility Bill
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              accept=".pdf"
                              onChange={(event) => {
                                const file =
                                  event.currentTarget.files &&
                                  event.currentTarget.files[0];
                                if (file) {
                                  if (file.size / 1024 / 1024 > 10) {
                                    toast.error("File size too big (10MB max)");
                                  } else if (file.type !== "application/pdf") {
                                    toast.error(
                                      "File type not supported. Only PDF allowed"
                                    );
                                  } else {
                                    setPhysicalAddress(file);
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                      setBase64EncodedPhysicalAddress(
                                        e.target?.result.split(",")[1]
                                      );
                                      console.log(e.target?.result);
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }
                              }}
                              disabled={uploadStatus.physicalAddress}
                            />
                            <p className="text-primary text-xs">
                              Document for proof of address E.g Utility bill <br />
                              Failure to upload this will limit your transactions and wallet balance
                            </p>
                            {uploadStatus.physicalAddress && (
                              <p className="text-success text-xs">
                                Proof of address uploaded successfully
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="input-block text-center">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "uploading..." : "Next Step"}
                          </button>
                        </div>
                      </div>
                    </form>

                    <div className="account-footer">
                      <p>
                        Already have an account? <Link to="/">Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </div>
  );
}
