import React, {useContext, useState, useEffect} from "react";
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import { db } from '../../../../firebase.js';
import {collection, getDocs, where, query} from 'firebase/firestore';
import {Link} from "react-router-dom";
import { UserContext } from "../../../../InitialPage/App.js";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const PreviousPayroll = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);

    const [loading, setLoading] = useState(true);

    const [menu, setMenu] = useState(false);
    const [data, setData] = useState([]);
    const [focused, setFocused] = useState(false);
    const [focused1, setFocused1] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    // const [filteredData, setFilteredData] = useState(data);
    const [filteredSearchData, setFilteredSearchData] = useState(data);
    const [entriesCount, setEntriesCount] = useState(0);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedMonth1, setSelectedMonth1] = useState('');

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleMonthChange1 = (e) => {
        setSelectedMonth1(e.target.value);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);

                const colRef = collection(db, "add-salary");
                // const querySnapshot = await getDocs(colRef);

                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }
                
                const dataArray = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setData(dataArray);
                setLoading(false);

            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, [userData, loading, searchInput]);

    const formattedMonthYear = format(selectedDate, 'MMMM yyyy');

    const filteredData = searchInput
        ? filteredSearchData.filter(item => {
            const selectedMonthYear = selectedDate.toISOString().slice(0, 7);
            return item.month === selectedMonthYear && !item.deleted && item.status === 'approved';
        })
        : data.filter(item => {
            const selectedMonthYear = selectedDate.toISOString().slice(0, 7);
            return item.month === selectedMonthYear && !item.deleted && item.status === 'approved';
        });

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            (item.employeeName && item.employeeName.toLowerCase().includes(input.toLowerCase())) ||
            (item.netPay && item.netPay.toLowerCase().includes(input.toLowerCase())) ||
            (item.grossSalary && item.grossSalary.toLowerCase().includes(input.toLowerCase()))
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredSearchData(filtered);
    };


    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Run Payroll</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="#">Payroll</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Run Payroll</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                     <div className="input-block">
                                            <label htmlFor="monthPicker">Select Month and Year:</label>
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                showMonthYearPicker
                                                dateFormat="yyyy-MM"
                                                className="form-control"
                                            />
                                        </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row mt-4">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                    <label>
                                                            Show
                                                            <select className="form-control form-control-sm">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_filter">
                                                        <label>
                                                            Search:
                                                            <input
                                                                className="form-control form-control-sm"
                                                                onFocus={() => setFocused(true)}
                                                                onBlur={() => setFocused(false)}
                                                                value={searchInput}
                                                                onChange={handleSearchInputChange}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {filteredData.length > 0 && (
                                        <div className="row mt-4">
                                             <h4 className="table-header">Payroll for {formattedMonthYear}</h4>
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th>Month/Year</th>
                                                        <th>Employee</th>
                                                        <th>Currency</th>
                                                        <th>Gross</th>
                                                        <th>PAYE</th>
                                                        <th>NSSF</th>
                                                        <th>NHIF</th>
                                                        <th>Deduction</th>
                                                        <th>Net</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {filteredData.map(item => (
                                                    <tr key={item.id}>
                                                        <td>{formattedMonthYear}</td>
                                                        <td>{item.employeeName}</td>
                                                        <td>{item.currency}</td>
                                                        <td>{item.totalGrossPay && new Intl.NumberFormat().format(item.totalGrossPay) || "N/A"}</td>
                                                        <td>{item.payePayable && new Intl.NumberFormat().format(item.payePayable) || "N/A"}</td>
                                                        <td>{item.nssfContribution && new Intl.NumberFormat().format(item.nssfContribution) || "N/A"}</td>
                                                        <td>{item.nhifContribution && new Intl.NumberFormat().format(item.nhifContribution) || "N/A"}</td>
                                                        {/* <td>{item.pensionContributions && new Intl.NumberFormat().format(item.pensionContributions)}</td> */}
                                                        <td>{item.totalDeductions && new Intl.NumberFormat().format(item.totalDeductions) || "N/A"}</td>
                                                        <td>{item.netPay && new Intl.NumberFormat().format(item.netPay)}</td>
                                                    </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreviousPayroll;
