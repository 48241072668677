import React, { useState } from 'react';
import logo2 from '../assets/img/new-rhokit-logo.png'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { app } from '../firebase.js';
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ResetPassword(){
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
      };


      const handleSubmit = (e) => {
        e.preventDefault();

        if (!email) {
          setEmailError('Email is required');
          return;
        }
        const auth = getAuth(app);
        return sendPasswordResetEmail(auth, email)
        .then(() => {
            toast.success("An email has been sent!");
            setEmail(''); 
            // return `An email has been sent to ${email}`;
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            toast.error(errorCode, errorMessage);
            throw error;
        });

      };

    return(
        <>
        <div className='login'>
            <div className='account-page'>
                <div className='main-wrapper'>
                    <div className='account-content'>
                        <div className='container'>
                            <div className='account-logo'>
                                <img src={logo2} alt="Logo" />
                            </div>

                            <div className='account-box'>
                                <div className='account-wrapper'>
                                    <h3 className='account-title'>Forgot Password?</h3>
                                    <p className='account-subtitle'>Enter your email to get a password reset link</p>

                                    <div>
                                        <form onSubmit={handleSubmit}>
                                            <div className='input-block'>
                                                <label>Email Address</label>
                                                <input type='text' name='email' autocomplete="off" className={`form-control ${emailError && 'is-invalid'}`} value={email} onChange={handleEmailChange} ></input>
                                                <div className="text-danger">{emailError}</div>
                                            </div>

                                            <div className='input-block text-center'>
                                                <button className='btn btn-primary account-btn' type='submit'>Reset Password</button>
                                            </div>
                                        </form>

                                        <div className='account-footer'>
                                            <p>Remembered your password? <a href='/' id="a2">Login</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </div>
        
        </>

    );
}

export default ResetPassword;
