import React, { useState, useEffect } from "react";
import { db } from '../../../firebase.js';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import DatePicker from "react-datepicker";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth } from "firebase/auth";

const auth = getAuth();

const SubmittedTimeSheetForm = () => {
    const [formData, setFormData] = useState({
        employeeName: '',
        projectName: '',
        currentDate: '',
        projectTotalHours: '',
        hoursWorked: '',
        projectRemainingHours: '',
        projectDeadline: '',
        timesheetDescription:'',
        organisationId: 0,
    });

    const [selectedDate1, setSelectedDate1] = useState(null);
    const currentDate = new Date();
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [selectedDate2, setSelectedDate2] = useState(null);

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const handleDateChange1 = (currentDate) => {
        setSelectedDate1(currentDate);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];

            querySnapshot.forEach((doc) => {
                const employeeData = doc.data();

                // Check if the employee is active and not deleted
                if (employeeData.status === 'active' && 
                    !employeeData.deleted &&
                    employeeData.organisationId === userData[0]?.organisationId
                ) {
                    employeeList.push({ id: doc.id, ...employeeData });
                }
            });

            setEmployees(employeeList);
        };
        fetchEmployees();
    }, [userData]);

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            const querySnapshot = await getDocs(collection(db, 'projects'));
            const projectList = [];
            // querySnapshot.forEach((doc) => {
            //     projectList.push({ id: doc.id, ...doc.data() });
            // });
            querySnapshot.forEach((doc) => {
                const projectData = doc.data();

                // Check if the employee is active and not deleted
                if (!projectData.deleted &&
                    projectData.organisationId === userData[0]?.organisationId
                ) {
                    projectList.push({ id: doc.id, ...projectData });
                }
            });
            setProjects(projectList);
        };
        fetchProjects();
    }, [userData]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'projectName') {
            const selectedProject = projects.find(project => project.projectName === value);

            setFormData({
                ...formData,
                [name]: value,
                projectTotalHours: selectedProject ? selectedProject.projectTotalHours : '',
                projectDeadline: selectedProject ? selectedProject.projectDeadline : '',
                projectRemainingHours: calculateRemainingHours(selectedProject ? selectedProject.projectTotalHours : '', formData.hoursWorked),
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const calculateRemainingHours = () => {
        const totalHours = parseFloat(formData.projectTotalHours) || 0;
        const hoursWorked = parseFloat(formData.hoursWorked) || 0;
        const remainingHours = totalHours - hoursWorked;
        return remainingHours;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.keys(formData)
            .filter(key => !['currentDate', 'projectTotalHours', 'projectRemainingHours', 'projectDeadline'].includes(key)) // Exclude currentDate and projectTotalHours from validation
            .every(key => !formData[key]);

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // Check each editable field for empty value
        const errorMessages = [];
        Object.keys(formData)
            .filter(key => !['currentDate', 'projectTotalHours', 'projectRemainingHours', 'projectDeadline'].includes(key)) // Exclude currentDate and projectTotalHours from validation
            .forEach((key) => {
                if (!formData[key]) {
                    errorMessages.push(`${key} cannot be empty`);
                }
            });

        // If there are errors, display them as a toast
        if (errorMessages.length > 0) {
            const errorMessage = errorMessages.join('\n');
            showToast(errorMessage, 'error');
            return;
        }

        let currentDateString = null;

        if (currentDate) {
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            currentDateString = `${year}-${month}-${day}`;
        }

        const totalHours = parseFloat(formData.projectTotalHours) || 0;
        const hoursWorked = parseFloat(formData.hoursWorked) || 0;
        const remainingHours = totalHours - hoursWorked;

        try {
            const docRef = await addDoc(collection(db, 'timesheets'), {
                ...formData,
                currentDate: currentDateString,
                projectRemainingHours: remainingHours,
                organisationId: userData[0].organisationId,
            });
            console.log("Document written with ID: ", docRef.id);
            //alert('Form submitted successfully!');
            showToast('Timesheet Added Successfully', 'success');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    return (
        <>
            {/* Add Today's Work Modal */}
            <div
                id="add_todaywork"
                className="modal custom-modal fade"
                role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Today's Work details</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="row">
                                    <div className="input-block col-sm-12">
                                        <label>
                                            Employee <span className="text-danger">*</span>
                                        </label>
                                        {/* <select
                                            className="form-select form-control"
                                            name="employeeName"
                                            value = {formData.employeeName}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Select an employee</option>
                                                {employees.map(employee => (
                                                    <option>
                                                        {employee.employeeName}
                                                    </option>
                                                ))}
                                        </select> */}
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="employeeName"
                                            autoComplete="off"
                                            value = {formData.employeeName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-block col-sm-12">
                                        <label>
                                            Project <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            className="form-select form-control"
                                            name="projectName"
                                            value = {formData.projectName}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Select a project</option>
                                            {projects.map(project => (
                                                <option  key={project.id} value={project.projectName}>
                                                    {project.projectName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-block col-sm-4">
                                        <label>
                                            Current Date <span className="text-danger">*</span>
                                        </label>
                                        <div className="cal-icon">
                                                <DatePicker
                                                    selected={currentDate}
                                                    onChange={handleDateChange1}
                                                    className="form-control datetimepicker"
                                                    type="date"
                                                    readOnly
                                                />
                                        </div>
                                    </div>
                                    <div className="input-block col-sm-4">
                                        <label>
                                            Total Project Hours <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="projectTotalHours"
                                            value={formData.projectTotalHours}
                                            onChange={handleInputChange}
                                            readOnly
                                        />
                                    </div>
                                    <div className="input-block col-sm-4">
                                        <label>
                                            Hours Worked <span className="text-danger">*</span>
                                        </label>
                                        <input
                                                className="form-control"
                                                type="number"
                                                name="hoursWorked"
                                                autoComplete="off"
                                                value={formData.hoursWorked}
                                                onChange={handleInputChange}
                                                required
                                         />
                                    </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-block col-sm-6">
                                            <label>
                                                Remaining Hours <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="projectRemainingHours"
                                                value={calculateRemainingHours()}
                                                readOnly
                                            />
                                        </div>
                                        <div className="input-block col-sm-6">
                                            <label>
                                                Project Deadline <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name = "projectDeadline"
                                                value={formData.projectDeadline}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                <div className="input-block">
                                    <label>
                                        Description <span className="text-danger">*</span>
                                    </label>
                                    <textarea
                                        rows={4}
                                        className="form-control"
                                        type="text"
                                        value={formData.timesheetDescription}
                                        onChange={handleInputChange}
                                        name="timesheetDescription"
                                    />
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Today Work Modal */}

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default SubmittedTimeSheetForm;
