import React, { useState, useEffect } from "react";
import { db } from "../../firebase.js";
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import { addPositionHolder } from "../../lib/astra.js";
import logo2 from "../../assets/img/new-rhokit-logo.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "../../assets/css/register.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Slide } from "react-toastify";

const PositionHolder = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState();
    const [user, setUser] = useState({});
    const [businessType, setBusinessType] = useState("");
    let { userId } = useParams();
    const [formData, setFormData] = useState({
        customerName: "",
        customerId: "",
        customerPosition: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const position = [
        "OWNER",
        "EMPLOYEE",
        "DIRECTOR",
        "PRIMARY_CONTACT",
        "HEAD",
        "MANAGER",
        "MEMBER",
        "PARTNER",
    ];

    const [lastName, setLastName] = useState('');
    const [usersWithLastName, setUsersWithLastName] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedFullName, setSelectedFullName] = useState('');

    const fetchUser = async (userId) => {
        try {
            const userRef = doc(db, "users", userId);
            const userSnapshot = await getDoc(userRef);
            if (userSnapshot.exists()) {
                setUser(userSnapshot.data());
                setLastName(userSnapshot.data().lastName);
                setBusinessType(userSnapshot.data().businessType);
            } else {
                toast.error("User not found");
                navigate("/orgregister");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    // console.log("Last name", lastName);

    const searchUsersByLastName = async (searchLastName) => {
        try {
            const q = query(collection(db, "users"), where("lastName", "==", searchLastName));
            const snapshot = await getDocs(q);
            const users = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUsersWithLastName(users);
        } catch (error) {
            console.error("Error searching users:", error);
        }
    };

    const handleLastNameChange = (e) => {
        const { value } = e.target;
        setLastName(value);
        if (value.trim() !== "") {
            searchUsersByLastName(value.trim());
        } else {
            setUsersWithLastName([]);
        }
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
        setFormData({
            customerName: `${user.firstName} ${user.lastName}`,
            customerId: user.customerId,
        });
        setUsersWithLastName([]);
        setLastName(`${user.firstName} ${user.lastName}`);
    };

    useEffect(() => {
        let isMounted = true;
        fetchUser(userId);
        return () => {
            isMounted = false;
        };
    }, [userId]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!e.target.checkValidity()) {
          toast.error("Please fill in all required fields");
          return;
        }
        
        const userRef = doc(db, "users", userId);

        try {
          setLoading(true);
          addPositionHolder(
            user.organisationId,
            formData.customerId,
            formData.customerPosition
          )
            .then(async (data) => {
              updateDoc(userRef, {
                position: data,
              });

              setLoading(false);
              toast.success("Position added successfully");

              // Redirect to the next step
              navigate(`/orgregister/${userRef.id}/set-org-password`);
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.message);
                // navigate(`/orgregister/${userRef.id}/verify-kyb`);
            });
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
            // navigate(`/orgregister/${userRef.id}/verify-kyb`);
        }
      };
    // console.log("Users with Last name", usersWithLastName);
    return (
        <div className="register">
            <div className="account-page">
                <div className="main-wrapper">
                    <div className="account-content">
                        <div className="container">
                            <div className="account-logo">
                                <img src={logo2} alt="Logo"/>
                            </div>

                            <div className="account-box">
                                <div className="account-wrapper">
                                    <h3 className="account-title">Register</h3>
                                    <p className="account-subtitle">Access to our dashboard</p>

                                    <div>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label>
                                                            Select Customer Name{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            value={lastName}
                                                            onChange={handleLastNameChange}
                                                            placeholder="Search by last name"
                                                            required
                                                        />
                                                        {usersWithLastName.length > 0 && (
                                                            <div className="dropdown-content">
                                                                <ul>
                                                                    {usersWithLastName.map((user) => (
                                                                        <li key={user.id}>
                                                                            <button
                                                                                className="btn btn-primary col-md-12 mt-1"
                                                                                type="button"
                                                                                onClick={() => handleUserSelect(user)}
                                                                            >
                                                                                {user.firstName} {user.lastName}
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label>Position <span
                                                            className="text-danger">*</span>
                                                        </label>
                                                        <select
                                                            className="form-select form-control"
                                                            name="customerPosition"
                                                            value={formData.customerPosition}
                                                            onChange={handleInputChange}
                                                            required
                                                        >
                                                            <option value>Select Position</option>
                                                            {businessType === "LIMITED_COMPANY"
                                                                ? <option value="PRIMARY_CONTACT">PRIMARY_CONTACT</option>
                                                                : position.filter(pos => pos !== "PRIMARY_CONTACT").map((pos) => (
                                                                    <option key={pos} value={pos}>
                                                                        {pos}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="input-block text-center">
                                                    <button
                                                        className="btn btn-primary"
                                                        type="submit"
                                                        disabled={loading}
                                                    >
                                                        {loading ? "Updating details..." : "Next Step"}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="account-footer">
                                            <p>
                                                Already have an account? <Link to="/">Login</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PositionHolder;
