import React, {useContext, useState, useEffect} from "react";
import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import {UserContext} from "../../../InitialPage/App";
import {Link} from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { db } from "../../../firebase.js";
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';

import { getAuth } from "firebase/auth";

const auth = getAuth();

const ProjectSchedule = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [menu, setMenu] = useState(false);
    const [projectDates, setProjectDates] = useState([]);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const setshowEvents = false;
    const setshow = false;
    const [showmodel, setshowmodel] = useState(false);
    const [isEditDelete, setiseditdelete] = useState(false);

    const [setevent_title] = useState("");
    const [calenderevent, setcalenderevent] = useState("");

    const [weekendsVisible] = useState(true);

    const handleClose = () => {
        setisnewevent(false);
        setiseditdelete(false);
        setshow(false);
        setshowEvents(false);
        setshowmodel(false);
    };
    const handleEventClick = (clickInfo) => {
        setiseditdelete(false);
        setevent_title(clickInfo.event.title);

        setcalenderevent(clickInfo.event);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchProjectDates = async () => {
            try {
                // const colRef = collection(db, 'projects');
                // const querySnapshot = await getDocs(colRef);
                const colRef = collection(db, 'projects');

                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProjectDates(dataArray);
            } catch (error) {
                console.error('Error fetching leave dates:', error);
            }
        };

        fetchProjectDates();
    }, [userData, loading]);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Timesheets</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hod/timesheets/">Timesheets</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Project Schedule</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card bg-white calendarIndex">
                                            <div className="card-body">
                                                <FullCalendar
                                                    plugins={[
                                                        dayGridPlugin,
                                                        timeGridPlugin,
                                                        interactionPlugin,
                                                    ]}
                                                    headerToolbar={{
                                                        left: "prev,next today",
                                                        center: "title",
                                                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                                                    }}
                                                    initialView="dayGridMonth"
                                                    editable={true}
                                                    selectable={true}
                                                    selectMirror={true}
                                                    dayMaxEvents={true}
                                                    weekends={true}
                                                    // events={projectDates.map(event => ({
                                                    //     title: (event.projectName),
                                                    //     start: new Date(event.startDate).toISOString(), // Parse startDate to Date object
                                                    //     end: new Date(event.projectDeadline).toISOString(), 
                                                    //     allDay: true,
                                                    //     backgroundColor: '#00AAB9',
                                                    // }))}
                                                    events={projectDates.map(event => {
                                                        // Ensure startDate and projectDeadline exist and are valid dates
                                                        const startDate = event.startDate ? new Date(event.startDate) : null;
                                                        const endDate = event.projectDeadline ? new Date(event.projectDeadline) : null;
                                                    
                                                        return {
                                                            title: event.projectName || "Unnamed Project",
                                                            start: startDate instanceof Date && !isNaN(startDate) ? startDate.toISOString() : null,
                                                            end: endDate instanceof Date && !isNaN(endDate) ? endDate.toISOString() : null,
                                                            allDay: true,
                                                            backgroundColor: '#00AAB9',
                                                        };
                                                    }).filter(event => event.start)}  // Filter out events with invalid dates                                                    
                                                    eventContent={({event}) => (
                                                        <div>
                                                            {event.title}
                                                        </div>
                                                    )}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectSchedule;