import React, {useState, useEffect} from "react";
import logo2 from "../../../assets/img/new-rhokit-logo.png";
import DatePicker from "react-datepicker";
import {addDoc, collection, getDocs, query, where} from "firebase/firestore";
import {db, app} from "../../../firebase";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {Slide, toast, ToastContainer} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { countries } from "countries-list";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import {Link} from "react-router-dom";
import Select from "react-select";

const MAX_FILE_NAME_LENGTH = 1048487; 
const MAX_FILE_SIZE = 2 * 1024 * 1024; 

const CompanyRegister = () => {
    const [loading, setLoading] = useState(false);
    const auth = getAuth(app);
    const navigate = useNavigate();
    const [countryNames, setCountryNames] = useState([]);

    // Define the regex pattern for the text input
    // const textInputRegex = /^([a-zA-Z]+\s[a-zA-Z']{0,2}[a-zA-Z]+(\s[a-zA-Z']{0,2}[a-zA-Z]+)?)/;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [emailError, setEmailError] = useState('');
    const kraPinRegex = /^[A-Za-z][0-9]{9}[A-Za-z]$/;
    const [kraPinError, setKraPinError] = useState('');

    const initialFormData = {
        // logoURL: '',
        companyName: '',
        tradingName: '',
        companyNumber: '',
        businessType: '',
        phone: '',
        email: '',
        businessRegistrationDate: '',
        taxNumber: '',
        // nhifNo: '',
        // nssfNo: '',
        // physicalAddress: '',
        // postCode: '',
        address: {
            city: '',
            country: '',
        },
        type: "BUSINESS",
        role: "admin",
        modules: [],
    };

    useEffect(() => {
        // Extract country names from countries object
        const countryNamesArray = Object.values(countries).map(
          (country) => country.name
        );
        // Set the countryNames state with the list of country names
        setCountryNames(countryNamesArray);
    }, []);

    const [formData, setFormData] = useState(initialFormData);
    const [selectedDate, setSelectedDate] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const storage = getStorage();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [repeatPasswordError, setRepeatPasswordError] = useState("");
    const [selectedCountry, setSelectedCountry] = useState('KE'); 

    const handlePhoneChange = (value, country) => {
        setFormData({
            ...formData,
            phone: value,
        });
        setSelectedCountry(country.countryCode.toUpperCase()); // Update selected country
    };

    const isValidPhone = (phone) => {
        return isValidPhoneNumber(phone, selectedCountry); // Use selected country for validation
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const [uploadStatus, setUploadStatus] = useState({
        logo: false,
      });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const invalidPattern = /[^\w\s]/; // Matches any character that is not a word character or whitespace

        if (name === 'email') {
            // If it is the email field, update the state directly without further validation
            setFormData({
                ...formData,
                [name]: value,
            });
        } else {
            // If it's not the email field, check for invalid characters
            // if (invalidPattern.test(value)) {
            //     // If it contains invalid characters, don't update the state and show an error message
            //     showToast('Invalid input: Special characters and symbols are not allowed', 'error');
            //     return;
            // }

            // If the value doesn't contain invalid characters, update the state
            setFormData({
                ...formData,
                [name]: name === 'businessRegistrationDate' ? value : value,
            });
        }
    };

    const handleInputChange1 = (e) => {
        const { name, value } = e.target;
        if (name === 'city' || name === 'country') {
            setFormData({
                ...formData,
                address: {
                    ...formData.address,
                    [name]: value
                }
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];

        setLogoFile(file);

        // Read the image file and convert it to a URL
        const reader = new FileReader();
        reader.onload = () => {
            // Update the formData with the new selfieURL
            setFormData(prevData => ({
                ...prevData,
                logoURL: reader.result
            }));
        };
        reader.readAsDataURL(file);
    };


    const requiredFields =
        [
            'companyName',
            // 'tradingName',
            'companyNumber',
            'businessType',
            'email',
            'phone',
            'date',
            // 'taxNumber',
            'city',
            'country',
        ];

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isFormEmpty = Object.entries(formData).every(([key, value]) => {
            // Check if the field is an object (nested property)
            if (typeof value === 'object' && value !== null) {
                // Check if nested object properties are empty
                return Object.values(value).every(nestedValue => nestedValue === '');
            } else {
                // Check if other types of values are empty
                return !value;
            }
        });

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // Validate KRA PIN
        if (!kraPinRegex.test(formData.taxNumber)) {
            setKraPinError('PIN must be 11 alphanumeric characters.');
            showToast('Invalid PIN!', 'error');
            return;
        } else {
            setKraPinError('');
        }

        // Validate phone number
        if (formData.phone.length > 15) {
            showToast('Phone number cannot exceed 15 characters!', 'error');
            return;
        }

        // Validate phone number
        if (formData.phone.length < 10) {
            showToast('Phone number cannot be less than 10 characters!', 'error');
            return;
        }

        // Validate email
        if (!emailRegex.test(formData.email)) {
            setEmailError('Email must be valid!');
            showToast('Invalid email!', 'error');
            return;
        } else {
            setEmailError('');
        }

        // Check if the country field is empty
        if (!formData.address.country) {
            showToast('Country cannot be empty!', 'error');
            return;
        }

        // Check if the city field is empty
        if (!formData.address.city) {
            showToast('City cannot be empty!', 'error');
            return;
        }

        // Check if the file input has a file uploaded
        // if (!logoFile) {
        //     showToast('Please upload your company logo!', 'error');
        //     return;
        // }

        // If the form is not empty, check each required field for empty value
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (requiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        let businessRegString = null;
        if (selectedDate) {
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate.getDate()).padStart(2, '0');
            businessRegString = `${year}${month}${day}`;
        }
        try {
            setLoading(true);
            const organisationId = Math.floor(Math.random() * 1000000);

            // Check if the legal name already exists in the database
            const companySnapshot = await getDocs(query(collection(db, 'add-company'), where('companyName', '==', formData.companyName)));
            if (!companySnapshot.empty) {
                showToast('Company with this legal name already exists!', 'error');
                return;
            }

            const companyPINSnapshot = await getDocs(query(collection(db, 'add-company'), where('taxNumber', '==', formData.taxNumber)));
            if (!companyPINSnapshot.empty) {
                showToast('Company with this PIN already exists!', 'error');
                return;
            }

            const companyEmailSnapshot = await getDocs(query(collection(db, 'add-company'), where('email', '==', formData.email)));
            if (!companyEmailSnapshot.empty) {
                showToast('Company with this email already exists!', 'error');
                return;
            }

            const updatedFormData = {
                ...formData,
                tradingName: formData.companyName,
                businessRegistrationDate: businessRegString,
                organisationId
            };

            setLoading(true);
            // Add the company data to Firestore
            const docRef = await addDoc(collection(db, 'add-company'), updatedFormData);
            console.log("Document written with ID: ", docRef.id);

            await addDoc(collection(db, 'users'), {
                ...updatedFormData,
            })
            .then(async () => {
                const requestBody = JSON.stringify({
                    email: formData.email,
                    name: formData.companyName,
                    uid: docRef.id
                });
                
                console.log('Request Body:', requestBody);

                await fetch(`https://send-password-link-api-6w2fko6g2a-ey.a.run.app`,
                {
                    method: "POST",
                    body: requestBody,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(() => {
                    console.log('Works!')
                })
                .catch((err) => {
                    console.log(err)
                })
            });
            // alert('Form submitted successfully!');
            showToast('Verification Email sent successfully', 'success');

            setLoading(false);
            setFormData(initialFormData);
            setLogoFile(null);
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    return (
        <>
            <div className="register">
                <div className="account-page">
                    <div className="main-wrapper">
                        <div className="account-content">
                            <div className="container">
                                <div className="account-logo">
                                    <img src={logo2} alt="Logo"/>
                                </div>

                                <div className="account-box">
                                    <div className="account-wrapper">
                                        <h3 className="account-title">Register</h3>
                                        <p className="account-subtitle">Access to our dashboard</p>

                                        <div>
                                            <form id="companyForm" onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Name <span
                                                                className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="companyName"
                                                                autoComplete="off"
                                                                value={formData.companyName}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Company Number <span
                                                                className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="companyNumber"
                                                                autoComplete="off"
                                                                value={formData.companyNumber}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <label>Business Type <span
                                                                className="text-danger">*</span></label>
                                                             <select
                                                                className="form-control form-select"
                                                                name="businessType"
                                                                autoComplete="off"
                                                                value={formData.businessType}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option value="">--</option>
                                                                <option value="SOLE_PROPRIETOR">Sole Proprietor</option>
                                                                <option value="LIMITED_COMPANY">Limited Company</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Email <span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="email"
                                                                name="email"
                                                                autoComplete="off"
                                                                value={formData.email}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Phone No <span className="text-danger">*</span></label>
                                                            <PhoneInput
                                                                country={'ke'}
                                                                value={formData.phone}
                                                                onChange={handlePhoneChange}
                                                                inputClass="form-control"
                                                                containerClass="phone-input-container"
                                                                required
                                                            />
                                                            {formData.phone && !isValidPhone(formData.phone) && (
                                                                <span className="text-danger">Invalid phone number</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label className="col-form-label">Registration date <span
                                                                className="text-danger">*</span></label>
                                                            <div className="cal-icon">
                                                                <DatePicker
                                                                    selected={selectedDate}
                                                                    onChange={handleDateChange}
                                                                    className="form-control datetimepicker"
                                                                    type="date"
                                                                    maxDate={new Date()}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Tax Number
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="taxNumber"
                                                                autoComplete="off"
                                                                value={formData.taxNumber}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>
                                                                City <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="city"
                                                                autoComplete="off"
                                                                value={formData.address.city}
                                                                onChange={handleInputChange1}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Country <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="country"
                                                                value={formData.address.country}
                                                                onChange={handleInputChange1}
                                                            >
                                                                <option value="">Select Country</option>
                                                                {countryNames.sort().map((country) => (
                                                                    <option key={country} value={country}>
                                                                        {country}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <label>Modules<span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="modules"
                                                                options={[
                                                                    { value: 'employees', label: 'Employee management' },
                                                                    { value: 'holidays', label: 'Holiday management' },
                                                                    { value: 'leaves', label: 'Leave management' },
                                                                    { value: 'timesheets', label: 'Timesheet management' },
                                                                    { value: 'shifts', label: 'Shift management' },
                                                                    { value: 'departments', label: 'Departments & Teams management' },
                                                                    { value: 'payroll', label: 'Payroll' },
                                                                ]}
                                                                value={formData.modules.map(selectedValue => {
                                                                    const option = [
                                                                        { value: 'employees', label: 'Employee management' },
                                                                        { value: 'holidays', label: 'Holiday management' },
                                                                        { value: 'leaves', label: 'Leave management' },
                                                                        { value: 'timesheets', label: 'Timesheet management' },
                                                                        { value: 'shifts', label: 'Shift management' },
                                                                        { value: 'departments', label: 'Departments & Teams management' },
                                                                        { value: 'payroll', label: 'Payroll' },
                                                                    ].find(option => option.value === selectedValue);
                                                                    return option || { value: selectedValue, label: selectedValue }; // Fallback for undefined labels
                                                                })}
                                                                onChange={(selectedOptions) => {
                                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                                    setFormData({ ...formData, modules: selectedValues });
                                                                }}
                                                                placeholder="Select all relevant modules..."
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='input-block text-center'>
                                                    <button
                                                        className="btn btn-primary account-btn mt-2" type="submit"
                                                    >
                                                        Register
                                                    </button>
                                                </div>
                                            </form>

                                            <div className="account-footer">
                                                <p>
                                                    Already have an account? <Link to="/">Login</Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
            </div>
        </>
    )
}

export default CompanyRegister;
