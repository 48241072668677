import React, {useContext, useState, useEffect} from "react";
import { db } from '../../../firebase.js';
import { addDoc, collection, getDocs, doc, updateDoc, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import Header from "../../common/Header";
import Sidebar from "../../common/Sidebar";
import {Link} from "react-router-dom";
import {UserContext} from "../../../InitialPage/App";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const auth = getAuth();

const HodLeaveCategories = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [menu, setMenu] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [entriesCount, setEntriesCount] = useState(0);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [editLeaveCategoryId, setEditLeaveCategoryId] = useState(null);
    const [selectedDate, setSelectedDate] = useState('');

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    const [formData, setFormData] = useState({
        leaveCategory: '',
        gender: '',
        maxNoOfDays: '',
        accrued: '',
        yearStarts: '',
        organisationId: 0,
    });

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(leaveCategory => !leaveCategory.deleted);
        } else {
            return filteredData.filter(leaveCategory => !leaveCategory.deleted);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Define a regular expression pattern to match invalid inputs
        const invalidPattern = /[^\w\s]/; // Matches any character that is not a word character or whitespace

        // Check if the field is the leaveCategory field
        if (name === 'leaveCategory') {
            // If it is the teamName field, check for invalid characters
            // if (invalidPattern.test(value)) {
            //     // If it contains invalid characters, don't update the state and show an error message
            //     showToast('Invalid input: Special characters and symbols are not allowed', 'error');
            //     return;
            // }
        }

        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    
    };

    const handleDateChange = (date) => {
        if (date) {
            // Format the selected date to dd-mm and set it in formData
            const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' }).split('/').join('-');
            setSelectedDate(date);
            setFormData({ ...formData, yearStarts: formattedDate });
        }
    };

    const requiredFields =
        [
            'leaveCategory',
            'gender',
            'maxNoOfDays',
            'accrued',
            'yearStarts',
        ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(formData).every(value => !value);

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // If the form is not empty, check each required field for empty value
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (requiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        try {
            // const docRef = await addDoc(collection(db, 'leave-categories'), {
            //     ...formData,
            //     organisationId: userData[0].organisationId,
            // });
            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'leave-categories'), {
                    ...formData,
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'leave-categories'), {
                    ...formData,
                    organisationId: selectedCompany.organisationId,
                });
            }
            console.log("Document written with ID: ", docRef.id);

            showToast('Leave Category Added Successfully', 'success');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);

                // const colRef = collection(db, 'leave-categories');
                // const querySnapshot = await getDocs(colRef);
                const colRef = collection(db, 'leave-categories');

                let querySnapshot;

                if (userData && userData[0]?.role !== 'sys-admin') {
                    // If the user is not a sys-admin, filter companies based on organisation ID
                    querySnapshot = await getDocs(query(colRef, where('organisationId', '==', userData[0]?.organisationId)));
                } else {
                    // If user is sys-admin, fetch all companies
                    querySnapshot = await getDocs(colRef);
                }

                const dataArray = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(leaveCategory => !leaveCategory.deleted); // Filter out deleted leave categories
                setData(dataArray);
                setEntriesCount(dataArray.length);

                setLoading(false);

                // If there is an ID for editing, set the form data
                if (editLeaveCategoryId) {
                    const selectedLeaveCategory = dataArray.find(leaveCategory => leaveCategory.id === editLeaveCategoryId);
                    setFormData(selectedLeaveCategory);
                }
                // console.log('Fetched data:', dataArray);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userData, loading, searchInput, editLeaveCategoryId]);

    const resetFormData = () => {
        setFormData({
            leaveCategory: '',
            gender: '',
            maxNoOfDays: '',
            accrued: '',
            yearStarts: '',

        });
    };

    const handleEdit = (leaveCategoryId) => {
        setEditLeaveCategoryId(leaveCategoryId);
        const selectedLeaveCategory = data.find(leaveCategory => leaveCategory.id === leaveCategoryId);
        setFormData(selectedLeaveCategory);
    };

    const handleAddButtonClick = () => {
        resetFormData(); // Reset form data when switching to add mode
        // Other add button click logic...
    };

    const handleEditFormSubmit = async (e) => {
        e.preventDefault();

        if (!e.target.checkValidity()) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        try {
            const leaveCategoryRef = doc(db, 'leave-categories', editLeaveCategoryId);
            await updateDoc(leaveCategoryRef, {
                leaveCategory: formData.leaveCategory,
                maxNoOfDays: formData.maxNoOfDays,
                gender: formData.gender,
                accrued: formData.accrued,
                yearStarts: formData.yearStarts
            });

            showToast('Leave category updated successfully', 'success');
            setEditLeaveCategoryId(null);
        } catch (error) {
            console.error('Error updating document: ', error);
        }
    };

    const handleDelete = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'leave-categories', editLeaveCategoryId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(prevData => prevData.map(leaveCategory =>
                leaveCategory.id === editLeaveCategoryId ? { ...leaveCategory, deleted: true } : leaveCategory
            ));

            showToast('Leave category deleted successfully', 'success');

        } catch (error) {
            console.error('Error deleting leave:', error);
        }
    };

    // Function to format date from dd-mm string
    function formatDateFromString(dateString) {
        if (typeof dateString === 'string') {
            const [day, month] = dateString.split('-').map(Number);
            const year = new Date().getFullYear(); // You may need to adjust the year as needed
            return new Date(year, month - 1, day).toLocaleDateString('en-GB', {
                month: 'long',
                day: '2-digit',
            });
        } else {
            // Handle cases where dateString is not a string
            return ''; // or any other default value
        }
    }

    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        const fetchCompanies = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-company'));
            const companyList = [];
            querySnapshot.forEach((doc) => {
                const companyData = doc.data();
                if (!companyData.deleted && companyData.status === 'active') {
                    companyList.push({ id: doc.id, ...companyData });
                }
            });
            setCompanies(companyList);
        };
        fetchCompanies();
    }, []);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Leaves</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="/hod/leave-management/leave-categories">Leave
                                                        Management</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Leave Categories</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_hod_leave_category"
                                                onClick={handleAddButtonClick}>
                                                <i className="fa fa-plus"/> Add Category
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="row dt-row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th>Leave</th>
                                                        <th>Gender</th>
                                                        <th>Maximum Number of Days</th>
                                                        <th>Accrued</th>
                                                        <th>Year Starts</th>
                                                        <th className="text-end">Actions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {filterData().map((leaveCategory, index) => (
                                                    <tr key={leaveCategory.id}>
                                                        <td>{leaveCategory.leaveCategory}</td>
                                                        <td style={{ fontSize: '24px' }}>
                                                            {leaveCategory.gender === 'Male' && <i className="la la-male"></i>}
                                                            {leaveCategory.gender === 'Female' && <i className="la la-female"></i>}
                                                            {leaveCategory.gender === 'All' && <i className="la la-restroom"></i>}
                                                        </td>
                                                        <td>{leaveCategory.maxNoOfDays}</td>
                                                        <td>{leaveCategory.accrued}</td>
                                                        <td>
                                                            {leaveCategory.yearStarts ?
                                                                formatDateFromString(leaveCategory.yearStarts) : ''}
                                                        </td>

                                                        <td>
                                                            <div className="dropdown dropdown-action text-end">
                                                                <Link
                                                                    to="#"
                                                                    className="action-icon dropdown-toggle"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false">
                                                                    <i className="material-icons">more_vert</i>
                                                                </Link>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#edit_hod_leave_category"
                                                                        onClick={() => handleEdit(leaveCategory.id)}
                                                                    >
                                                                        <i className="fa fa-pencil m-r-5"/> Edit
                                                                    </Link>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to="#"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#delete_hod_leave_category"
                                                                        onClick={() => handleEdit(leaveCategory.id)}
                                                                    >
                                                                        <i className="fa fa-trash m-r-5"/> Delete
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-footer">
                                    <div className="col-sm-12 col-md-5">
                                        <div className="dataTables_info">
                                            Showing 1 to {entriesCount} of {entriesCount} entries
                                        </div>
                                    </div>
                                </div>

                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Add Leave Category Modal */}
                            <div
                                id="add_hod_leave_category"
                                className="modal custom-modal fade"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div
                                    className="modal-dialog modal-dialog-centered"
                                    role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Add Leave Category</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                {userData[0]?.role === 'sys-admin' && (  
                                                    <div className="input-block">
                                                        <label className="col-form-label">Select Organisation</label>
                                                        <select
                                                            className="form-select form-control"
                                                            name="companyName"
                                                            value={formData.companyName}
                                                            onChange={handleInputChange}
                                                            required
                                                        >
                                                            <option value="">Select an active organisation</option>
                                                            {companies.map(company => (
                                                                <option>
                                                                    {company.companyName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )}
                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>
                                                                Leave Category <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                name="leaveCategory"
                                                                autoComplete="off"
                                                                value={formData.leaveCategory}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label>
                                                               Gender <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-control form-select"
                                                                name="gender"
                                                                autoComplete="off"
                                                                value={formData.gender}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option>Select Gender</option>
                                                                <option value="All">All</option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Maximum Days <span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="maxNoOfDays"
                                                                autoComplete="off"
                                                                value={formData.maxNoOfDays}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Accrued <span className="text-danger">*</span>
                                                            </label>
                                                            <select
                                                                className="form-control form-select"
                                                                name="accrued"
                                                                autoComplete="off"
                                                                value={formData.accrued}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option>Select leave increment</option>
                                                                <option value="None">None</option>
                                                                <option value="Weekly">Weekly</option>
                                                                <option value="Monthly">Monthly</option>
                                                                <option value="Yearly">Yearly</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="input-block">
                                                            <label>
                                                                Leave Year Starts (dd-mm) <span className="text-danger">*</span>
                                                            </label>
                                                            <DatePicker
                                                                selected={selectedDate}
                                                                onChange={handleDateChange}
                                                                dateFormat="dd-MM"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Add Leave Category Modal */}


                            {/* Edit Leave Category Modal */}
                            <div
                                id="edit_hod_leave_category"
                                className="modal custom-modal fade"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div
                                    className="modal-dialog modal-dialog-centered"
                                    role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Leave Category</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleEditFormSubmit} noValidate>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="input-block">
                                                        <label>
                                                            Leave Category<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="leaveCategory"
                                                            autoComplete="off"
                                                            value={formData.leaveCategory}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="input-block">
                                                        <label>
                                                           Gender <span className="text-danger">*</span>
                                                        </label>
                                                        <select
                                                            className="form-control form-select"
                                                            name="gender"
                                                            autoComplete="off"
                                                            value={formData.gender}
                                                            onChange={handleInputChange}
                                                            required
                                                        >
                                                            <option>Select Gender</option>
                                                            <option value="All">All</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="input-block">
                                                        <label>
                                                            Maximum Days <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            name="maxNoOfDays"
                                                            autoComplete="off"
                                                            value={formData.maxNoOfDays}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="input-block">
                                                        <label>
                                                            Accrued <span className="text-danger">*</span>
                                                        </label>
                                                        <select
                                                            className="form-control form-select"
                                                            name="accrued"
                                                            autoComplete="off"
                                                            value={formData.accrued}
                                                            onChange={handleInputChange}
                                                            required
                                                        >
                                                            <option>Select leave increment</option>
                                                            <option value="None">None</option>
                                                            <option value="Weekly">Weekly</option>
                                                            <option value="Monthly">Monthly</option>
                                                            <option value="Yearly">Yearly</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="input-block">
                                                        <label>
                                                            Leave Year Starts (dd-mm) <span className="text-danger">*</span>
                                                        </label>
                                                        <DatePicker
                                                            selected={selectedDate}
                                                            onChange={handleDateChange}
                                                            dateFormat="dd-MM"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>

                                                </div>
                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal" type="submit">
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Edit Leave Category Modal */}


                            {/* Delete Leave Category Modal */}
                            <div
                                className="modal custom-modal fade"
                                id="delete_hod_leave_category"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Category</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <a href="" className="btn btn-primary continue-btn"
                                                           data-bs-dismiss="modal" onClick={handleDelete}>
                                                            Delete
                                                        </a>
                                                    </div>
                                                    <div className="col-6">
                                                        <a
                                                            href=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn">
                                                            Cancel
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /Delete Leave Category Modal */}
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default HodLeaveCategories;
