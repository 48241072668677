import React, { useState, useEffect } from "react"
import { db } from '../../../firebase.js';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const auth = getAuth();

const HodLeaveForm = () => {
    const [formData, setFormData] = useState({
        employeeName:'',
        leaveCategory:'',
        startDate: null,
        endDate: null,
        noOfDays: 0,
        remainingLeaves: 0,
        organisationId: 0,
    });

    const [employees, setEmployees] = useState([]);
    const [leaveHistory, setLeaveHistory] = useState([]);
    const [leaveCategories, setLeaveCategories] = useState([]);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showOtherInput, setShowOtherInput] = useState(false);
    const [otherLeaveType, setOtherLeaveType] = useState("");
    const [selectedCompany, setSelectedCompany] = useState(null);

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchEmployees = async () => {
            if (userData && userData[0]?.role !== 'sys-admin') {
                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' && 
                        !employeeData.deleted &&
                        employeeData.organisationId === userData[0]?.organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                console.log('Fetched Employees for Non-Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            } else {
                if (!selectedCompany) return;

                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' &&
                        !employeeData.deleted &&
                        employeeData.organisationId === selectedCompany.organisationId // Use selected company's organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            }
        };
        fetchEmployees();

        const fetchLeaveHistory = async () => {
            const querySnapshot = await getDocs(collection(db, 'issue-leave'));
            const leaveHistoryList = [];
            querySnapshot.forEach((doc) => {
                leaveHistoryList.push({ id: doc.id, ...doc.data() });
            });
            // querySnapshot.forEach((doc) => {
            //     const leaveHistoryData = doc.data();
                
            //     if (leaveHistoryData.organisationId === userData[0]?.organisationId
            //     ) {
            //         leaveHistoryList.push({ id: doc.id, ...leaveHistoryData });
            //     }
            // });
            setLeaveHistory(leaveHistoryList);
        };

        fetchLeaveHistory();

        const fetchLeaveCategories = async () => {
            if (userData && userData[0]?.role !== 'sys-admin') {
                const querySnapshot = await getDocs(collection(db, 'leave-categories'));
                const leaveCategoryList = [];
                querySnapshot.forEach((doc) => {
                    const leaveCategoryData = doc.data();

                    // Check if the employee is active and not deleted
                    if (!leaveCategoryData.deleted &&
                        leaveCategoryData.organisationId === userData[0]?.organisationId
                    ) {
                        leaveCategoryList.push({ id: doc.id, ...leaveCategoryData });
                    }
                });
                setLeaveCategories(leaveCategoryList);
            } else {
                if (!selectedCompany) return;

                const querySnapshot = await getDocs(collection(db, 'leave-categories'));
                const leaveCategoryList = [];
                querySnapshot.forEach((doc) => {
                    const leaveCategoryData = doc.data();

                    // Check if the employee is active and not deleted
                    if (!leaveCategoryData.deleted &&
                        leaveCategoryData.organisationId === selectedCompany.organisationId // Use selected company's organisationId
                    ) {
                        leaveCategoryList.push({ id: doc.id, ...leaveCategoryData });
                    }
                });
                // console.log('Fetched Employees for Admin:', employeeList); // Debug log
                setLeaveCategories(leaveCategoryList);
            }
        };
        fetchLeaveCategories();

    }, [userData, selectedCompany]);


    useEffect(() => {
        // Update remainingLeaves when leaveHistory changes
        if (formData.employeeName && formData.leaveCategory) {
            const employeeLeaveHistory = leaveHistory.filter(leave => leave.employeeName === formData.employeeName && leave.leaveCategory === formData.leaveCategory);
            const usedLeaves = employeeLeaveHistory.reduce((total, leave) => total + leave.noOfDays, 0);
            const leaveCategoryData = leaveCategories.find(category => category.leaveCategory === formData.leaveCategory);

            if (leaveCategoryData) {
                const remainingLeaves = Math.max(0, leaveCategoryData.maxNoOfDays - usedLeaves);
                setFormData(prevData => ({ ...prevData, remainingLeaves }));
            } else {
                console.error(`Leave category data not found for ${formData.leaveCategory}`);
                // Handle the case where leaveCategoryData is undefined
                // For example, set a default value for remainingLeaves or display an error message to the user
            }
        }
    }, [leaveHistory, formData.employeeName, formData.leaveCategory, leaveCategories]);

    useEffect(() => {
        // Update noOfDays when either startDate or endDate changes
        if (formData.startDate && formData.endDate) {
            const businessDays = calculateBusinessDays(formData.startDate, formData.endDate);
            setFormData(prevData => ({ ...prevData, noOfDays: businessDays }));
        }
    }, [formData.startDate, formData.endDate]);

    useEffect(() => {
        // Update remainingLeaves based on selected leaveCategory and leaveHistory
        if (formData.leaveCategory && formData.startDate) {
            const leaveCategoryData = leaveCategories.find(category => category.leaveCategory === formData.leaveCategory);
            if (leaveCategoryData) {
                let remainingLeaves = leaveCategoryData.maxNoOfDays;
                if (formData.leaveCategory === 'Annual leave') {
                    const noOfCurrentMonth = formData.startDate.getMonth() + 1;
                    const totalNoOfMonths = 12;
                    remainingLeaves = (noOfCurrentMonth / totalNoOfMonths) * remainingLeaves;
                }

                // Calculate used leaves based on leave history for the employee
                const usedLeaves = leaveHistory.filter(leave => leave.employeeName === formData.employeeName && leave.leaveCategory === formData.leaveCategory)
                                                .reduce((total, leave) => total + leave.noOfDays, 0);
                remainingLeaves -= usedLeaves;

                setFormData(prevData => ({ ...prevData, remainingLeaves }));
            }
        }
    }, [formData.leaveCategory, formData.startDate, leaveCategories, leaveHistory]);

    useEffect(() => {
        // Update remainingLeaves when noOfDays changes
        const updatedRemainingLeaves = formData.remainingLeaves - formData.noOfDays;
        setFormData(prevData => ({ ...prevData, remainingLeaves: updatedRemainingLeaves }));
    }, [formData.noOfDays]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    };

    const [oneWeekFromNow, setOneWeekFromNow] = useState(() => {
        const currentDate = new Date();
        const oneWeekFromNowDate = new Date(currentDate);
        oneWeekFromNowDate.setDate(currentDate.getDate() + 7);
        return oneWeekFromNowDate;
    });

    const handleStartDateChange = (date) => {
        if (formData.leaveCategory !== 'Sick leave' && date < oneWeekFromNow) {
            // Show an alert or any other form of feedback to indicate the error
            showToast('Please select a start date at least one week from today.', 'error');
            return;
        }

        setFormData({
            ...formData,
            startDate: date,
        });
    };


    const handleEndDateChange = (date) => {
        if (date < formData.startDate) {
            // Show an alert or any other form of feedback to indicate the error
            showToast('End date must be after the start date.', 'error');
            return;
        }

        setFormData({
            ...formData,
            endDate: date,
        });
    };

    const calculateBusinessDays = (startDate, endDate) => {
        let currentDate = new Date(startDate);
        const finalDate = new Date(endDate);
        let businessDays = 0;

        while (currentDate <= finalDate) {
            const dayOfWeek = currentDate.getDay();

            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                businessDays++;
            }

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return businessDays;
    };

    const requiredFields =
        [
            'employeeName',
            'leaveCategory',
            'startDate',
            'endDate',
        ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the form is completely empty
        const isFormEmpty = Object.values(formData).every(value => !value);

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // If the form is not empty, check each required field for empty value
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (requiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        try {
            // const docRef = await addDoc(collection(db, 'issue-leave'), {
            //     ...formData,
            //     organisationId: userData[0].organisationId,
            // });
            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'issue-leave'), {
                    ...formData,
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'issue-leave'), {
                    ...formData,
                    organisationId: selectedCompany.organisationId,
                });
            }
            console.log("Document written with ID: ", docRef.id);
            showToast('Leave Added Successfully', 'success');
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-company'));
            const companyList = [];
            querySnapshot.forEach((doc) => {
                const companyData = doc.data();
                if (!companyData.deleted && companyData.status === 'active') {
                    companyList.push({ id: doc.id, ...companyData });
                }
            });
            setCompanies(companyList);
        };
        fetchCompanies();
    }, []);
    return (
        <>
            <div id="add_hod_leave"
                 className="modal custom-modal fade"
                 role="dialog"
                 data-bs-backdrop="static"
                 data-bs-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Leave</h5>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                {userData[0]?.role === 'sys-admin' && (  
                                    <div className="input-block">
                                        <label className="col-form-label">Select Organisation</label>
                                        <select                                                className="form-select form-control"
                                            name="companyName"
                                            value={formData.companyName}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Select an active organisation</option>
                                            {companies.map(company => (
                                                <option>
                                                        {company.companyName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                <div className="input-block">
                                    <label>
                                        Employee <span className="text-danger">*</span>
                                    </label>
                                    {/* <select
                                        className="form-select form-control"
                                        name="employeeName"
                                        value = {formData.employeeName}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Select an employee</option>
                                        {employees.map(employee => (
                                            <option>
                                                {employee.employeeName}
                                            </option>
                                        ))}
                                    </select> */}
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="employeeName"
                                        autoComplete="off"
                                        value = {formData.employeeName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="input-block">
                                    <label>
                                        Leave Category <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        className="form-select form-control"
                                        name="leaveCategory"
                                        value = {formData.leaveCategory}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Select category</option>
                                        {leaveCategories.map(leaveCategory => (
                                            <option>
                                                {leaveCategory.leaveCategory}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="input-block">
                                    <label>
                                        From <span className="text-danger">*</span>
                                    </label>
                                    <div className="cal-icon">
                                        <DatePicker
                                            className="form-control datetimepicker"
                                            selected={formData.startDate}
                                            onChange={(date) => handleStartDateChange(date)}
                                            minDate={formData.leaveCategory !== 'Sick leave' ? oneWeekFromNow : new Date()}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="input-block">
                                    <label>
                                        To <span className="text-danger">*</span>
                                    </label>
                                    <div className="cal-icon">
                                        <DatePicker
                                            className="form-control datetimepicker"
                                            selected={formData.endDate}
                                            onChange={(date) => handleEndDateChange(date)}
                                            minDate={formData.startDate}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="input-block">
                                    <label>
                                        Number of days
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="noOfDays"
                                        value={formData.noOfDays}
                                        readOnly
                                    />
                                </div>

                                <div className="input-block">
                                    <label>
                                        Remaining Leaves
                                    </label>
                                    <input
                                        className="form-control"
                                        readOnly
                                        name="remainingLeaves"
                                        value={formData.remainingLeaves}
                                        type="text"
                                    />
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default HodLeaveForm;
