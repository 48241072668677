import React, { useState, createContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';  // Import onAuthStateChanged
import { app, db } from '../firebase.js';
import { getFirestore, collection, doc, getDocs } from 'firebase/firestore';
import Login from "../components/Login.js";
import ResetPassword from "../components/ResetPassword.js";
import Dashboard from "../components/dashboards/Dashboard.js";
import TaxCalculator from "../components/finance/payroll/TaxCalculator/TaxCalculator.js";
import CompanyForm from "../components/companies/companyForm.js";
import CompanyList from "../components/companies/companyList.js";
import EmployeeForm from "../components/employees/employeeForm.js";
import EmployeeCards from "../components/employees/employeeCards.js";
import EmployeeList from "../components/employees/employeeList.js";
import SalaryForm from "../components/finance/payroll/EmployeeSalary/salaryForm.js";
import SalaryList from "../components/finance/payroll/EmployeeSalary/salaryList.js";
import Payslip from "../components/finance/payroll/Payslip/Payslip.js";
import PayrollItems from "../components/finance/payroll/PayrollItems/PayrollItems.js";
import RunPayroll from "../components/finance/payroll/RunPayroll/RunPayroll.js";
import PreviousPayroll from "../components/finance/payroll/RunPayroll/PreviousPayroll.js";
import P9Forms from "../components/finance/payroll/P9Forms/P9Forms.js";
import BenefitsForm from "../components/hr/benefits/benefitsForm.js";
import BenefitsList from "../components/hr/benefits/benefitsList.js";
import LeaveForm from "../components/hr/leave/leaveForm.js";
import BulkLeaveForm from "../components/hr/leave/bulkLeave.js";
import LeaveRequests from "../components/hr/leave/leaveRequests.js";
import LeaveScheduling from "../components/hr/leave/leaveScheduling.js";
import EmployeeLeaves from "../components/employees/employeeLeaves.js";
import EmployeeHolidays from "../components/employees/employeeHolidays.js";
import EmployeeLeaveSettings from "../components/employees/employeeLeaveSettings.js";
import EmployeeAttendance from "../components/employees/employeeAttendance.js";
import EmployeeDepartments from "../components/employees/employeeDepartments.js";
import EmployeeDesignations from "../components/employees/employeeDesignations.js";
import EmployeeTimesheet from "../components/employees/employeeTimesheet.js";
import EmployeeShiftScheduling from "../components/employees/employeeShiftScheduling.js";
import EmployeeOvertime from "../components/employees/employeeOvertime.js";
import EmployeeShiftList from "../components/employees/employeeShiftList.js";
import HodDepartments from "../components/hod/departments/hodDepartments.js";
import HodTeamCards from "../components/hod/departments/hodTeamCards.js";
import HodResponsibilities from "../components/hod/departments/hodResponsibilities.js";
import HodLeaveRequests from "../components/hod/leave/hodLeaveRequests.js";
import HodLeaveScheduling from "../components/hod/leave/hodLeaveScheduling.js";
import SubmittedTimeSheets from "../components/hod/timesheets/submittedTimeSheets.js";
import ProjectCards from "../components/hod/timesheets/projectCards.js";
import HodAttendance from "../components/hod/attendance/hodAttendance.js";
import HodPerformanceReviews from "../components/hod/performance/hodPerformanceReviews.js";
import EmployeeBenefits from "../components/employees/employeeBenefits.js";
import EmployeeBenefitsForm from "../components/employees/employeeBenefitsForm.js";
import EmployeeProfile from "../components/profile/employeeProfile.js";
import ProjectList from "../components/hod/timesheets/projectList.js";
import HodTeamList from "../components/hod/departments/hodTeamList.js";
import GeneratedP9Form from "../components/finance/payroll/P9Forms/GeneratedP9Form.js";
import Register from "../components/auth/Register.js";
import Wallet from "../components/finance/wallet/Wallet.js";
import PayrollDashboard from "../components/finance/payroll/PayrollDashboard/PayrollDashboard.js";
import Downloads from "../components/finance/payroll/Downloads/Downloads.js";
import MusterRoll from "../components/finance/payroll/MusterRoll/MusterRoll.js";
import ChangePassword from "../components/settings/changePassword.js";
import GeneratedPayrollSummary from "../components/finance/payroll/PayrollSummary/GeneratedPayrollSummary.js";
import HodLeaveCategories from "../components/hod/leave/hodLeaveCategories";
import HodLeaveEntitlement from "../components/hod/leave/reports/hodLeaveEntitlement";
import HodLeaveBalances from "../components/hod/leave/reports/hodLeaveBalances";
import HodLeaveLogs from "../components/hod/leave/reports/hodLeaveLogs";
import HodLeavesMusterRoll from "../components/hod/leave/reports/hodLeavesMusterRoll";
import LeaveCategories from "../components/hr/leave/leaveCategories";
import LeaveEntitlement from "../components/hr/leave/reports/leaveEntitlement";
import LeaveBalances from "../components/hr/leave/reports/leaveBalances";
import LeaveLogs from "../components/hr/leave/reports/leaveLogs";
import LeavesMusterRoll from "../components/hr/leave/reports/leavesMusterRoll";
import HodLeaveReport from "../components/hod/leave/hodLeaveReport";
import LeaveReport from "../components/hr/leave/leaveReport";
import GoogleCalendar from "../components/GoogleCalendar";
import ProjectSchedule from "../components/hod/timesheets/projectSchedule";
import AnnualMusterRoll from "../components/finance/payroll/MusterRoll/AnnualMusterRoll";
import UploadDocuments from "../components/auth/UploadDocuments.js";
import AddAddress from "../components/auth/AddAddress.js";
import SetPassword from "../components/auth/SetPassword.js";
import VerifyKYC from "../components/auth/verify-kyc.js";
import VerifyKYB from '../components/auth/verify-kyb.js';
import OrgRegister from "../components/auth/OrgRegister";
import AddOrgAddress from "../components/auth/AddOrgAddress";
import UploadOrgDocuments from "../components/auth/UploadOrgDocuments";
import SoleProprietorDetails from "../components/auth/SoleProprietorDetails";
import PrimaryContactDetails from "../components/auth/PrimaryContactDetails";
import PositionHolder from "../components/auth/PositionHolder";
import SetOrgPassword from '../components/auth/SetOrgPassword.js';
import Notifications from "../components/notifications/Notifications";
import ChooseRegister from "../components/auth/ChooseRegister";
import CompanyProfile from "../components/profile/companyProfile";
import Profile from '../components/profile/Profile.js';
import ChooseReceiveWallet from "../components/auth/ChooseReceiveWallet";
import NoWalletChoice from "../components/auth/NoWalletChoice";
import CompanyRegister from "../components/auth/regular/companyRegister";
import EmployeeRegister from "../components/auth/regular/employeeRegister";
import EmployeeRegister2 from '../components/auth/regular/employeeRegisterPassword';
import CompanyProfile2 from '../components/companies/companyProfile.js';
import NoInternet from '../components/NoInternet.js';
import NotFound from '../components/FourOFour.js';
import TaxRateForm from "../components/finance/payroll/PayrollCalculator/TaxRateForm.js";

export const UserContext = createContext();

const App = () => {
  const [userRole, setUserRole] = useState("");
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const auth = getAuth(app);

    const storedUserRole = localStorage.getItem("userRole");
    if (storedUserRole) {
      setUserRole(storedUserRole);
    } else {
      // Listen for changes in the user's login status
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          // Fetch user data from Firestore
          const userDocRef = await getDocs(collection(db, 'users'));
          try {
            const userDocSnap = await userDocRef.get();
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              const role = userData.role;
              setUserRole(role);
              // Save the role in local storage
              localStorage.setItem("userRole", role);
            } else {
              console.log("User document not found.");
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        } else {
          setUserRole("");
        }
      });

      return () => unsubscribe();
    }
  }, []);

  // useEffect(() => {
  //   const auth = getAuth(app);

  //       // Check if the user is already authenticated in local storage
  //       const storedUserRole = localStorage.getItem('userRole');
  //       if (storedUserRole) {
  //           setUserRole(storedUserRole);
  //       } else {
  //           // Listen for changes in the user's login status
  //           const unsubscribe = onAuthStateChanged(auth, (user) => {
  //               if (user) {
  //                   const role = getRoleFromEmail(user.email);
  //                   setUserRole(role);
  //                   // Save the role in local storage
  //                   localStorage.setItem('userRole', role);
  //               } else {
  //                   setUserRole('');
  //               }
  //           });

  //           return () => unsubscribe();
  //       }
  //   }, []);

  //   const getRoleFromEmail = (email) => {
  //       const role = email.split('@')[0];
  //       return role;
  //   };

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!isOnline) {
    return <NoInternet />;
  }

  return (
    <UserContext.Provider value={{ userRole, setUserRole }}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="*" element={<NotFound />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/orgregister" element={<OrgRegister />} />
          <Route exact path="/register-organization" element={<CompanyRegister />} />
          <Route exact path="/register-individual" element={<EmployeeRegister />} />

          <Route exact path="/choose-register" element={<ChooseRegister />} />
          <Route exact path="/choose-receive-wallet" element={<ChooseReceiveWallet />} />
          <Route exact path="/no-wallet-choice" element={<NoWalletChoice />} />
          <Route
            exact
            path="/orgregister/:userId/verify-kyb"
            element={<VerifyKYB />}
          />
          <Route
            exact
            path="/orgregister/:userId/set-org-password"
            element={<SetOrgPassword />}
          />
          <Route
              exact
              path="/orgregister/:userId/add-org-address"
              element={<AddOrgAddress />}
          />
          <Route
              exact
              path="/orgregister/:userId/upload-org-documents"
              element={<UploadOrgDocuments />}
          />
          <Route
              exact
              path="/orgregister/:userId/sole-proprietor-details"
              element={<SoleProprietorDetails />}
          />
          <Route
              exact
              path="/orgregister/:userId/primary-contact-details"
              element={<PrimaryContactDetails />}
          />
          <Route
              exact
              path="/orgregister/:userId/position-holder"
              element={<PositionHolder />}
          />
          <Route
            exact
            path="/register/:userId/add-address"
            element={<AddAddress />}
          />
          <Route
            exact
            path="/register/:userId/upload-documents"
            element={<UploadDocuments />}
          />
          <Route
            exact
            path="/register/:userId/set-password"
            element={<SetPassword />}
          />
          <Route
            exact
            path="/register/:userId/verify-kyc"
            element={<VerifyKYC />}
          />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="/"
            element={<Navigate replace to="/reset-password" />}
          />
          <Route exact path="/dashboard/:role" element={<Dashboard />} />
          <Route
            exact
            path="/finance/payroll/tax-calculator"
            element={<TaxCalculator />}
          />
          <Route
            exact
            path="/companies/add-company"
            element={<CompanyForm />}
          />
          <Route
            exact
            path="/companies/companies-list"
            element={<CompanyList />}
          />
          <Route
            exact
            path="/companies/company-profile/:ids"
            element={<CompanyProfile2 />}
          />
          <Route
            exact
            path="/employees/add-employee"
            element={<EmployeeForm />}
          />
          <Route
            exact
            path="/employees/employees-cards"
            element={<EmployeeCards />}
          />
          <Route
            exact
            path="/employees/employees-list"
            element={<EmployeeList />}
          />
          <Route
            exact
            path="/finance/payroll/add-salary"
            element={<SalaryForm />}
          />
          <Route
            exact
            path="/finance/payroll/employee-salary"
            element={<SalaryList />}
          />
          <Route
            exact
            path="/finance/payroll/payslip/:ids"
            element={<Payslip />}
          />
          <Route
            exact
            path="/finance/payroll/payroll-items"
            element={<PayrollItems />}
          />
          <Route
            exact
            path="/finance/payroll/run-payroll"
            element={<RunPayroll />}
          />
          <Route
            exact
            path="/finance/payroll/previous-payroll"
            element={<PreviousPayroll />}
          />
          <Route exact path="/finance/payroll/p9-forms" element={<P9Forms />} />
          <Route
            exact
            path="/finance/payroll/generated-p9form/:ids"
            element={<GeneratedP9Form />}
          />
          <Route
            exact
            path="/finance/payroll/generated-payrollsummary"
            element={<GeneratedPayrollSummary />}
          />
          <Route
            exact
            path="/hr/benefits-management/benefits-form"
            element={<BenefitsForm />}
          />
          <Route
            exact
            path="/hr/benefits-management/benefits-list"
            element={<BenefitsList />}
          />
          <Route
            exact
            path="/hr/leave-management/leave-form"
            element={<LeaveForm />}
          />
          <Route
            exact
            path="/hr/leave-management/leave-requests"
            element={<LeaveRequests />}
          />
          <Route
            exact
            path="/hr/leave-management/leave-scheduling"
            element={<LeaveScheduling />}
          />
          <Route
            exact
            path="/hr/leave-management/leave-categories"
            element={<LeaveCategories />}
          />
          <Route
            exact
            path="/hr/leave-management/leave-report"
            element={<LeaveReport />}
          />
          <Route
            exact
            path="/hr/leave-management/hr-leave-reports/leave-entitlement"
            element={<LeaveEntitlement />}
          />
          <Route
            exact
            path="/hr/leave-management/hr-leave-reports/leave-balances"
            element={<LeaveBalances />}
          />
          <Route
            exact
            path="/hr/leave-management/hr-leave-reports/leave-logs"
            element={<LeaveLogs />}
          />
          <Route
            exact
            path="/hr/leave-management/hr-leave-reports/leave-musteroll"
            element={<LeavesMusterRoll />}
          />
          <Route
            exact
            path="/employees/employee-leaves"
            element={<EmployeeLeaves />}
          />
          <Route
            exact
            path="/employees/employee-holidays"
            element={<EmployeeHolidays />}
          />
          <Route
            exact
            path="/employees/employee-leave-settings"
            element={<EmployeeLeaveSettings />}
          />
          <Route
            exact
            path="/employees/employee-attendance"
            element={<EmployeeAttendance />}
          />
          <Route
            exact
            path="/employees/employee-departments"
            element={<EmployeeDepartments />}
          />
          <Route
            exact
            path="/employees/employee-designations"
            element={<EmployeeDesignations />}
          />
          <Route
            exact
            path="/employees/employee-timesheet"
            element={<EmployeeTimesheet />}
          />
          <Route
            exact
            path="/employees/employee-shift-scheduling"
            element={<EmployeeShiftScheduling />}
          />
          <Route
            exact
            path="/employees/employee-overtime"
            element={<EmployeeOvertime />}
          />
          <Route
            exact
            path="/employees/employee-shift-list"
            element={<EmployeeShiftList />}
          />
          <Route
            exact
            path="/hod/departments/department-list"
            element={<HodDepartments />}
          />
          <Route
            exact
            path="/hod/departments/teams-cards"
            element={<HodTeamCards />}
          />
          <Route
            exact
            path="/hod/departments/teams-list"
            element={<HodTeamList />}
          />
          <Route
            exact
            path="/hod/departments/responsibilities"
            element={<HodResponsibilities />}
          />
          <Route
            exact
            path="/hod/leave-management/leave-requests"
            element={<HodLeaveRequests />}
          />
          <Route
            exact
            path="/hod/leave-management/leave-scheduling"
            element={<HodLeaveScheduling />}
          />
          <Route
            exact
            path="/hod/timesheets/project-cards"
            element={<ProjectCards />}
          />
          <Route
            exact
            path="/hod/timesheets/project-list"
            element={<ProjectList />}
          />
          <Route
            exact
            path="/hod/timesheets/timesheet-schedule"
            element={<ProjectSchedule />}
          />
          <Route
            exact
            path="/hod/timesheets/submitted-timesheets"
            element={<SubmittedTimeSheets />}
          />
          <Route exact path="/hod/attendance" element={<HodAttendance />} />
          <Route
            exact
            path="/hod/performance"
            element={<HodPerformanceReviews />}
          />
          <Route
            exact
            path="/hod/leave-management/leave-categories"
            element={<HodLeaveCategories />}
          />
          <Route
            exact
            path="/hod/leave-management/leave-report"
            element={<HodLeaveReport />}
          />
          <Route
            exact
            path="/hod/leave-management/hod-leave-reports/leave-entitlement"
            element={<HodLeaveEntitlement />}
          />
          <Route
            exact
            path="/hod/leave-management/hod-leave-reports/leave-balances"
            element={<HodLeaveBalances />}
          />
          <Route
            exact
            path="/hod/leave-management/hod-leave-reports/leave-logs"
            element={<HodLeaveLogs />}
          />
          <Route
            exact
            path="/hod/leave-management/hod-leave-reports/leave-musteroll"
            element={<HodLeavesMusterRoll />}
          />
          <Route exact path="/bulk-leave" element={<BulkLeaveForm />} />
          <Route
            exact
            path="/employees/employee-benefits"
            element={<EmployeeBenefits />}
          />
          <Route
            exact
            path="/employees/employee-benefits-form"
            element={<EmployeeBenefitsForm />}
          />
          <Route
              exact
              path="/profile"
              element={<Profile />}
          />
          <Route
            exact
            path="/profile/employee-profile"
            element={<EmployeeProfile />}
          />
          <Route
              exact
              path="/profile/company-profile"
              element={<CompanyProfile />}
          />
          <Route exact path="/finance/wallet" element={<Wallet />} />
          <Route
            exact
            path="/finance/payroll/payroll-dashboard"
            element={<PayrollDashboard />}
          />
          <Route
            exact
            path="/finance/payroll/tax-rate-form"
            element={<TaxRateForm />}
          />
          <Route
            exact
            path="/finance/payroll/downloads"
            element={<Downloads />}
          />
          <Route
            exact
            path="/finance/payroll/muster-roll"
            element={<MusterRoll />}
          />
          <Route
            exact
            path="/finance/payroll/annual-muster-roll"
            element={<AnnualMusterRoll />}
          />
          <Route
            exact
            path="/settings/change-password"
            element={<ChangePassword />}
          />
          <Route exact path="/calendar" element={<GoogleCalendar />} />
          <Route exact path="/notifications" element={<Notifications />} />
          <Route exact path="/employee-register" element={<EmployeeRegister2 />} />

          {/* <Route path="/" element={<Navigate replace to="/dashboard/:role" />} /> */}
        </Routes>
      </Router>
    </UserContext.Provider>
  );
};

export default App;
