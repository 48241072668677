import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "../../assets/css/register.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import { countries } from "countries-list";
import { useNavigate } from "react-router-dom";
import logo2 from "../../assets/img/new-rhokit-logo.png";
import { db } from "../../firebase.js";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { addAddress } from "../../lib/astra.js";
export default function AddAddress() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [countryNames, setCountryNames] = useState([]);
  const [user, setUser] = useState({});
  const addressType = [
    "BRANCH",
    "HEADQUARTERS",
    "OPERATING",
    "PHYSICAL",
    "POSTAL",
    "PRIMARY",
    "REGISTERED",
  ];
  let { userId } = useParams();

  const fetchUser = async () => {
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      setUser(userSnapshot.data());
    } else {
      toast.error("User not found");
      navigate("/register");
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchCountryNames = async () => {
      const countryNamesArray = Object.values(countries).map(
        (country) => country.name
      );

      if (isMounted) {
        setCountryNames(countryNamesArray);
      }
    };

    fetchCountryNames();
    fetchUser();

    return () => {
      isMounted = false;
    };
  }, []);

  const [formData, setFormData] = useState({
    addressType: "",
    city: "",
    country: "",
    line1: "",
    line2: "",
    state: "",
    code: "",
  });

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!e.target.checkValidity()) {
      toast.error("Please fill in all required fields");
      return;
    }
    try {
      setLoading(true);
      addAddress(
        user.customerId,
        formData.addressType,
        formData.city,
        formData.country,
        formData.line1,
        formData.state,
        formData.line2,
        formData.code
      )
        .then(async (data) => {
          const userRef = doc(db, "users", userId);
          updateDoc(userRef, {
            address: data,
          });

          toast.success("Address added successfully");

          // Redirect to the next step
          navigate(`/register/${userRef.id}/upload-documents`);
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="register">
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="account-logo">
                <img src={logo2} alt="Logo" />
              </div>

              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">Register</h3>
                  <p className="account-subtitle">Access to our dashboard</p>

                  <div>
                    <form onSubmit={handleSubmit} noValidate>
                      <h4>Add Address</h4>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="input-block">
                            <label>
                              Address Type{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select form-control"
                              name="addressType"
                              value={formData.addressType}
                              onChange={handleInputChange2}
                              required
                            >
                              <option value="">Select Address</option>
                              {addressType.map((address) => (
                                <option key={address} value={address}>
                                  {address}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block">
                            <label>
                              City <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="city"
                              autoComplete="off"
                              value={formData.city}
                              placeholder="Nairobi"
                              onChange={handleInputChange2}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block">
                            <label>
                              Country <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select form-control"
                              name="country"
                              value={formData.country}
                              onChange={handleInputChange2}
                              required
                            >
                              <option value="">Select Country</option>
                              {countryNames.sort().map((country) => (
                                <option key={country} value={country}>
                                  {country}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block">
                            <label>
                              Line 1 <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="line1"
                              autoComplete="off"
                              value={formData.line1}
                              onChange={handleInputChange2}
                              required
                              placeholder="e.g. 1234 Main Street"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block">
                            <label>Line 2</label>
                            <input
                              className="form-control"
                              type="text"
                              name="line2"
                              autoComplete="off"
                              value={formData.line2}
                              onChange={handleInputChange2}
                              placeholder="e.g. 1234 Main Street"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block">
                            <label>
                              State <span className="text-danger">*</span>{" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="state"
                              autoComplete="off"
                              value={formData.state}
                              onChange={handleInputChange2}
                              required
                              placeholder="e.g. Gauteng"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block">
                            <label>Code </label>
                            <input
                              className="form-control"
                              type="number"
                              name="code"
                              autoComplete="off"
                              value={formData.code}
                              onChange={handleInputChange2}
                              placeholder="e.g. 1234"
                            />
                          </div>
                        </div>
                        <div className="input-block text-center">
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? "Updating address..." : "Next Step"}
                          </button>
                        </div>
                      </div>
                    </form>

                    <div className="account-footer">
                      <p>
                        Already have an account? <Link to="/">Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
      />
    </div>
  );
}
