import React, { useState, useEffect } from "react";
import { db } from "../../firebase.js";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, getDoc, updateDoc, collection, addDoc } from "firebase/firestore";
import logo2 from "../../assets/img/new-rhokit-logo.png";
import PhoneInput from "react-phone-input-2";
import { Link, useParams, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "../../assets/css/register.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import { uploadOrganisationDocument } from "../../lib/astra.js";

const PrimaryContactDetails = () => {
    const navigate = useNavigate();
    const storage = getStorage();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState();
    const [user, setUser] = useState({});
    let { userId } = useParams();
    const [businessType, setBusinessType] = useState('');

    const [idFrontFile, setIdFrontFile] = useState(null);
    const [base64EncodedIdFront, setBase64EncodedIdFront] = useState(null);
    const [idBackFile, setIdBackFile] = useState(null);
    const [base64EncodedIdBack, setBase64EncodedIdBack] = useState(null);
    const [kraCertificateFile, setKraCertificateFile] = useState(null);
    const [base64EncodedKraCertificate, setBase64EncodedKraCertificate] = useState(null);

    const [formData, setFormData] = useState({
        fullName: "",
        phone: "",
        nationalIDNumber: "",
        kraPinNumber: "",
    });

    const [uploadStatus, setUploadStatus] = useState({
        // selfie: false,
        idFront: false,
        idBack: false,
        kraCertificate: false,
        // physicalAddress: false,
      });

      const fetchUser = async () => {
        const userRef = doc(db, "users", userId);
        const userSnapshot = await getDoc(userRef);
        if (userSnapshot.exists()) {
          setUser(userSnapshot.data());
          setBusinessType(userSnapshot.data().businessType);
        } else {
          toast.error("User not found");
          navigate("/orgregister");
        }
      };

      useEffect(() => {
        let isMounted = true;
    
        fetchUser();
    
        return () => {
          isMounted = false;
        };
      }, []);
    
    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        let kraContactCertificateURL = null;
    
        try {
          setLoading(true);
          await uploadOrganisationDocument(
            "NATIONAL_IDENTITY",
            user.organisationId,
            base64EncodedIdFront,
            idFrontFile.type
          );
          toast.success("Front of national identity uploaded successfully");
          setUploadStatus({ ...uploadStatus, idFront: true });
    
          // Upload NATIONAL_IDENTITY file to Firebase Storage
          const storageRef2 = ref(storage, `idFront/${user.organisationId}`);
          await uploadBytes(storageRef2, idFrontFile);
          const idFrontURL = await getDownloadURL(storageRef2);
    
          await uploadOrganisationDocument(
            "BACK_OF_NATIONAL_IDENTITY",
            user.organisationId,
            base64EncodedIdBack,
            idBackFile.type
          );
          toast.success("Back of national identity uploaded successfully");
          setUploadStatus({ ...uploadStatus, idBack: true });
    
          // Upload BACK_OF_NATIONAL_IDENTITY file to Firebase Storage
          const storageRef3 = ref(storage, `idBack/${user.organisationId}`);
          await uploadBytes(storageRef3, idBackFile);
          const idBackURL = await getDownloadURL(storageRef3);
    
          if (kraCertificateFile) {
            await uploadOrganisationDocument(
              "TAX",
              user.organisationId,
              base64EncodedKraCertificate,
              kraCertificateFile.type
            );
            toast.success("KRA certificate uploaded successfully");
            setUploadStatus({ ...uploadStatus, kraCertificate: true });
    
            // Upload TAX file to Firebase Storage
            const storageRef4 = ref(storage, `kraCertificate/${user.organisationId}`);
            await uploadBytes(storageRef4, kraCertificateFile);
            kraContactCertificateURL = await getDownloadURL(storageRef4);
          }
    
          const userRef = doc(db, "users", userId);
          await updateDoc(userRef, {
            // selfieURL,
            idFrontURL,
            idBackURL,
            kraContactCertificateURL,
            fullName: formData.fullName,
            phone: formData.phone,
            nationalIDNumber: formData.nationalIDNumber,
            kraPinNumber: formData.kraPinNumber,
            // physicalAddressURL,
          });
    
          setLoading(false);
          toast.success("Documents uploaded successfully");
          navigate(`/register/${userId}/position-holder`);
        } catch (error) {
          setLoading(false);
          toast.error(
            error.message +
              ". Please try again by selecting another file to proceed with the upload"
          );
        }
      };

    return (
        <div className="register">
            <div className="account-page">
                <div className="main-wrapper">
                    <div className="account-content">
                        <div className="container">
                            <div className="account-logo">
                                <img src={logo2} alt="Logo"/>
                            </div>

                            <div className="account-box">
                                <div className="account-wrapper">
                                    <h3 className="account-title">Register</h3>
                                    <p className="account-subtitle">Access to our dashboard</p>

                                    <div>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <h4>Director & Primary Contact Details</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-block">
                                                        <label>
                                                            Full Name{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="fullName"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            value={formData.fullName}
                                                            onChange={handleInputChange2}
                                                            required
                                                            placeholder="Enter full name"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="input-block">
                                                        <label>
                                                            Valid Phone Number{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <PhoneInput
                                                            country={"ke"}
                                                            value={value}
                                                            onChange={setValue}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Valid National ID Number
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            name="nationalIDNumber"
                                                            autoComplete="off"
                                                            value={formData.nationalIDNumber}
                                                            onChange={handleInputChange2}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Valid KRA PIN Number
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="kraPinNumber"
                                                            autoComplete="off"
                                                            value={formData.kraPinNumber}
                                                            onChange={handleInputChange2}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Upload Front Side of your National ID{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            accept=".png,.jpeg"
                                                            disabled={uploadStatus.idFront}
                                                            onChange={(event) => {
                                                                const file =
                                                                event.currentTarget.files &&
                                                                event.currentTarget.files[0];
                                                                if (file) {
                                                                if (file.size / 1024 / 1024 > 1) {
                                                                    toast.error("File size too big (1MB max)");
                                                                } else if (
                                                                    file.type !== "image/jpeg" &&
                                                                    file.type !== "image/png"
                                                                ) {
                                                                    toast.error(
                                                                    "File type not supported. Only JPEG, PNG allowed"
                                                                    );
                                                                } else {
                                                                    setIdFrontFile(file);
                                                                    const reader = new FileReader();
                                                                    reader.onload = (e) => {
                                                                    setBase64EncodedIdFront(
                                                                        e.target?.result.split(",")[1]
                                                                    );
                                                                    console.log(e.target?.result);
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }
                                                                }
                                                            }}
                                                            required
                                                            />
                                                            <p className="text-primary text-xs">
                                                            Front of national identity
                                                            </p>
                                                            {uploadStatus.idFront && (
                                                            <p className="text-success text-xs">
                                                                Front of national identity uploaded successfully
                                                            </p>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Upload Back Side of your National ID{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            accept=".png,.jpeg"
                                                            disabled={uploadStatus.idBack}
                                                            onChange={(event) => {
                                                                const file =
                                                                event.currentTarget.files &&
                                                                event.currentTarget.files[0];
                                                                if (file) {
                                                                if (file.size / 1024 / 1024 > 1) {
                                                                    toast.error("File size too big (1MB max)");
                                                                } else if (
                                                                    file.type !== "image/jpeg" &&
                                                                    file.type !== "image/png"
                                                                ) {
                                                                    toast.error(
                                                                    "File type not supported. Only JPEG, PNG allowed"
                                                                    );
                                                                } else {
                                                                    setIdBackFile(file);
                                                                    const reader = new FileReader();
                                                                    reader.onload = (e) => {
                                                                    setBase64EncodedIdBack(
                                                                        e.target?.result.split(",")[1]
                                                                    );
                                                                    console.log(e.target?.result);
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }
                                                                }
                                                            }}
                                                            required
                                                            />
                                                            <p className="text-primary text-xs">
                                                            Back of national identity
                                                            </p>
                                                            {uploadStatus.idBack && (
                                                            <p className="text-success text-xs">
                                                                Back of national identity uploaded successfully
                                                            </p>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                        Valid KRA PIN Certificate
                                                        </label>
                                                        <input
                                                        className="form-control"
                                                        type="file"
                                                        accept=".pdf"
                                                        onChange={(event) => {
                                                            const file =
                                                            event.currentTarget.files &&
                                                            event.currentTarget.files[0];
                                                            if (file) {
                                                            if (file.size / 1024 / 1024 > 10) {
                                                                toast.error("File size too big (10MB max)");
                                                            } else if (file.type !== "application/pdf") {
                                                                toast.error(
                                                                "File type not supported. Only PDF allowed"
                                                                );
                                                            } else {
                                                                setKraCertificateFile(file);
                                                                const reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                setBase64EncodedKraCertificate(
                                                                    e.target?.result.split(",")[1]
                                                                );
                                                                console.log(e.target?.result);
                                                                };
                                                                reader.readAsDataURL(file);
                                                            }
                                                            }
                                                        }}
                                                        disabled={uploadStatus.kraCertificate}
                                                        />
                                                        {uploadStatus.kraCertificate && (
                                                        <p className="text-success text-xs">
                                                            KRA certificate uploaded successfully
                                                        </p>
                                                        )}
                                                    </div>
                                                </div>
{/* 
                                                <div className="col-md-12">
                                                    <div className="input-block">
                                                        <label className="col-form-label">
                                                            Upload Your Passport Photo{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            accept=".png,.jpeg"
                                                            // required
                                                        />
                                                        <p className="text-primary text-xs">
                                                            Passport photo
                                                        </p>
                                                    </div>
                                                </div> */}

                                                <div className="input-block text-center">
                                                    <button
                                                        className="btn btn-primary"
                                                        type="submit"
                                                        disabled={loading}
                                                    >
                                                        {loading ? "Updating..." : "Next Step"}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="account-footer">
                                            <p>
                                                Already have an account? <Link to="/">Login</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </div>
    )
}

export default PrimaryContactDetails;
