import React, { useState, useEffect, useContext } from "react";
import { db } from '../../../../firebase.js';
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import Header from "../../../common/Header.js";
import Sidebar from "../../../common/Sidebar.js";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import SalaryForm from "./salaryForm.js";
import { UserContext } from "../../../../InitialPage/App.js";
import Select from 'react-select'
import { calculateTotalCashPay, calculateTotalNonCashPay, calculateComputedRent, calculateNetValueOfHousing, calculateTotalGrossPay, calculatePensionCashPay, calculateAmountOfBenefit, calculateTaxablePay, calculateNSSF, calculateNHIF, calculateTaxPayable, calculateTotalDeductions, calculateInsuranceRelief, calculatePayeTax, calculateNetPay, CONSTANTS, calculateHousing } from '../PayrollCalculator/PayrollCalculator.js'
import { getAuth } from "firebase/auth";

const auth = getAuth();

const customStyles = {
    control: base => ({
        ...base,
        height: 50,
        minHeight: 50
    })
};


const SalaryList = () => {
    const { userRole } = useContext(UserContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        month: '',
        employeeName:[],
        currency: '',
        basicSalary: 0,
        insuranceContribution: 0,
    });

    const [resultsData, setResultsData] = useState({
        personalRelief: 2400,
        permissibleLimit: 20000,
        totalAllowance: 0,
        totalNonCashPay: 0,
        totalCashPay: 0,
        rentOfHouseComputed: 0,
        netValueOfHousing: 0,
        totalGrossPay: 0,
        pensionCashPay: 0,
        totalAmountOfBenefit: 0,
        insuranceRelief: 0,
        taxablePay: 0,
        taxPayable: 0,
        payePayable:0,
        nhifContribution:0,
        nssfContribution:0,
        housingLevy: 0,
        totalDeductions: 0,
        netPay: 0,
    });

    const [focused, setFocused] = useState(false);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedDate2, setSelectedDate2] = useState(null);
    const [entriesCount, setEntriesCount] = useState(0); // State for tracking the number of entries
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [filteredEntriesCount, setFilteredEntriesCount] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    const [employees, setEmployees] = useState([]);
    const [nonCashBenefits, setNonCashBenefits] = useState([]);
    const [housingBenefits, setHousingBenefits] = useState([]);
    const [cashBenefits, setCashBenefits] = useState([]);
    const [allowances, setAllowances] = useState([]);
    const [deductions, setDeductions] = useState([]);
    const [editSalaryId, setEditSalaryId] = useState(null);
    const [menu, setMenu] = useState(false);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const handleEntriesChange = (e) => {
        const value = e.target.value;
        setEntriesPerPage(value === 'all' ? entriesCount : parseInt(value));
    };

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = data.filter((item) =>
            (item.employeeName && item.employeeName.toLowerCase().includes(input.toLowerCase()))
        );

        setFilteredEntriesCount(filtered.length);
        setFilteredData(filtered);
    };

    const filterData = () => {
        if (searchInput === '') {
            return data.filter(item => !item.deleted);
        } else {
            return filteredData.filter(item => !item.deleted);
        }
    };

    const handleStatusChange = async (salaryId, newStatus) => {
        try {
            await updateDoc(doc(db, 'add-salary', salaryId), { status: newStatus });

            const updatedData = data.map(item => {
                if (item.id === itemId) {
                    return { ...item, status: newStatus };
                }
                return item;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleApproveSalaries = async () => {
        try {
            const employeeNames = Array.isArray(formData.employeeName) ? formData.employeeName : [formData.employeeName];
            // Iterate over selected employee names and update their status to "approved"
            await Promise.all(employeeNames.map(async (employeeName) => {
                const salaryToUpdate = data.find(item => item.employeeName === employeeName);
                if (salaryToUpdate) {
                    await updateDoc(doc(db, 'add-salary', salaryToUpdate.id), { status: 'approved' });
                }
            }));

            // Update the local data to reflect the changes
            const updatedData = data.map(item => {
                if (formData.employeeName.includes(item.employeeName)) {
                    return { ...item, status: 'approved' };
                }
                return item;
            });
            setData(updatedData);
        } catch (error) {
            console.error('Error approving salaries:', error);
        }
    };

    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    };
    const handleDateChange2 = (date) => {
        setSelectedDate2(date);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        const isNumericField = ['basicSalary', 'insuranceContribution'].includes(name);

        const numericValue = isNumericField ? Number(value): value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: numericValue,
        }));

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const rentRecoveredFromEmployee = housingBenefits.map(housingBenefit => housingBenefit.rentRecoveredFromEmployee);
            const deductionAmount = deductions.map(deduction => deduction.deductionAmount);

            const totalCashPay = await calculateTotalCashPay(formData.employeeName, formData.basicSalary, allowances);
            const totalNonCashPay = await calculateTotalNonCashPay(formData.employeeName, nonCashBenefits);
            const rentOfHouseComputed = await calculateComputedRent(formData.employeeName, totalCashPay, totalNonCashPay, housingBenefits);
            const netValueOfHousing = await calculateNetValueOfHousing(rentOfHouseComputed, rentRecoveredFromEmployee);
            const totalGrossPay = await calculateTotalGrossPay(totalCashPay, totalNonCashPay, netValueOfHousing);
            const pensionCashPay = await calculatePensionCashPay(totalCashPay);
            const totalAmountOfBenefit = await calculateAmountOfBenefit(
                [formData.employeeName],
                pensionCashPay,
                cashBenefits
            );
            const nssfContribution = await calculateNSSF(totalGrossPay);
            const housingLevy = await calculateHousing(totalGrossPay);
            const nhifContribution = await calculateNHIF(totalGrossPay);
            const totalDeductions = await calculateTotalDeductions(formData.employeeName, nssfContribution, deductionAmount, housingLevy);
            const taxablePay = await calculateTaxablePay(totalGrossPay, totalDeductions, totalAmountOfBenefit);
            const insuranceRelief = await calculateInsuranceRelief(formData.employeeName, nhifContribution, formData.insuranceContribution);
            const taxPayable = await calculateTaxPayable(taxablePay);
            const payePayable = await calculatePayeTax(taxPayable, insuranceRelief, CONSTANTS.personalRelief);
            const netPay = await calculateNetPay(totalGrossPay, payePayable, totalDeductions);

            // const combinedData = { ...formData, ...resultsData };
            setResultsData({
                ...resultsData,
                totalCashPay,
                totalNonCashPay,
                rentOfHouseComputed,
                netValueOfHousing,
                totalGrossPay,
                pensionCashPay,
                totalAmountOfBenefit,
                taxablePay,
                nhifContribution,
                nssfContribution,
                housingLevy,
                totalDeductions,
                insuranceRelief,
                taxPayable,
                payePayable,
                netPay
            });

            await updateDoc(doc(db, 'add-salary', editSalaryId),
                ...formData,
                ...resultsData,
            );

            setEditSalaryId(null);
        } catch (error) {
            console.error('Error updating document: ', error);
        }
    };

    const navigate = useNavigate();
    const handleGeneratePayslip = (employee) => {
        console.log("Generating payslip for employee:", employee);
        navigate(`/finance/payroll/payslip/${employee.id}`);
    };

    useEffect(() => {
        const fetchEmployees = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-employee'));
            const employeeList = [];

            querySnapshot.forEach((doc) => {
                const employeeData = doc.data();
                // Check if the employee is active and not deleted
                if (employeeData.status === 'active' && !employeeData.deleted && employeeData.organisationId === userData[0]?.organisationId) {
                    employeeList.push({ id: doc.id, ...employeeData });
                }
            });

            setEmployees(employeeList);
        };

        fetchEmployees();
    }, [userData]);

    useEffect(() => {
        const fetchNonCashBenefits = async () => {
            const querySnapshot = await getDocs(collection(db, 'payroll-noncash-benefit'));
            const nonCashList = [];

            querySnapshot.forEach((doc) => {
                const nonCashData = doc.data();
                if (!nonCashData.deleted) {
                    nonCashList.push({ id: doc.id, ...nonCashData });
                }
            });

            setNonCashBenefits(nonCashList);
        };

        fetchNonCashBenefits();
    }, [formData.employeeName]);

    useEffect(() => {
        const fetchCashBenefits = async () => {
            const querySnapshot = await getDocs(collection(db, 'payroll-cash-benefit'));
            const cashList = [];

            querySnapshot.forEach((doc) => {
                const cashData = doc.data();
                if (!cashData.deleted) {
                    cashList.push({ id: doc.id, ...cashData });
                }
            });

            setCashBenefits(cashList);
        };

        fetchCashBenefits();
    }, [formData.employeeName]);

    useEffect(() => {
        const fetchHousingBenefits = async () => {
            const querySnapshot = await getDocs(collection(db, 'payroll-housing-benefit'));
            const housingList = [];

            querySnapshot.forEach((doc) => {
                const housingData = doc.data();
                if (!housingData.deleted) {
                    housingList.push({ id: doc.id, ...housingData });
                }
            });

            setHousingBenefits(housingList);
        };

        fetchHousingBenefits();
    }, [formData.employeeName]);

    useEffect(() => {
        const fetchAllowances = async () => {
            const querySnapshot = await getDocs(collection(db, 'payroll-allowance'));
            const allowanceList = [];

            querySnapshot.forEach((doc) => {
                const allowanceData = doc.data();
                if (!allowanceData.deleted) {
                    allowanceList.push({ id: doc.id, ...allowanceData });
                }
            });

            setAllowances(allowanceList);
        };

        fetchAllowances();
    }, [formData.employeeName]);

    useEffect(() => {
        const fetchDeductions = async () => {
            const querySnapshot = await getDocs(collection(db, 'payroll-other-deduction'));
            const deductionList = [];

            querySnapshot.forEach((doc) => {
                const deductionData = doc.data();
                if (!deductionData.deleted) {
                    deductionList.push({ id: doc.id, ...deductionData });
                }
            });

            setDeductions(deductionList);
        };

        fetchDeductions();
    }, [formData.employeeName]);

    const handleDelete = async () => {
        try {
            // Update the deleted status to true
            await updateDoc(doc(db, 'add-salary', editSalaryId), { deleted: true });

            // Update the local data without refetching from Firestore
            setData(data => data.map(item =>
                item.id === editSalaryId ? { ...item, deleted: true } : item
            ));

        } catch (error) {
            console.error('Error deleting salary:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            //   setLoading(true);
            // const colRefSalary = collection(db, 'add-salary');
            // const querySnapshotSalary = await getDocs(colRefSalary);
            const colRefSalary = collection(db, 'add-salary');

            let querySnapshotSalary;

            if (userData && userData[0]?.role !== 'sys-admin') {
                // If the user is not a sys-admin, filter companies based on organisation ID
                querySnapshotSalary = await getDocs(query(colRefSalary, where('organisationId', '==', userData[0]?.organisationId)));
            } else {
                // If user is sys-admin, fetch all companies
                querySnapshotSalary = await getDocs(colRefSalary);
            }

            const dataArraySalary = querySnapshotSalary.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(item => !item.deleted);

            const dataWithAdditionalInfo = await Promise.all(dataArraySalary.map(async item => {
            // const employeeQuerySnapshot = await getDocs(query(collection(db, 'add-employee'), where('employeeName', '==', item.employeeName)));
            // const employeeData = employeeQuerySnapshot.docs[0]?.data(); // Added check here
            // if (employeeData) {
            return {
                id: item.id,
                ...item,
                employeeName: item.employeeName,
                // email: employeeData.email,
                // role: employeeData.role,
                totalGrossPay: item.totalGrossPay,
                netPay: item.netPay,
            };

            // } else {
            //     return null; // Return null if no matching document is found
            // }
            }));

            const filteredData = dataWithAdditionalInfo.filter(item => item !== null); // Filter out null entries

            setData(filteredData);
            setEntriesCount(filteredData.length);

              setLoading(false);

            if (editSalaryId) {
                const selectedSalary = dataArraySalary.find(item => item.id === editSalaryId);
                setFormData(selectedSalary);
                setResultsData(selectedSalary);
            }

          } catch (error) {
            console.error('Error fetching data:', error);
              setLoading(false);
          }
        };

        fetchData();
      }, [userData, loading, searchInput, entriesPerPage, editSalaryId]);

    const handleDownloadCSVTemplate = () => {
        // Define the CSV content with dropdown options
        const csvContent = "employeeName,basicSalary,insuranceContribution\n\n\n\n\n";

        // Create a Blob object with the CSV content
        const csvBlob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(csvBlob);
        link.setAttribute('download', 'salary_template.csv');

        // Simulate a click event to trigger the download
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <div className="app-container">
                    <Header onMenuClick={() => toggleMobileMenu()}/>
                    <div className="main-content">
                        <Sidebar/>
                        <div className="page-wrapper">
                            <div className="content container-fluid">
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h3 className="page-title">Employee Salary</h3>
                                            <ul className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <Link to={`/dashboard/${userRole}`}>Dashboard</Link>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <Link to="#">Payroll</Link>
                                                </li>
                                                <li className="breadcrumb-item active">Employee Salary</li>
                                            </ul>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="/finance/payroll/tax-calculator"
                                                className="btn add-btn"
                                                data-bs-target="#tax_calculator">
                                                <i className="fa fa-calculator"/> Simple Tax Calculator
                                            </Link>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="#"
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#add_salary">
                                                <i className="fa fa-plus"/> Add Salary
                                            </Link>
                                        </div>
                                        <div className="col-auto float-end ms-auto">
                                            <Link
                                                to="#"
                                                onClick={handleDownloadCSVTemplate}
                                                className="btn add-btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#upload_csv_salary">
                                                <i className="fa fa-cloud-upload-alt"/> Upload CSV
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="row filter-row">
                                    <div className="col-sm-6 col-md-12">
                                        <div
                                            className={
                                                focused
                                                    ? "input-block form-focus focused"
                                                    : "input-block form-focus"
                                            }>
                                            <input
                                                type="text"
                                                className="form-control floating"
                                                onFocus={() => setFocused(true)}
                                                onBlur={() => setFocused(false)}
                                                value={searchInput}
                                                onChange={handleSearchInputChange}
                                            />
                                            <label className="focus-label ">Search...</label>
                                        </div>
                                    </div>

                                </div>
                                <div className="row filter-row">
                                    <div className="col-sm-6 col-md-9">
                                        <div
                                            className={
                                                focused
                                                    ? "input-block form-focus focused"
                                                    : "input-block form-focus"
                                            }>
                                            <Select
                                                isMulti
                                                name="employeeName"
                                                styles={customStyles}
                                                options={filterData().map(item => ({
                                                    value: item.employeeName,
                                                    label: item.employeeName
                                                }))}
                                                value={Array.isArray(formData.employeeName) ?
                                                    formData.employeeName.map(selected => ({
                                                        value: selected,
                                                        label: selected
                                                    })) :
                                                    {
                                                        value: formData.employeeName,
                                                        label: formData.employeeName
                                                    }
                                                }
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                        setFormData({
                                                            ...formData,
                                                            employeeName: selectedValues
                                                        });
                                                }}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <button
                                            className="btn btn-success btn-block w-100"
                                            onClick={handleApproveSalaries}
                                        >
                                            {" "}
                                            Approve{" "}
                                        </button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-header">
                                            <div className="row mt-4">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length">
                                                        <label>
                                                            Show
                                                            <select className="form-control form-control-sm"
                                                                    value={entriesPerPage}
                                                                    onChange={handleEntriesChange}>
                                                                <option value="all">All</option>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                            Entries
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="table-responsive">
                                                <table className="table table-striped custom-table datatable">
                                                    <thead>
                                                    <tr>
                                                        <th>Employee</th>
                                                        {/* <th>Email</th> */}
                                                        {/* <th>Role</th> */}
                                                        <th>Currency</th>
                                                        <th>Basic Salary</th>
                                                        <th>Gross Salary</th>
                                                        <th>Net Pay</th>
                                                        <th>Status</th>
                                                        <th className="text-end">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {filterData().slice(0, entriesPerPage).map(item => (
                                                        <tr key={item.id}>
                                                            <td>{item.employeeName}</td>
                                                            {/* <td>{item.email}</td> */}
                                                            {/* <td>{item.role}</td> */}
                                                            <td>{item.currency}</td>
                                                            <td>{item.basicSalary && new Intl.NumberFormat().format(item.basicSalary)}</td>
                                                            <td>{item.totalGrossPay && new Intl.NumberFormat().format(item.totalGrossPay) || "N/A"}</td>
                                                            <td>{item.netPay && new Intl.NumberFormat().format(item.netPay)}</td>
                                                            <td>
                                                                <div className="dropdown action-label">
                                                                    <a className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                                       data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className={`fa-regular fa-circle-dot text-${item.status === 'approved' ? 'success' : item.status === 'disapproved' ? 'danger' : 'purple'}`}></i>
                                                                        {item.status === 'approved' ? 'Approved' : item.status === 'disapproved' ? 'Denied' : 'New'}
                                                                    </a>
                                                                    <div className="dropdown-menu">
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'approved')}>
                                                                            <i className="fa-regular fa-circle-dot text-success me-1"></i> Approve
                                                                        </a>
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'disapproved')}>
                                                                            <i className="fa-regular fa-circle-dot text-danger me-1"></i> Deny
                                                                        </a>
                                                                        <a className="dropdown-item"
                                                                           onClick={() => handleStatusChange(item.id, 'new')}>
                                                                            <i className="fa-regular fa-circle-dot text-purple me-1"></i> New
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="dropdown dropdown-action">
                                                                    <a className="action-icon dropdown-toggle"
                                                                       data-bs-toggle="dropdown"
                                                                       aria-expanded="false"><i
                                                                        className="material-icons">more_vert</i></a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a className="dropdown-item"
                                                                           data-bs-toggle="modal"
                                                                           data-bs-target="#edit_salary"
                                                                           onClick={() => setEditSalaryId(item.id)}><i
                                                                            className="fa-solid fa-pencil m-r-5"></i>
                                                                            Edit</a>
                                                                        <a className="dropdown-item"
                                                                           data-bs-toggle="modal"
                                                                           data-bs-target="#delete_salary"
                                                                           onClick={() => setEditSalaryId(item.id)}><i
                                                                            className="fa-regular fa-trash-can m-r-5"></i>
                                                                            Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="table-footer">
                                            <div className="col-sm-12 col-md-5">
                                                <div className="dataTables_info">
                                                    {searchInput
                                                        ? `Showing 1 to ${Math.min(filteredEntriesCount, entriesPerPage)} of ${filteredEntriesCount} entries`
                                                        : `Showing 1 to ${Math.min(entriesCount, entriesPerPage)} of ${entriesCount} entries`}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {loading && (
                                    <div className="text-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* <TaxCalculator /> */}
                            <SalaryForm/>

                            {/*Edit Salary Modal*/}
                            <div id="edit_salary"
                                 className="modal custom-modal fade"
                                 role="dialog"
                                 data-bs-backdrop="static"
                                 data-bs-keyboard="false"
                            >
                                <div
                                    className="modal-dialog modal-dialog-centered modal-lg"
                                    role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Edit Staff Salary</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>Select Staff <span
                                                                className="text-danger">*</span></label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="employeeName"
                                                                value={formData.employeeName}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option value="">Select an employee</option>
                                                                {employees.map(employee => (
                                                                    <option>
                                                                        {employee.employeeName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="input-block">
                                                            <label>Currency <span className="text-danger">*</span></label>
                                                            <select
                                                                className="form-select form-control"
                                                                name="currency"
                                                                value={formData.currency}
                                                                onChange={handleInputChange}
                                                                required
                                                            >
                                                                <option value="">Select Currency</option>
                                                                <option value="KES">Kenya Shillings (KSHS)</option>
                                                                <option value="UGX">Ugandan Shillings (UGX)</option>
                                                                <option value="TZS">Tanzanian Shillings (TZS)</option>
                                                                <option value="USD">US Dollars (US$)</option>
                                                                <option value="Euros">Euros (€)</option>
                                                                <option value="GBP">Pound Sterling (£)</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Basic Salary <span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="basicSalary"
                                                                autoComplete="off"
                                                                value={formData.basicSalary}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input-block">
                                                            <label>Insurance Contribution <span className="text-danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                name="insuranceContribution"
                                                                autoComplete="off"
                                                                value={formData.insuranceContribution}
                                                                onChange={handleInputChange}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="submit-section">
                                                    <button className="btn btn-primary submit-btn"
                                                            data-bs-dismiss="modal"
                                                            type='submit'>
                                                        Update
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Edit Salary Modal*/}

                            {/*Delete Salary Modal*/}
                            <div
                                className="modal custom-modal fade"
                                id="delete_salary"
                                role="dialog"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                            >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="form-header">
                                                <h3>Delete Salary</h3>
                                                <p>Are you sure want to delete?</p>
                                            </div>
                                            <div className="modal-btn delete-action">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Link to="" className="btn btn-primary continue-btn"
                                                              data-bs-dismiss="modal" onClick={handleDelete}>
                                                            Delete
                                                        </Link>
                                                    </div>
                                                    <div className="col-6">
                                                        <Link
                                                            to=""
                                                            data-bs-dismiss="modal"
                                                            className="btn btn-primary cancel-btn"
                                                        >
                                                            Cancel
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Delete Salary Modal*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalaryList;
