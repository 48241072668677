import React, { useState, useEffect } from 'react';
import { db } from '../../../../firebase.js';
import { collection, addDoc, getDocs, where, query } from 'firebase/firestore';
import { ToastContainer, toast, Slide  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { calculateTotalCashPay, calculateTotalNonCashPay, calculateComputedRent, calculateNetValueOfHousing, calculateTotalGrossPay, calculatePensionCashPay, calculateAmountOfBenefit, calculateTaxablePay, calculateNSSF, calculateNHIF, calculateTaxPayable, calculateTotalDeductions, calculateInsuranceRelief, calculatePayeTax, calculateNetPay, CONSTANTS, calculateHousing, calculateWithholdingTax } from '../PayrollCalculator/PayrollCalculator.js'
import { getAuth } from "firebase/auth";

const auth = getAuth();

const SalaryForm = () => {
    const [formData, setFormData] = useState({
        month: '',
        employeeName:'',
        employmentType: '',
        currency: '',
        basicSalary: 0,
        insuranceContribution: 0,
        organisationId: 0,
    });

    const [resultsData, setResultsData] = useState({
        personalRelief: CONSTANTS.personalRelief,
        permissibleLimit: CONSTANTS.permissibleLimit,
        totalAllowance: 0,
        totalNonCashPay: 0,
        totalCashPay: 0,
        rentOfHouseComputed: 0,
        netValueOfHousing: 0,
        totalGrossPay: 0,
        pensionCashPay: 0,
        totalAmountOfBenefit: 0,
        insuranceRelief: 0,
        taxablePay: 0,
        taxPayable: 0,
        payePayable:0,
        nhifContribution:0,
        nssfContribution:0,
        housingLevy: 0,
        totalDeductions: 0,
        // housingLevy: 0,
        netPay: 0,
        withHoldingTax: 0,
    });

    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [nonCashBenefits, setNonCashBenefits] = useState([]);
    const [housingBenefits, setHousingBenefits] = useState([]);
    const [cashBenefits, setCashBenefits] = useState([]);
    const [allowances, setAllowances] = useState([]);
    const [deductions, setDeductions] = useState([]);

    const [selectedMonth, setSelectedMonth] = useState('');
    const [fileData, setFileData] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);


    const handleMonthChange = (e) => {
        // console.log('Selected month:', e.target.value);
        setSelectedMonth(e.target.value);
    };

    const showToast = (message, type) => {
        switch (type) {
            case 'success':
                toast.success(message);
                break;
            case 'error':
                toast.error(message);
                break;
            default:
                toast(message);
        }
    };

    useEffect(() => {
        const checkUserAuthentication = async () => {
            if (!auth.currentUser) {
                console.error("User not found. Please login again.");
                return;
            }

            const q = query(collection(db, "users"), where("email", "==", auth.currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.error("User data not found.");
                return;
            }

            const userDataArray = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setLoading(false);
            setUserData(userDataArray);
            // console.log("User Data", userData);
        };

        // Check user authentication first
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in
                checkUserAuthentication();
            } else {
                // No user is signed in
                setLoading(false);
                console.error("User not found. Please login again.");
            }
        });

        // Clean up subscription
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchEmployees = async () => {
            if (userData && userData[0]?.role !== 'sys-admin') {
                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' && 
                        !employeeData.deleted &&
                        employeeData.organisationId === userData[0]?.organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Non-Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            } else {
                if (!selectedCompany) return;

                const querySnapshot = await getDocs(collection(db, 'add-employee'));
                const employeeList = [];
                querySnapshot.forEach((doc) => {
                    const employeeData = doc.data();

                    // Check if the employee is active and not deleted
                    if (employeeData.status === 'active' &&
                        !employeeData.deleted &&
                        employeeData.organisationId === selectedCompany.organisationId // Use selected company's organisationId
                    ) {
                        employeeList.push({ id: doc.id, ...employeeData });
                    }
                });
                // console.log('Fetched Employees for Admin:', employeeList); // Debug log
                setEmployees(employeeList);
            }
        };
        fetchEmployees();
    }, [userData, selectedCompany]);

    useEffect(() => {
        const fetchNonCashBenefits = async () => {
            const querySnapshot = await getDocs(collection(db, 'payroll-noncash-benefit'));
            const nonCashList = [];

            querySnapshot.forEach((doc) => {
                const nonCashData = doc.data();
                if (!nonCashData.deleted) {
                    nonCashList.push({ id: doc.id, ...nonCashData });
                }
            });

            setNonCashBenefits(nonCashList);
        };

        fetchNonCashBenefits();
    }, [formData.employeeName]);

    useEffect(() => {
        const fetchCashBenefits = async () => {
            const querySnapshot = await getDocs(collection(db, 'payroll-cash-benefit'));
            const cashList = [];

            querySnapshot.forEach((doc) => {
                const cashData = doc.data();
                if (!cashData.deleted) {
                    cashList.push({ id: doc.id, ...cashData });
                }
            });

            setCashBenefits(cashList);
        };

        fetchCashBenefits();
    }, [formData.employeeName]);

    useEffect(() => {
        const fetchHousingBenefits = async () => {
            const querySnapshot = await getDocs(collection(db, 'payroll-housing-benefit'));
            const housingList = [];

            querySnapshot.forEach((doc) => {
                const housingData = doc.data();
                if (!housingData.deleted) {
                    housingList.push({ id: doc.id, ...housingData });
                }
            });

            setHousingBenefits(housingList);
        };

        fetchHousingBenefits();
    }, [formData.employeeName]);

    useEffect(() => {
        const fetchAllowances = async () => {
            const querySnapshot = await getDocs(collection(db, 'payroll-allowance'));
            const allowanceList = [];

            querySnapshot.forEach((doc) => {
                const allowanceData = doc.data();
                if (!allowanceData.deleted) {
                    allowanceList.push({ id: doc.id, ...allowanceData });
                }
            });

            setAllowances(allowanceList);
        };

        fetchAllowances();
    }, [formData.employeeName]);

    useEffect(() => {
        const fetchDeductions = async () => {
            const querySnapshot = await getDocs(collection(db, 'payroll-other-deduction'));
            const deductionList = [];

            querySnapshot.forEach((doc) => {
                const deductionData = doc.data();
                if (!deductionData.deleted) {
                    deductionList.push({ id: doc.id, ...deductionData });
                }
            });

            setDeductions(deductionList);
        };

        fetchDeductions();
    }, [formData.employeeName]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        const isNumericField = ['basicSalary', 'insuranceContribution'].includes(name);

        const numericValue = isNumericField ? Number(value): value;
        setFormData((prevData) => ({
            ...prevData,
            month: selectedMonth,
            [name]: numericValue,
        }));

        if (name === 'companyName') {
            const company = companies.find(c => c.companyName === value);
            setSelectedCompany(company);
        }
    };

    //  Function to handle file upload
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const csvData = event.target.result;
            setFileData(csvData);
        };
        reader.readAsText(file);
    };

    const processData = async (e) => {
        e.preventDefault();

        if (!fileData) {
            showToast('Please upload a CSV file', 'error');
            return;
        }

        const lines = fileData.trim().split('\n');

        if (lines.length < 2) {
            showToast('Invalid CSV file format', 'error');
            return;
        }

        const [header, ...rows] = lines;
        const [employeeNameHeader, basicSalaryHeader, insuranceContributionHeader] = header.split(',');

        if (employeeNameHeader.trim() !== 'employeeName' || basicSalaryHeader.trim() !== 'basicSalary' || insuranceContributionHeader.trim() !== 'insuranceContribution') {
            showToast('Invalid CSV file format', 'error');
            return;
        }

        try {
            const promises = rows.map(async row => {
                const [employeeName, basicSalary, insuranceContribution] = row.split(',');

                const trimmedEmployeeName = employeeName.trim();
                const trimmedBasicSalary = basicSalary.trim();
                const trimmedInsuranceContribution = insuranceContribution.trim();

                const rentOfHouseMarketValue = housingBenefits.map(housingBenefit => housingBenefit.rentOfHouseMarketValue);
                const rentRecoveredFromEmployee = housingBenefits.map(housingBenefit => housingBenefit.rentRecoveredFromEmployee);
                const typeOfHousing = housingBenefits.map(housingBenefit => housingBenefit.typeOfHousing);

                const nonCashBenefitName = nonCashBenefits.map(nonCashBenefit => nonCashBenefit.nonCashBenefitName);
                const nonCashBenefitAmount = nonCashBenefits.map(nonCashBenefit => nonCashBenefit.nonCashBenefitAmount);

                const cashBenefitName = cashBenefits.map(cashBenefit => cashBenefit.cashBenefitName);
                const cashBenefitAmount = cashBenefits.map(cashBenefit => cashBenefit.cashBenefitAmount);

                const allowanceAmount = allowances.map(allowance => allowance.allowanceAmount);
                const totalAllowance = allowanceAmount.reduce((acc, curr) => acc + curr, 0);
                const deductionAmount = deductions.map(deduction => deduction.deductionAmount);

                // Calculate total cash pay and total non-cash pay
                const totalCashPay = await calculateTotalCashPay(formData.employeeName, trimmedBasicSalary, allowances);
                console.log("Total Cash Pay:", totalCashPay);

                const totalNonCashPay = await calculateTotalNonCashPay(formData.employeeName, nonCashBenefits);
                console.log("Total Non-cash Pay:", totalNonCashPay);

                // Calculate rent of house computed and net value of housing
                const rentOfHouseComputed = await calculateComputedRent(formData.employeeName, totalCashPay, totalNonCashPay, housingBenefits);
                console.log("Computed Rent of House:", rentOfHouseComputed);

                const netValueOfHousing = await calculateNetValueOfHousing(rentOfHouseComputed, rentRecoveredFromEmployee);
                console.log("Net Value of House", netValueOfHousing);

                // Calculate total gross pay
                const totalGrossPay = await calculateTotalGrossPay(totalCashPay, totalNonCashPay, netValueOfHousing);
                console.log("Total Gross Pay", totalGrossPay);

                // Calculate pension cash pay
                const pensionCashPay = await calculatePensionCashPay(totalCashPay);
                console.log("Pension Cash Pay", pensionCashPay);

                // Calculate amount of benefit
                const totalAmountOfBenefit = await calculateAmountOfBenefit(
                    [formData.employeeName],
                    pensionCashPay,
                    cashBenefits
                );
                console.log("Amount of Benefit", totalAmountOfBenefit);

                // Calculate NSSF contribution
                const nssfContribution = await calculateNSSF(totalGrossPay);
                console.log("Nssf Contribution", nssfContribution);

                // Calculate NHIF contribution
                const nhifContribution = await calculateNHIF(totalGrossPay);
                console.log("Nhif Contribution", nhifContribution);

                const housingLevy = await calculateHousing(totalGrossPay);
                console.log("Housing Levy", housingLevy);

                // Calculate total deductions
                const totalDeductions = await calculateTotalDeductions(formData.employeeName, nssfContribution, deductions, housingLevy);
                console.log("Total Deductions", totalDeductions);

                // Calculate taxable pay
                const taxablePay = await calculateTaxablePay(totalGrossPay, totalDeductions, totalAmountOfBenefit);
                console.log("Taxable Pay", taxablePay);


                // Calculate insurance relief
                const insuranceRelief = await calculateInsuranceRelief(formData.employeeName, nhifContribution, trimmedInsuranceContribution);
                console.log("Insurance relief", insuranceRelief);

                // Calculate tax payable
                const taxPayable = await calculateTaxPayable(taxablePay);
                console.log("Tax Payable", taxPayable);

                // Calculate PAYE payable
                const payePayable = await calculatePayeTax(taxPayable, insuranceRelief, CONSTANTS.personalRelief);
                console.log("PAYE Payable", payePayable);

                const netPay = await calculateNetPay(totalGrossPay, payePayable, totalDeductions);
                console.log("Net Pay", netPay);

                const withHoldingTax = await calculateWithholdingTax(trimmedBasicSalary);
                console.log("Withholding Tax", withHoldingTax);

                const formDataWithResults = {
                    month: selectedMonth,
                    employeeName: trimmedEmployeeName,
                    currency: formData.currency,
                    basicSalary: parseFloat(trimmedBasicSalary),
                    insuranceContribution: parseFloat(trimmedInsuranceContribution),
                    totalAllowance,
                    totalCashPay,
                    totalNonCashPay,
                    rentOfHouseComputed,
                    netValueOfHousing,
                    totalGrossPay,
                    pensionCashPay,
                    totalAmountOfBenefit,
                    taxablePay,
                    nhifContribution,
                    nssfContribution,
                    housingLevy,
                    totalDeductions,
                    insuranceRelief,
                    taxPayable,
                    payePayable,
                    netPay,
                    withHoldingTax,
                };

                if (Object.values(formDataWithResults).some(value => value === null || value === undefined)) {
                    console.log('Skipping document with null values:', formDataWithResults);
                }

                // Add the document to Firestore
                await addDoc(collection(db, 'add-salary'), formDataWithResults);
            });

            await Promise.all(promises);

            showToast('CSV data processed successfully', 'success');
        } catch (error) {
            console.error('Error adding documents: ', error);
            showToast('Error adding documents', 'error');
        }
    };

    const requiredFields =
        [
            'month',
            'employeeName',
            'employmentType',
            'currency',
            'basicSalary',
        ];

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Check if the form is completely empty
        const isFormEmpty = Object.values(formData).every(value => !value);

        if (isFormEmpty) {
            showToast('Form cannot be empty!', 'error');
            return;
        }

        // processData();
        // If the form is not empty, check each required field for empty value
        const errorMessages = [];
        Object.entries(formData).forEach(([key, value]) => {
            // Check if the field is required and empty
            if (requiredFields.includes(key) && !value) {
                errorMessages.push(`${key} cannot be empty`);
            }
        });

        // If there are errors, display them as separate toasts
        if (errorMessages.length > 0) {
            errorMessages.forEach(errorMessage => {
                showToast(errorMessage, 'error');
            });
            return;
        }

        // Check if the employee's salary for the selected month already exists
        const existingSalaryQuery = query(collection(db, 'add-salary'), where('employeeName', '==', formData.employeeName), where('month', '==', selectedMonth));
        const existingSalarySnapshot = await getDocs(existingSalaryQuery);

        if (!existingSalarySnapshot.empty) {
            showToast('Salary already added for this employee in the selected month', 'error');
            return;
        }
        try {
            await new Promise((resolve) => {
                setFormData((prevData) => ({
                    ...prevData,
                    month: selectedMonth,
                }));
                resolve();
            });

            let formDataWithResults = { ...formData, month: selectedMonth };

            if (formData.employmentType === 'Consultancy') {
                const {netPayWithholding, amountToBeDeducted} = await calculateWithholdingTax(formData.basicSalary);
                formDataWithResults = { 
                    ...formDataWithResults, 
                    netPay: netPayWithholding, 
                    withHoldingTax: amountToBeDeducted
                };
                console.log({formDataWithResults});
            } else {
                const rentOfHouseMarketValue = housingBenefits.map(housingBenefit => housingBenefit.rentOfHouseMarketValue);
                const rentRecoveredFromEmployee = housingBenefits.map(housingBenefit => housingBenefit.rentRecoveredFromEmployee);
                const typeOfHousing = housingBenefits.map(housingBenefit => housingBenefit.typeOfHousing);

                const nonCashBenefitName = nonCashBenefits.map(nonCashBenefit => nonCashBenefit.nonCashBenefitName);
                const nonCashBenefitAmount = nonCashBenefits.map(nonCashBenefit => nonCashBenefit.nonCashBenefitAmount);

                const cashBenefitName = cashBenefits.map(cashBenefit => cashBenefit.cashBenefitName);
                const cashBenefitAmount = cashBenefits.map(cashBenefit => cashBenefit.cashBenefitAmount);

                const allowanceAmount = allowances.map(allowance => allowance.allowanceAmount);
                const totalAllowance = allowanceAmount.reduce((acc, curr) => acc + curr, 0);
                const deductionAmount = deductions.map(deduction => deduction.deductionAmount);

                // Calculate total cash pay and total non-cash pay
                const totalCashPay = await calculateTotalCashPay(formData.employeeName, formData.basicSalary, allowances);
                console.log("Total Cash Pay:", totalCashPay);

                const totalNonCashPay = await calculateTotalNonCashPay(formData.employeeName, nonCashBenefits);
                console.log("Total Non-cash Pay:", totalNonCashPay);

                // Calculate rent of house computed and net value of housing
                const rentOfHouseComputed = await calculateComputedRent(formData.employeeName, totalCashPay, totalNonCashPay, housingBenefits);
                console.log("Computed Rent of House:", rentOfHouseComputed);

                const netValueOfHousing = await calculateNetValueOfHousing(rentOfHouseComputed, rentRecoveredFromEmployee);
                console.log("Net Value of House", netValueOfHousing);

                // Calculate total gross pay
                const totalGrossPay = await calculateTotalGrossPay(totalCashPay, totalNonCashPay, netValueOfHousing);
                console.log("Total Gross Pay", totalGrossPay);

                // Calculate pension cash pay
                const pensionCashPay = await calculatePensionCashPay(totalCashPay);
                console.log("Pension Cash Pay", pensionCashPay);

                // Calculate amount of benefit
                const totalAmountOfBenefit = await calculateAmountOfBenefit(
                    [formData.employeeName],
                    pensionCashPay,
                    cashBenefits
                );
                console.log("Amount of Benefit", totalAmountOfBenefit);

                // Calculate NSSF contribution
                const nssfContribution = await calculateNSSF(totalGrossPay);
                console.log("Nssf Contribution", nssfContribution);

                // Calculate NHIF contribution
                const nhifContribution = await calculateNHIF(totalGrossPay);
                console.log("Nhif Contribution", nhifContribution);

                const housingLevy = await calculateHousing(totalGrossPay);
                console.log("Housing Levy", housingLevy);

                // Calculate total deductions
                const totalDeductions = await calculateTotalDeductions(formData.employeeName, nssfContribution, deductions, housingLevy);
                console.log("Total Deductions", totalDeductions);

                // Calculate taxable pay
                const taxablePay = await calculateTaxablePay(totalGrossPay, totalDeductions, totalAmountOfBenefit);
                console.log("Taxable Pay", taxablePay);


                // Calculate insurance relief
                const insuranceRelief = await calculateInsuranceRelief(formData.employeeName, nhifContribution, formData.insuranceContribution);
                console.log("Insurance relief", insuranceRelief);

                // Calculate tax payable
                const taxPayable = await calculateTaxPayable(taxablePay);
                console.log("Tax Payable", taxPayable);

                // Calculate PAYE payable
                const payePayable = await calculatePayeTax(taxPayable, insuranceRelief, CONSTANTS.personalRelief);
                console.log("PAYE Payable", payePayable);

                const netPay = await calculateNetPay(totalGrossPay, payePayable, totalDeductions);
                console.log("Net Pay", netPay);

                formDataWithResults = {
                    ...resultsData,
                    ...formDataWithResults,
                    totalAllowance,
                    totalCashPay,
                    totalNonCashPay,
                    rentOfHouseComputed,
                    netValueOfHousing,
                    totalGrossPay,
                    pensionCashPay,
                    totalAmountOfBenefit,
                    taxablePay,
                    nhifContribution,
                    nssfContribution,
                    housingLevy,
                    totalDeductions,
                    insuranceRelief,
                    taxPayable,
                    payePayable,
                    netPay
                };
            };

            let docRef;
            if (userData && userData[0]?.role !== 'sys-admin') {
                docRef = await addDoc(collection(db, 'add-salary'), {
                    ...formDataWithResults,
                    organisationId: userData[0].organisationId,
                });
            } else {
                docRef = await addDoc(collection(db, 'add-salary'), {
                    ...formDataWithResults,
                    organisationId: selectedCompany.organisationId,
                });
            }
            console.log("Document written with ID: ", docRef.id);
            showToast('Salary Added Successfully', 'success');

            setFormData({
                month: '',
                employeeName:'',
                employmentType: '',
                currency: '',
                basicSalary: 0,
                insuranceContribution: 0,
            });
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            const querySnapshot = await getDocs(collection(db, 'add-company'));
            const companyList = [];
            querySnapshot.forEach((doc) => {
                const companyData = doc.data();
                if (!companyData.deleted && companyData.status === 'active') {
                    companyList.push({ id: doc.id, ...companyData });
                }
            });
            setCompanies(companyList);
        };
        fetchCompanies();
    }, []);

    return (
        <>
            <div id="add_salary"
                 className="modal custom-modal fade"
                 role="dialog"
                 data-bs-backdrop="static"
                 data-bs-keyboard="false"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Staff Salary</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} noValidate>
                                    <div className="col-md-12">
                                        <div className="input-block">
                                        <label htmlFor="monthPicker">Select Month: <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="month"
                                                id="monthPicker"
                                                value={selectedMonth}
                                                onChange={handleMonthChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    {userData[0]?.role === 'sys-admin' && (  
                                        <div className="input-block">
                                            <label className="col-form-label">Select Organisation</label>
                                            <select
                                                className="form-select form-control"
                                                name="companyName"
                                                value={formData.companyName}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select an active organisation</option>
                                                {companies.map(company => (
                                                    <option>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>Staff <span className="text-danger">*</span></label>
                                            {/* <select
                                                className="form-select form-control"
                                                name="employeeName"
                                                value={formData.employeeName}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select an employee</option>
                                                {employees.map(employee => (
                                                    <option>
                                                        {employee.employeeName}
                                                    </option>
                                                ))}
                                            </select> */}
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="employeeName"
                                                autoComplete="off"
                                                value = {formData.employeeName}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label>Work Structure<span className="text-danger">*</span></label>
                                            <select
                                                className="form-select form-control"
                                                name="employmentType"
                                                value={formData.employmentType}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select</option>
                                                <option value="Employment">Employment</option>
                                                <option value="Consultancy">Consultancy</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="input-block">
                                            <label>Currency <span className="text-danger">*</span></label>
                                            <select
                                                className="form-select form-control"
                                                name="currency"
                                                value={formData.currency}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select Currency</option>
                                                <option value="KES">Kenya Shillings (KSHS)</option>
                                                <option value="UGX">Ugandan Shillings (UGX)</option>
                                                <option value="TZS">Tanzanian Shillings (TZS)</option>
                                                <option value="USD">US Dollars (US$)</option>
                                                <option value="Euros">Euros (€)</option>
                                                <option value="GBP">Pound Sterling (£)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Basic Salary <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="basicSalary"
                                                autoComplete="off"
                                                value={formData.basicSalary}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    {formData.employmentType !== 'Consultancy' && (
                                    <div className="col-md-6">
                                        <div className="input-block">
                                            <label>Insurance Contribution</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="insuranceContribution"
                                                autoComplete="off"
                                                value={formData.insuranceContribution}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    )}
                                </div>

                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal"
                                            type='submit'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div id="upload_csv_salary" className="modal custom-modal fade" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Upload Salary</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={processData} noValidate>
                                    <div className="col-md-12">
                                        <div className="input-block">
                                        <label htmlFor="monthPicker">Select Month: <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="month"
                                                id="monthPicker"
                                                value={selectedMonth}
                                                onChange={handleMonthChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="input-block">
                                            <label>Currency <span className="text-danger">*</span></label>
                                            <select
                                                className="form-select form-control"
                                                name="currency"
                                                value={formData.currency}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select Currency</option>
                                                <option value="KES">Kenya Shillings (KSHS)</option>
                                                <option value="UGX">Ugandan Shillings (UGX)</option>
                                                <option value="TZS">Tanzanian Shillings (TZS)</option>
                                                <option value="USD">US Dollars (US$)</option>
                                                <option value="Euros">Euros (€)</option>
                                                <option value="GBP">Pound Sterling (£)</option>
                                            </select>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-block">
                                            <label className="col-form-label">
                                                Upload CSV File <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                accept=".csv"
                                                onChange={handleFileUpload}
                                            />
                                        <h6 className="mt-2" style={{ color: '#00AAB9' }}>Kindly use the downloaded csv template</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type='submit'>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />
        </>
    )
}

export default SalaryForm;
